import React from "react";
import Logo from "../logo/Logo";
import { Link, useLocation } from "react-router-dom";
import {
  PresentationChartLineIcon,
  BanknotesIcon,
  BriefcaseIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
  CommandLineIcon,
  BuildingLibraryIcon,
  KeyIcon,
  ScaleIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from 'react-redux';
import { XMarkIcon } from "@heroicons/react/20/solid";

import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";

const Sidebar = ({ fixed, theme, className, visibility, setVisibility, ...props }) => {
  const { pathname } = useLocation();
  const { organisation: { features, organisations }, account: { account } } = useSelector((state) => state);

  const activeClass = 'inline-flex items-center gap-x-4 lg:gap-x-2 text-white font-bold lg:text-sm text-lg';
  const inactiveClass = 'inline-flex items-center gap-x-4 lg:gap-x-2 hover:text-white text-white font-extralight lg:text-sm text-lg';

  const normalClass = 'bg-purple-900 top-0 left-0 fixed h-screen w-[170px] hidden lg:block';
  const mobileClass = 'bg-purple-900 top-0 left-0 absolute h-screen w-screen z-[999]';

  const itemClass = 'nk-menu-item mb-2 text-lg px-4 c';

  const iconClass = 'h-6 w-6';

  return (
    <div className={visibility === false ? normalClass : mobileClass}>
      <XMarkIcon
        className="h-8 w-8 text-white absolute top-2 right-2 cursor-pointer lg:hidden"
        onClick={() => setVisibility(false)}
      />
      <div className="pt-2 pb-3 px-4 lg:!px-5">
        <Logo />
      </div>

      <ul className="nk-menu" id="main-menu">
        <li className={itemClass} id="sidebar_insights">
          <Link
            className={pathname.startsWith('/insights') ? activeClass : inactiveClass}
            onClick={() => setVisibility(false)}
            to="/"
          >
            <PresentationChartLineIcon className={iconClass} />
            Insights
          </Link>
        </li>

        <li className={itemClass} id="sidebar_payments">
          <Link
            className={pathname.startsWith('/payments') ? activeClass : inactiveClass}
            onClick={() => setVisibility(false)}
            to="/payments"
          >
            <BanknotesIcon className={iconClass} />
            Payments
          </Link>
        </li>

        <li className={itemClass} id="sidebar_bookings">
          <Link
            className={pathname.startsWith('/bookings') ? activeClass : inactiveClass}
            onClick={() => setVisibility(false)}
            to="/bookings"
          >
            <BriefcaseIcon className={iconClass} />
            Bookings
          </Link>
        </li>

        <li className={itemClass} id="sidebar_customers">
          <Link
            className={pathname.startsWith('/customers') ? activeClass : inactiveClass}
            onClick={() => setVisibility(false)}
            to="/customers"
          >
            <UserGroupIcon className={iconClass} />
            Customers
          </Link>
        </li>

        {HasRole(account, ['ledger:view', 'payouts:view', 'payouts:create', 'acquirer:reporting'], true) && (
          <li className={itemClass} id="sidebar_settlement">
            <Link
              className={pathname.startsWith('/settlement') ? activeClass : inactiveClass}
              onClick={() => setVisibility(false)}
              to="/settlement"
            >
              <BuildingLibraryIcon className={iconClass} />
              Settlement
            </Link>
          </li>
        )}

        {HasRole(account, ['booking-and-payment:organisation-access'], true) && (
          <li className={itemClass} id="sidebar_settlement">
            <Link
              className={pathname.startsWith('/surcharging') ? activeClass : inactiveClass}
              onClick={() => setVisibility(false)}
              to="/surcharging"
            >
              <ScaleIcon className={iconClass} />
              Surcharging
            </Link>
          </li>
        )}



        {HasRole(account, [
          'users:manage',
          'organisation:integrations',
          'organisation:audit',
          'billing:view',
          'suppliers:manage',
          'beneficiaries:manage',
          'open-banking:manage',
        ], true) && (
            <li className={itemClass} id="sidebar_organisation">
              <Link
                className={pathname.startsWith('/organisation') && !pathname.includes('/organisations') ? activeClass : inactiveClass}
                to="/organisation"
                onClick={() => setVisibility(false)}
              >
                <BuildingOffice2Icon className={iconClass} />
                Organisation
              </Link>
            </li>
          )}

        {HasRole(account, ['developers:manage:keys', 'developers:manage:webhooks'], true) && HasFeature(features, ['developers']) && (
          <li className={itemClass} id="sidebar_developers">
            <Link
              className={pathname.startsWith('/developers') ? activeClass : inactiveClass}
              to="/developers"
              onClick={() => setVisibility(false)}
            >
              <CommandLineIcon className={iconClass} />
              Developers
            </Link>
          </li>
        )}

        {typeof organisations === 'object' && organisations.map((org) => org.id).includes('X1234') && (
          <li className={itemClass} id="sidebar_admin">
            <Link
              className={pathname.startsWith('/admin') ? activeClass : inactiveClass}
              to="/admin/organisations"
              onClick={() => setVisibility(false)}
            >
              <KeyIcon className={iconClass} />
              Admin
            </Link>
          </li>
        )}


      </ul>

    </div>
  );
};
export default Sidebar;
