export const FETCH_ORGANISATIONS = '@organisations/fetch';
export const FETCHING_ORGANISATIONS = '@organisations/fetching';
export const SET_ORGANISATIONS = '@organisations/set';
export const SET_ORGANISATION = '@organisation/set'
export const SET_ORGANISATION_FEATURES = '@organisation/set/features';
export const SET_ORGANISATION_CURRENCIES = '@organisation/set/currencies';
export const SET_ORGANISATION_PAYMENT_METHODS = '@organisation/set/payment-methods'
export const SET_FILTERS_SHOW_ALL_CHILD_ORGANISATIONS = '@organisation/filtering/show-all-child_organisations'
export const SET_ORGANISATION_UNPAID = '@organisation/set/unpaid';
export const SET_ORGANISATION_SURCHARGING = '@organisation/set/surcharging';
export const SET_ORGANISATION_INTEGRATIONS = '@organisation/set/integrations';

export function fetchOrganisations(organisations) {
  return (dispatch) => dispatch({ type: FETCH_ORGANISATIONS });
}

export function setOrganisation(id) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATION,
    payload: id,
  });
}

export function setOrganisationFeatures(features) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATION_FEATURES,
    payload: features,
  });
}

export function setOrganisationCurrencies(currencies) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATION_CURRENCIES,
    payload: currencies,
  });
}

export function setOrganisationUnPaid(status) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATION_UNPAID,
    payload: status,
  });
}

export function setOrganisationSurcharging(status) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATION_SURCHARGING,
    payload: status,
  });
}

export function setOrganisations(organisations) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATIONS,
    payload: organisations,
  });
}

export function setFetchingOrganisations(status) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATIONS,
    payload: status,
  });
}

export function setFiltersShowAllChildOrganisations(status) {
  return (dispatch) => dispatch({
    type: SET_FILTERS_SHOW_ALL_CHILD_ORGANISATIONS,
    payload: status,
  });
}

export function setOrganisationIntegrations(integrations) {
  return (dispatch) => dispatch({
    type: SET_ORGANISATION_INTEGRATIONS,
    payload: integrations,
  });
}
