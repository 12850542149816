import BarclaysLogo from './logos/barclays.png';
import GlobalLogo from './logos/global.webp';
import LloydsLogo from './logos/lloyds.png';
import NuapayLogo from './logos/nuapay.png';
import NuveiLogo from './logos/nuvei.png';
import PlanetLogo from './logos/planet.svg';
import StripeLogo from './logos/stripe.png';
import TotalProcessingLogo from './logos/total-processing.png';
import TrustLogo from './logos/trust.webp';
import WorldpayLogo from './logos/worldpay.png';

const PROVIDER_IDS = {
  barclays_cybersource: '611b6678-a3e0-4758-8a4f-df0b3ca9ff52',
  barclays_epdq: 'acbb0a1d-be48-4f14-abf3-2830f680ff43',
  global_payments: '8d4dabf4-b05c-4204-ad39-fbe29b91038b',
  lloyds_cardnet: 'f1b3b3b3-1b3b-1b3b-1b3b-1b3b1b3b1b3b',
  nuapay: '952af356-907a-47d2-a7a6-fe70847cb24e',
  nuvei: '6556532c-18a9-4686-b96a-f4b31a7f6dad',
  planet: '6cb154f0-1031-40be-a7fb-23c6c9de39cc',
  stripe: '17b6d3e2-fb5c-4716-a281-93bda3e8eec3',
  total_processing: '62d41710-c70e-484b-a750-17bc42d10209',
  trust_payments: 'c9a94478-2c53-498c-957d-76da36e1a058',
  worldpay: 'c22d5cbb-d27e-48aa-a103-efaf17b0e3ed',
};


export const PROVIDER_CONFIGURATION = {
  '611b6678-a3e0-4758-8a4f-df0b3ca9ff52': {
    name: 'Barclays CyberSource',
    display_name: 'Barclays CyberSource',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/barclays-cybersource',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: BarclaysLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  'acbb0a1d-be48-4f14-abf3-2830f680ff43': {
    name: 'Barclays ePDQ',
    display_name: 'Barclays ePDQ',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/barclays-epdq',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: BarclaysLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  '8d4dabf4-b05c-4204-ad39-fbe29b91038b': {
    name: 'Global Payments',
    display_name: 'Global Payments',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/global-payments',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: GlobalLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  'f1b3b3b3-1b3b-1b3b-1b3b-1b3b1b3b1b3b': {
    name: 'Lloyds Cardnet',
    display_name: 'Lloyds Cardnet',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/lloyds-cardnet',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: LloydsLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  '952af356-907a-47d2-a7a6-fe70847cb24e': {
    name: 'Nuapay',
    display_name: 'Nuapay',
    type: 'Open Banking',
    manage_path: '/organisation/integrations/nuapay',
    description: 'An open banking provider allowing you to accept bank-to-bank payments.',
    logo: NuapayLogo,
    custom_settlement: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  '6556532c-18a9-4686-b96a-f4b31a7f6dad': {
    name: 'Nuvei',
    display_name: 'Nuvei',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/nuvei',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: NuveiLogo,
    custom_settlement: false,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  '6cb154f0-1031-40be-a7fb-23c6c9de39cc': {
    name: 'Planet',
    display_name: 'Planet',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/planet',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: PlanetLogo,
    custom_settlement: false,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  '17b6d3e2-fb5c-4716-a281-93bda3e8eec3': {
    name: 'Stripe',
    display_name: 'Stripe',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/stripe',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: StripeLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  '62d41710-c70e-484b-a750-17bc42d10209': {
    name: 'Total Processing',
    display_name: 'Trust Payments',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/total-processing',
    description: 'A payment gateway that allows you to accept card payments.',
    logo: TotalProcessingLogo,
    custom_settlement: false,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  'c9a94478-2c53-498c-957d-76da36e1a058': {
    name: 'Trust Payments',
    display_name: 'Trust Payments',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/trust-payments',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: TrustLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
  'c22d5cbb-d27e-48aa-a103-efaf17b0e3ed': {
    name: 'Worldpay',
    display_name: 'Worldpay',
    type: 'Card Processing',
    manage_path: '/organisation/integrations/worldpay',
    description: 'A merchant acquirer that allows you to accept card payments.',
    logo: WorldpayLogo,
    custom_settlement: false,
    is_beta: true,
    apm: {
      googlepay: false,
      applepay: false,
    }
  },
};

export default PROVIDER_IDS;