import React from 'react';
import DataTable from 'src/components/data-table';
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import { convertPenniesToDecimals } from "src/utils/money";
import TransactionLink from "./components/transaction-link";
import PiiObfuscator from "src/components/pii-obfuscator";
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import {
  Button,
} from 'src/components/Component';

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className='text-left'>
      <span>Type</span>
    </th>
    <th className='text-left'>
      <span>Account Number</span>
    </th>
    <th className="border-l text-left">
      <span>Description</span>
    </th>
    <th className="text-center" style={{ minWidth: '100px' }}>
      <span>Counterparty</span>
    </th>
    <th className="border-l text-center" style={{ minWidth: '100px' }}>
      <span>Amount</span>
    </th>
    <th className="border-l text-center" style={{ minWidth: '150px' }}>
      <span>Linked Entity</span>
    </th>
  </tr>
);

const RowFormatter = ({ item, triggerReload }) => {
  return (
    <React.Fragment key={item.id}>
      <tr className="tb-tnx-item">
        <td className="border-r text-left">
          <span className="title">{item.type || item.category}</span>
        </td>
        <td className="border-r">
          { item?.account?.account_number }
        </td>
        <td className="border-r">
          <span className="title">
            <PiiObfuscator className="title" type="bank_description">{item.description}</PiiObfuscator>
          </span>
        </td>
        <td className="border-r text-center">
          <span className="title">{item.counterparty_name || '---'}</span>
        </td>
        <td className="border-r text-center">
          <span className="title">{CurrencyFormat().format(convertPenniesToDecimals(item.amount))}</span>
        </td>
        <td className="text-center border-r" style={{ width: '175px', padding: 0 }}>
          <TransactionLink item={item} triggerReload={triggerReload} />
        </td>
      </tr>
    </React.Fragment>
  );
}

const PageOptions = ({triggerReload, loading}) => {
  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
        </li>
    </ul>
  );
};

const Bookings = () => {
  return (
    <DataTable
      title="Bank Account Statement"
      pathFilters={{
        type: 'credit',
      }}
      dateGroupingField="posting_date"
      columnCount={9}
      itemTitle="ledger entries"
      TableHeaders={TableHeaders}
      PageOptions={PageOptions}
      dateFilterOption={true}
      dateFilterLabel="Posting Date"
      searchPlaceholder="Transaction description"
      RowFormatter={RowFormatter}
      endpoint={API_ROUTES.aisp['transactions:get']()}
      childOrganisationsOption={false}
      noHeader={true}
    />
  )
};

export default Bookings;
