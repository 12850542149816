import React, {useState} from 'react';
import {Button, Icon} from "../../../../../../components/Component";
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import Axios from "axios";
import API_ROUTES from "../../../../../../config/api";
import {Auth} from "aws-amplify";

const DeleteButton = ({ triggerReload, item }) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);

  const toggleForm = () => setModalForm(!modalForm);

  const deleteRefund = async () => {
    setModalFormProcessing(true);
    const authUser = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.agent['refund:decline'](item.authorisation_code),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}` },
      }
    );

    triggerReload();
    toggleForm();
    setModalFormProcessing(false);
  };

  return (
    <React.Fragment>
      <Button
        className="text-white btn-danger w-100 text-center mt-2"
        onClick={toggleForm}
        style={{ display: 'block' }}>Decline</Button>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-red-500 text-lg text-white"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Decline Refund
        </ModalHeader>
        <ModalBody>
          <p>Are you sure that you would like to decline this refund?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="bg-red-500 hover:bg-red-700 text-white" type="submit" disabled={modalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                deleteRefund();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default DeleteButton;
