import React, {useState} from 'react';
import moment from "moment";
import { Button, Icon } from "../../../../../../../../components/Component";
import {Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import DatePicker from "react-datepicker";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";

const DateEdit = ({ data, name, description, bookingID, fieldName, triggerReload }) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);
  const [popoverOpen, togglePopover] = useState(false);

  const [ formValues, setFormValues ] = useState({
    date: data ? moment(data).utcOffset(0, true).format().split('T')[0] : '',
    date_pretty: data ? new Date(data).toDateString() : '',
  });

  const toggleForm = () => setModalForm(!modalForm);

  const updateDateField = (date) => {
    setFormValues({
      date: moment(date).utcOffset(0, true).format().split('T')[0],
      date_pretty: new Date(date).toDateString(),
    });
  };

  const updateFieldRequest = async() => {
    setModalFormProcessing(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.agent['booking:get'](bookingID),
      {
        method: 'post',
        data: {
          [fieldName]: formValues.date
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalFormProcessing(false);
    toggleForm();
    triggerReload();
  };

  return (
    <React.Fragment>
      <Col xl="6">
        <div>
          <div className="pr-2 pt-1 form-banner font-normal">
            { name }
            <Icon
              name="info"
              className="text-primary pl-1 pointer-event"
              style={{ cursor: 'pointer', display: popoverOpen ? 'none' : 'inline' }}
              onClick={() => togglePopover(popoverOpen === false)}
            />
            <div className="text-secondary" style={{ display: popoverOpen ? 'block' : 'none' }}>
              <small>{ description }</small>
            </div>
          </div>
          <div className="pr-2 pt-1">
            <div className="float-right">
              <Button
                id="create-payout-button"
                size="sm"
                color="white"
                outline
                className="btn-green text-white ml-2"
                onClick={toggleForm}
                style={{ height: '25px', borderRadius: '20px' }}
              >
                <Icon
                  name="edit"
                  className="text-white" />
              </Button>
            </div>
            <span className="data-value">
              { data ? moment(data).format('dddd Do MMMM YYYY') : 'Not Set' }
            </span>
          </div>
        </div>
      </Col>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-secondary text-white text-lg"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Update Booking { name }
        </ModalHeader>
        <ModalBody>
          <p className='mb-2'>Please enter the new { name.toLowerCase() }</p>
          <FormGroup>
            <div className="form-control-wrap">
              <DatePicker
                id="return_date"
                name="return_date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                value={formValues.date_pretty}
                onChange={(value) => updateDateField(value)}
                className={`form-control form-control-xl date-picker`}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="bg-red-600 hover:bg-red-800 text-white" type="submit" disabled={modalFormProcessing || formValues.date_pretty === '' || (data && formValues.date_pretty === new Date(data).toDateString())} onClick={
              (ev) => {
                ev.preventDefault();
                updateFieldRequest();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default DateEdit;
