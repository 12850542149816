import React, { useState, useEffect, useRef } from 'react';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent } from '../block/Block';
import { Card } from 'reactstrap';
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Skeleton from 'react-loading-skeleton'
import Filter from '../filter';

import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from "react-redux";
import Head from "../../layout/head/Head";
import moment from "moment";
import { setFiltersShowAllChildOrganisations } from "../../actions/organisationActions";

const filterPathFilters = (filters) => {
  const result = {};

  if (typeof filters === 'undefined' || filters === null) {
    return {};
  }

  Object.keys(filters).forEach((key) => {
    if (typeof filters[key] !== 'undefined' && filters[key] !== null) {
      result[key] = filters[key];
    }
  })

  return result;
};

const DataTable = ({
  RowFormatter,
  endpoint,
  title,
  PageOptions,
  TableHeaders,
  columnCount,
  dateGroupingField,
  searchPlaceholder,
  limit,
  autoReload,
  forceShowCount,
  itemTitle,
  hideCounter,
  pathFilters,
  childOrganisationsOption,
  TopBlock,
  SubBlock,
  showFilters,
  dateFilterOption,
  dateFilterLabel,
  loadingCount,
  hideFilteredItems,
  largeHeading,
  sort,
  noHeader,
  totalFilterOption,
  hideFilters,
  cardClass,
  defaultDateFilter,
}) => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const { account, piiObfuscated } = useSelector((state) => state.account);
  const { enums } = useSelector((state) => state.enums);

  const [oldSelectedTotal, setOldSelectedTotal] = useState(null);
  const [selectedTotal, setSelectedTotal] = useState(null);
  const [totals, setTotals] = useState(null);
  const [org, setOrg] = useState(null);
  const [data, setData] = useState([]);
  const [resultCount, setResultCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [fullLoading, setFullLoading] = useState(false);
  const [loadCount, triggerLoad] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [dateFilter, setDateFilter] = useState(defaultDateFilter || { startDate: null, endDate: null });
  const [dateFilterOld, setDateFilterOld] = useState(defaultDateFilter || { startDate: null, endDate: null });
  const { organisation } = useSelector((state) => state);
  const [reload, setReload] = useState(0);
  const [childFilterState, setChildFilterState] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [filters, setFilters] = useState({});
  const [triggerFullLoad, setTriggerFullLoad] = useState(false);
  const [reqestParams, setRequestParams] = useState(null);

  const filteredPathFilters = filterPathFilters(pathFilters);

  useEffect(() => {
    mounted.current = true;

    const fetchData = async () => {
      const childFilterEnabled = organisation?.filtering?.child_organisations_filtered === true;

      setLoading(true);

      const fullLoad = org !== organisation.organisation ||
        childFilterEnabled !== childFilterState ||
        triggerFullLoad === true ||
        oldSelectedTotal !== selectedTotal ||
        (dateFilter.startDate !== dateFilterOld.startDate || dateFilter.endDate !== dateFilterOld.endDate);

      setFullLoading(fullLoad);

      const user = await Auth.currentAuthenticatedUser();

      let requestParams = {
        ...filteredPathFilters,
        ...filters,
        date_from: dateFilter.startDate,
        date_to: dateFilter.endDate,
        take: limit ? limit : 25,
        keyword: searchText,
        skip: fullLoad === false ? data.length : 0,
        organisation: organisation.organisation,
      };
      setRequestParams(requestParams);

      if (sort) {
        requestParams.sort = sort;
      }

      if (childOrganisationsOption !== false) {
        requestParams['show-child-organisations'] = childFilterEnabled;
      }

      if (selectedTotal !== null) {
        requestParams['total_filter'] = selectedTotal;
      }

      const result = await Axios(
        endpoint,
        {
          method: 'post',
          data: requestParams,
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setRequestData(requestParams);
      setResultCount(result.data.meta.count);
      if (result.data?.meta?.totals) {
        setTotals(result.data.meta.totals);
      }

      let res = fullLoad === false ? [...data, ...result.data.data] : [...result.data.data];

      // Format the res data
      if (typeof dateGroupingField !== 'undefined' && dateGroupingField !== null) {
        let storedDatesContainer = {};

        res = res.map((item) => {
          let formattedDate = moment(item[dateGroupingField]).format('dddd Do MMMM YYYY');

          if (typeof storedDatesContainer[formattedDate] === 'undefined') {
            item.formattedDate = formattedDate;

            storedDatesContainer[formattedDate] = formattedDate;
          }

          return item;
        });
      }

      // Set the state
      setData(res);
      setOrg(organisation.organisation);
      setLoading(false);
      setFullLoading(false);
      setLoaded(true);
      setTriggerFullLoad(false);
      setChildFilterState(childFilterEnabled);
      setOldSelectedTotal(selectedTotal);
      setDateFilterOld(dateFilter);
    }

    if (organisation.fetching_organisations === false) {
      fetchData();
    }

    return () => {
      mounted.current = false;
    };
  }, [loadCount, organisation.organisation, reload, organisation.filtering.child_organisations_filtered, filters, selectedTotal, dateFilter, organisation.fetching_organisations]);

  useEffect(() => {
    if (loaded === true) {
      const delayDebounceFn = setTimeout(() => {
        setFullLoading(true);
        setData([]);
        triggerLoad(loadCount + 1);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchText])

  // Increase the loadCount
  const getNextPage = () => {
    triggerLoad(loadCount + 1);
  };

  // trigger a full reload of data
  const triggerReload = () => {
    setFullLoading(true);
    setData([]);
    setReload(reload + 1);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading || fullLoading,
    hasNextPage: true,
    onLoadMore: getNextPage,
    disabled: data.length === resultCount || data.length === 0 || autoReload === false,
    rootMargin: '0px 0px 1000px 0px',
  });

  const setChildOrganisations = (status) => {
    dispatch(setFiltersShowAllChildOrganisations(status));
  }

  const splitTitle = typeof title === 'string' ? title.split(' ') : [title];

  return (
    <React.Fragment>
      {
        (title || itemTitle) && (
          <Head title={itemTitle ? itemTitle : title} />
        )
      }

      {typeof TopBlock !== 'undefined' && TopBlock !== null && (
        <TopBlock requestParams={reqestParams} loading={loading} />
      )}
      {
        totalFilterOption === true && (
          <div className='grid flex h-auto pb-3 hidden lg:grid'>
            <div className='col-span-6 grid grid-cols-5 gap-4 flex h-auto'>
              {
                (!totals) && Array.from(Array(5).keys()).map((key) => (
                  <div className='border border-gray-300 p-2 h-auto rounded-md' key={key}>
                    <Skeleton count={1} height="20px" className="m-0 p-1" />
                    <Skeleton count={1} height="50px" className="m-0 p-1" />
                  </div>
                ))
              }
              {
                totals && Object.keys(totals).map((key, index) => {
                  const activeContainer = 'border p-2 h-auto rounded-md text-violet-800 border-violet-500';
                  const inactiveContainer = 'border border-gray-300 p-2 h-auto rounded-md cursor-pointer hover:border-violet-500';

                  const activeTitle = 'text-sm';
                  const inactiveTitle = 'text-sm text-slate-500';

                  const activeCount = 'text-2xl font-bold';
                  const inactiveCount = 'text-2xl font-bold text-slate-500';

                  const isActive = selectedTotal === key || (selectedTotal === null & index === 0) ? true : false;

                  return (
                    <div className={isActive ? activeContainer : inactiveContainer} key={index} onClick={() => setSelectedTotal(key)}>
                      <p className={isActive ? activeTitle : inactiveTitle}>{totals[key].headline}</p>
                      <p className={isActive ? activeCount : inactiveCount}>{totals[key] ? totals[key].count.toLocaleString(undefined, { minimumFractionDigits: 0 }) : '-'}</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
        )
      }

      <div className='relative pt-14 lg:pt-0'>
        {
          hideFilters !== true && (
            <div className='flex pb-3 col-span-6 hidden lg:flex'>
              {
                childOrganisationsOption !== false && organisation.organisations.length > 1 && (
                  <Filter
                    title="Child Organisations"
                    fieldTitle="Show Child Organisations"
                    type="checkbox"
                    value={organisation?.filtering?.child_organisations_filtered === true}
                    onToggle={() => setChildOrganisations(organisation?.filtering?.child_organisations_filtered === false)}
                  />
                )
              }

              {
                searchPlaceholder && showFilters !== false && hideFilteredItems !== true && (
                  <Filter
                    title={searchPlaceholder}
                    fieldTitle={searchPlaceholder}
                    type="text"
                    value={searchText}
                    onChange={(value) => setSearchText(value)}
                  />
                )
              }

              {
                dateFilterOption === true && showFilters !== false && hideFilteredItems !== true && (
                  <Filter
                    title={dateFilterLabel}
                    fieldTitle={dateFilterLabel}
                    type="date"
                    value={dateFilter}
                    onChange={(value) => setDateFilter(value)}
                  />
                )
              }

            </div>
          )
        }


        {typeof SubBlock !== 'undefined' && SubBlock !== null && (
          <SubBlock requestParams={reqestParams} enums={enums} />
        )}


        <div className={`col-span-6 ${cardClass || 'rounded-md border border-slate-300 animate-in fade-in-50'}`}>
          {
            title && (
              <BlockHead size="sm" className={noHeader !== true ? 'bg-slate-50 px-4 py-4' : 'absolute top-0 right-0'}>

                <BlockBetween>
                  {noHeader !== true && (
                    <BlockHeadContent>
                      <h2 className={`${largeHeading === true ? 'text-2xl' : 'text-xl'} font-normal text-purple-900 pb-0 mb-0`}>
                        {splitTitle.length >= 1 ? splitTitle[0] : ''}{splitTitle.length > 1 ? (
                          <span className="fw-normal">
                            {
                              splitTitle.map((item, index) => {
                                if (index === 0) return (<React.Fragment key={index} />);

                                return (<span className='font-bold' key={index}> {item}</span>);
                              })
                            }
                          </span>
                        ) : ''
                        }
                      </h2>

                      <BlockDes className="text-soft">
                        <p className='text-xs'>
                          {loading ? `Fetching ${itemTitle ? itemTitle : title.toLowerCase()}....` : `Found ${(resultCount ? resultCount : 0).toLocaleString('en-US')} ${itemTitle ? itemTitle : title.toLowerCase()}`}
                        </p>
                      </BlockDes>
                    </BlockHeadContent>
                  )}
                  {PageOptions && (
                    <BlockHeadContent>
                      <div className="toggle-expand-content" style={{ display: "block" }}>
                        <PageOptions requestParams={requestData} triggerReload={triggerReload} loading={loading} />
                      </div>
                    </BlockHeadContent>
                  )}
                </BlockBetween>

              </BlockHead>
            )
          }

          <Block>
            <Card>
              <div className="card-inner-group">

                <div className="card-inner p-0">
                  <table className="table table-tranx">

                    <thead>
                      {!dateGroupingField && typeof TableHeaders !== 'undefined' && (
                        <TableHeaders childFilterState={childFilterState} pathFilters={pathFilters} organisation={organisation} />
                      )}
                    </thead>
                    <tbody>
                      {data.length > 0 && fullLoading === false ?
                        data.map((item, id) => {
                          return (
                            <React.Fragment key={id}>
                              {typeof item.formattedDate !== 'undefined' && (
                                <React.Fragment>
                                  <tr>
                                    <td colSpan={columnCount} className="text-sm text-white pb-2 pt-2 bg-gradient-to-r from-slate-800 via-puple-700 to-purple-500 font-extralight">
                                      {item.formattedDate}
                                    </td>
                                  </tr>
                                  <TableHeaders childFilterState={childFilterState} pathFilters={pathFilters} organisation={organisation} />
                                </React.Fragment>
                              )}
                              <RowFormatter
                                item={item}
                                triggerReload={triggerReload}
                                childFilterState={childFilterState}
                                account={account}
                                piiObfuscated={piiObfuscated === true}
                                organisation={organisation}
                                enums={enums}
                                pathFilters={pathFilters}
                              />
                            </React.Fragment>
                          );
                        }) : null}
                      {(loading || fullLoading) && Array.from(Array(typeof loadingCount !== 'undefined' ? loadingCount : 9).keys()).map((key) => (
                        <tr className="tb-tnx-item" key={key}>
                          <td colSpan={columnCount}>
                            <Skeleton count={1} height="50px" className="m-0 p-1" />
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                  <span ref={sentryRef} />
                  {(data.length === resultCount || forceShowCount === true) && data.length > 0 && hideCounter !== true && (
                    <div className="card-inner bg-lighter">
                      <div className="text-center text-sm">
                        Showing <strong>{data.length} of {resultCount}</strong> {itemTitle ? itemTitle : title ? title.toLowerCase() : 'records'}
                      </div>
                    </div>
                  )}
                  {loading === false && fullLoading === false && data.length === 0 && (
                    <div className="card-inner">
                      <div className="text-center text-sm">
                        <span className="text-silent text-slate-600 font-bold">No {itemTitle ? itemTitle : title ? title.toLowerCase() : 'records'} found</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Block>
        </div>
      </div>

    </React.Fragment >
  )

}

export default DataTable;
