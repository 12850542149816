import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Block, BlockBetween, BlockHeadContent, BlockHead, BlockTitle } from 'src/components/block/Block';
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "src/components/pii-obfuscator";
import Skeleton from "react-loading-skeleton";
import { convertPenniesToDecimals } from "src/utils/money";
import moment from "moment";
import { Link, useHistory } from 'react-router-dom';
import { Button, Icon } from 'src/components/Component';

const DisbursementInformation = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const disbursement_id = urlParams.get('disbursement_id');

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [adjustmentsTotal, setAdjustmentsTotal] = useState(0);

  const getDisbursement = useCallback(async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.ledger["disbursement:get"](disbursement_id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    let adjustmentsTally = 0;
    if (result.data.data.adjustments && result.data.data.adjustments.length > 0) {
      result.data.data.adjustments.forEach((adjustment) => {
        adjustmentsTally += adjustment.amount;
      });
    }

    setAdjustmentsTotal(adjustmentsTally);
    setData(result.data.data);
    setLoading(false);
  }, [disbursement_id]);

  useEffect(() => {
    getDisbursement();
  }, [disbursement_id]);

  if (loading === true) {
    return (
      <div className='mb-4'>
        <Block>

          <BlockHead size="sm" className="border-b-2 mb-8 border-gray-200">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className="text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text" page>Disbursal <span className="fw-normal">Detail</span></BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3">
                      <Link to={'/disbursements'}>
                        <Button color="dark" className="btn-white text-violet-800 border-violet-800">
                          <Icon name="back-ios" />
                          <span>Back</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Card className="card-bordered card-stretch">
          <CardHeader className="bg-gray-50 text-purple-900 py-4">
            <h2 className="text-2xl text-purple-900">
              Disbursal <strong>Information</strong>
            </h2>
          </CardHeader>
            <CardBody>
              <Block>
                <div className="row g-3">
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Date:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Amount:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Bank Reference:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Receiving Account | Account Number:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Receiving Account | Sort Code:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Receiving Account | IBAN:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                  <div className="col-6">
                    <span className="sub-text text-violet-800">Receiving Account | Name:</span>
                    <Skeleton count={1} height="15px" width="100px" className="mb-2 p-1" />
                  </div>
                </div>
              </Block>
            </CardBody>
          </Card>
        </Block>
      </div>
    )
  }

  return (
    <Block className='mb-4'>
      <BlockHead size="sm" className="border-b-2 mb-8 border-gray-200">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className="text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text" page>Disbursal <span className="fw-normal">Detail</span></BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="toggle-expand-content">
                <ul className="nk-block-tools g-3">
                  <Link to={'/disbursements'}>
                    <Button color="dark" className="btn-white text-violet-800 border-violet-800">
                      <Icon name="back-ios" />
                      <span>Back</span>
                    </Button>
                  </Link>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Card className="card-bordered card-stretch">
        <CardHeader className="bg-gray-50 text-purple-900 py-4">
            <h2 className="text-2xl text-purple-900">
              Disbursal <strong>Information</strong>
            </h2>
          </CardHeader>
        <CardBody>
          <Block>
            <div className="row g-3">
              <div className="col-6">
                <span className="sub-text text-violet-800">Date:</span>
                <span>{moment(data.created_at).format('LLL')}</span>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Disbursal Amount:</span>
                <span>{CurrencyFormat().format(convertPenniesToDecimals(data.amount))}</span>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Adjustments:</span>
                <span>{CurrencyFormat().format(convertPenniesToDecimals(adjustmentsTotal))}</span>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Bank Reference:</span>
                <span>{data.bank_reference}</span>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Receiving Account | Account Number:</span>
                <PiiObfuscator type="account_number">{data.receiving_account.account_number}</PiiObfuscator>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Receiving Account | Sort Code:</span>
                <span>{data.receiving_account.sort_code}</span>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Receiving Account | IBAN:</span>
                <PiiObfuscator type="iban">{data.receiving_account.iban}</PiiObfuscator>
              </div>
              <div className="col-6">
                <span className="sub-text text-violet-800">Receiving Account | Name:</span>
                <PiiObfuscator type="company">{data.receiving_account.name}</PiiObfuscator>
              </div>
            </div>
          </Block>
        </CardBody>
      </Card>

      {data.adjustments && data.adjustments.length > 0 && (
        <div className="flex-col rounded-md border animate-in fade-in-50 mt-4">
          <CardHeader className="bg-gray-50 text-purple-900 py-4">
            <h2 className="text-2xl text-purple-900">
              Disbursal <strong>Adjustments</strong>
            </h2>
            <div className="nk-block-des text-soft"><p className="text-xs">Found { data.adjustments.length } Disbursal Adjustments</p></div>
          </CardHeader>
          <CardBody className="p-0">
            <table className="table table-tranx border-light card-bordered mb-0">
              <thead>
                <tr className="tb-tnx-head">
                  <th className='text-left'><span>ID</span></th>
                  <th className="border-l text-center"><span>Amount</span></th>
                  <th className="border-l text-center"><span>Description</span></th>
                  <th className="border-l text-center"><span>Vendor</span></th>
                  <th className="text-center"><span>Vendor Reference</span></th>
                </tr>
              </thead>
              <tbody>
                { data.adjustments.map((adjustment, key) => {

                  return (
                    <tr className="tb-tnx-item" key={key} >
                      <td>{ adjustment.id }</td>
                      <td className='border-l text-center'>{ CurrencyFormat().format(convertPenniesToDecimals(adjustment.amount)) }</td>
                      <td className='border-l text-center'>{ adjustment.description }</td>
                      <td className='border-l text-center'>{ adjustment.vendor_name }</td>
                      <td className='text-center'>{ adjustment.vendor_reference }</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
        </div>
      )}

    </Block>
  );

};

export default DisbursementInformation;
