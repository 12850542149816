import React, { useState } from 'react';
import {Row, Col, FormGroup, Card, Form, Spinner, Alert} from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Content from 'src/layout/content/Content';
import Head from 'src/layout/head/Head';
import {
  Block, BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Button, Icon,
} from 'src/components/Component';
import {Auth} from 'aws-amplify';
import Axios from 'axios';
import API_ROUTES from 'src/config/api';
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";

const APIKeyCreate = ({ ...props }) => {
  const [ key, setKey ] = useState(null);

  const { organisation } = useSelector((state) => state);

  if (key !== null) {
    return (
      <React.Fragment>
        <Head title="Create API Key" />
        <Content>

          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent className="mb-2" />
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3 pr-1">
                      <Link to={'/developers'}>
                        <Button color="light" outline className="btn-white" id="button__back">
                          <Icon name="back-ios" />
                          <span>Back</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Card className="card-bordered p-5">
            <BlockHead size="lg" wide="sm" className="p-0">
              <BlockHeadContent>
                <BlockTitle className="text-primary" page>API Key Detail</BlockTitle>
                <BlockDes className="fw-bold">It is important for you to immediately record these keys, you will not be able to see the private key again.</BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <div className="nk-divider divider md pb-2" />
            <Block size="lg">
              <Row className="g-3 align-center pt-2 pb-5">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label">Name</label>
                    <span className="form-note">This is the friendly name of your api key</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  { key.name }
                </Col>
              </Row>
              <Row className="g-3 align-center pb-5">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label">Public Key</label>
                    <span className="form-note">This is your public key and can be exposed to the public</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  { key.public_key }
                </Col>
              </Row>
              <Row className="g-3 align-center">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label">Secret Key</label>
                    <span className="form-note">This is your secret key. It should only be used within a backend environment and never exposed to users.</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  { key.secret_key }
                </Col>
              </Row>
            </Block>
          </Card>
        </Content>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
        <Head title="Create API Key" />
        <Content>
            <Card className="card-bordered p-5">
                <BlockHead size="lg" wide="sm" className="p-0">
                    <BlockHeadContent>
                        <BlockTitle className="text-primary" page>Create a New API Key</BlockTitle>
                    </BlockHeadContent>
                </BlockHead>

                <div className="nk-divider divider md pb-4" />

                <Block size="lg">
                    <Formik
                      initialValues={{
                          name: '',
                      }}
                      validationSchema={Yup.object().shape({
                          name: Yup.string()
                            .min(3)
                            .max(255)
                            .required('API Key name is required')
                      })}
                      onSubmit={async (values, { setSubmitting, setErrors }) => {
                        const user = await Auth.currentAuthenticatedUser();

                        try {
                          const response = await Axios(
                            API_ROUTES.token['key:create'](),
                            {
                              method: 'post',
                              data: {
                                name: values.name,
                                organisation: organisation.organisation,
                              },
                              headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
                            }
                          );
                          setKey(response.data.data);
                          setSubmitting(false);
                        } catch(error) {
                          setErrors({ general: 'An error has occurred, a member of our team has been notified. Please try again later.' });
                        }
                      }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => {
                            return (
                              <Form className="gy-3"  onSubmit={handleSubmit}>
                                {errors.general && (
                                  <div className="mb-3">
                                    <Alert color="danger" className="alert-icon">
                                      {" "}
                                      <Icon name="alert-circle" /> { errors.general }
                                    </Alert>
                                  </div>
                                )}
                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label">API Key Name</label>
                                              <span className="form-note">The name of the api key</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          <FormGroup>
                                              <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="form-control form-control-xl"
                                                  />
                                                  {touched.name && errors.name && <span className="invalid">{errors.name}</span>}
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                                  <Row className="g-3">
                                      <Col lg="7" className="offset-lg-5">
                                          <FormGroup>
                                              <Button
                                                type="submit"
                                                color="primary"
                                                size="lg"
                                                className="float-right bg-violet-700"
                                                disabled={Object.keys(errors).length >= 1}
                                              >
                                                  {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : 'Create'}
                                              </Button>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                              </Form>
                            );
                        }}
                    </Formik>
                </Block>
            </Card>
        </Content>
    </React.Fragment>

  );
};

export default APIKeyCreate;
