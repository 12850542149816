import React from 'react';
import HasFeature from "src/utils/has-feature";
import {useSelector} from "react-redux";

const BankStatus = ({ data }) => {
  const { organisation } = useSelector((state) => state);

  if (!HasFeature(organisation.features, ['aisp'])) {
    return (
      <React.Fragment>
        <em className={`pr-1 icon ni ni-alert font-weight-bold`} />
        Bank account not connected
      </React.Fragment>
    );
  }

  if (data.batch.length >= 1 && data.batch[0].disbursal?.bank_transaction?.id) {
    return (
      <React.Fragment>
        <em className={`pr-1 icon ni ni-check font-weight-bold`} />
        Found
      </React.Fragment>
    );
  }

  if (data?.settlement?.batch?.found_in_customer_account === true) {
    return (
      <React.Fragment>
        <em className={`pr-1 icon ni ni-check font-weight-bold`} />
        Found
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <em className={`pr-1 icon ni ni-alert font-weight-bold`} />
      Not Found
    </React.Fragment>
  );
};

export default BankStatus;
