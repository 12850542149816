import React, {useCallback, useEffect, useState} from "react";
import Head from "src/layout/head/Head";
import {
  Block,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import {Auth} from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from 'src/config/api';
import {Spinner} from "reactstrap";

const UserFeatures = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [changing, setChanging] = useState({
    enabled: false,
    ledgering: false,
    balancing: false,
  });
  const [enabled, setEnabled] = useState({
    enabled: false,
    ledgering: false,
    balancing: false,
  });

  const getPayoutConfiguration = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.user['organisation:payout-configuration'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setLoading(false)
    setEnabled(result.data.data)
  }, [id]);

  const changeStatus = async (item, status) => {
    setChanging({
      ...changing,
      [item]: true,
    });

    const user = await Auth.currentAuthenticatedUser();

    await Axios.post(
      API_ROUTES.user['organisation:payout-configuration:change'](id),
      JSON.stringify({ item, status }),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    setChanging({
      ...changing,
      [item]: false,
    });
    setEnabled({
      ...enabled,
      [item]: status,
    });
  }

  useEffect(() => {
    getPayoutConfiguration();
  }, [getPayoutConfiguration]);

  return (
    <div className="card-inner card-inner-lg">
      <Head title="Organisation Payouts" />
      <Block>
        <div style={{ display: loading === true ? 'block' : 'none' }} className="mt-3">
          <Skeleton count={5} height="50px" className="mb-4" style={{
            width: '100%',
          }}/>
        </div>

        { loading === false && (
          <React.Fragment>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label text-primary"><strong>Enabled</strong></span>
                <span>Whether payouts should be made to this organisations beneficiaries</span>
              </div>
              <div className="data-col data-col-end">
                <div className="data-col data-col-end">
                  <span>{ changing.enabled && <Spinner size="sm" className="mt-1 mr-3"> </Spinner> }</span>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      checked={enabled.enabled}
                      disabled={changing.enabled}
                      onChange={() => changeStatus('enabled', enabled.enabled !== true) }
                      id={`payouts-switch`}
                    />
                    <label className="custom-control-label" htmlFor={`payouts-switch`} />
                  </div>
                </div>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label text-primary"><strong>Ledgering</strong></span>
                <span>Whether the ledger should be respected when making payouts</span>
              </div>
              <div className="data-col data-col-end">
                <div className="data-col data-col-end">
                  <span>{ changing.ledgering && <Spinner size="sm" className="mt-1 mr-3"> </Spinner> }</span>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      checked={enabled.ledgering}
                      disabled={changing.ledgering}
                      onChange={() => changeStatus('ledgering', enabled.ledgering !== true) }
                      id={`ledgering-switch`}
                    />
                    <label className="custom-control-label" htmlFor={`ledgering-switch`} />
                  </div>
                </div>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label text-primary"><strong>Account Balancing</strong></span>
                <span>Balancing off adjustments against ledger balance. <br/ ><small className="text-red-800">This should only be enabled for brand new accounts, as does not consider manual adjustments, work needs to be done on a per agent level before this can be enabled.</small></span>
              </div>
              <div className="data-col data-col-end">
                <div className="data-col data-col-end">
                  <span>{ changing.balancing && <Spinner size="sm" className="mt-1 mr-3"> </Spinner> }</span>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      checked={enabled.balancing}
                      disabled={changing.balancing}
                      onChange={() => changeStatus('balancing', enabled.balancing !== true) }
                      id={`balancing-switch`}
                    />
                    <label className="custom-control-label" htmlFor={`balancing-switch`} />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

      </Block>
    </div>
  );
};
export default UserFeatures;
