import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { PlusIcon } from '@heroicons/react/24/outline';

const DateFilter = ({ onToggle, title, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleValueChange = (newValue) => {
    setSelectionRange({
      ...selectionRange,
      startDate: newValue.selection.startDate,
      endDate: newValue.selection.endDate,
    });
  }

  const applyFilter = () => {
    onChange({
      startDate: selectionRange.startDate.toISOString().split('T')[0],
      endDate: selectionRange.endDate.toISOString().split('T')[0],
    });
    onToggle(false);
    setIsOpen(false);
  }

  return (
    <div className={`relative mr-3 hs-dropdown-toggle p-0 rounded-md hover:bg-slate-50 text-violet-800 border relative cursor-pointer ${isOpen ? 'border-slate-700 bg-gray-50' : 'border-violet-800 bg-white'}`}>
      {isOpen && (
        <div className='absolute z-50 top-10 border-gray-400 border rounded-md overflow-hidden bg-white'>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleValueChange}
          />
          <button
            onClick={applyFilter}
            className='btn btn btn-outline-light bg-violet-800 text-white mr-1 border-none px-4 w-[95%] my-2 mx-2 text-center hover:bg-slate-600 justify-center'
          >
            Apply Filter
          </button>
        </div>
      )}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer bg-transparent z-10 hs-dropdown-toggle py-1 pl-2 inline-flex gap-x-2 text-xs font-left rounded-md hover:bg-slate-700 bg-white text-violet-800 placeholder-violet-800`}
      >
        {title}
        <div className='border-l pl-1 border-violet-800 pr-1'>
          <PlusIcon className='h-4 w-4 ' />
        </div>
      </div>
    </div>
  )
}

export default DateFilter;