import React, { Suspense, lazy } from "react";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import 'tippy.js/dist/tippy.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { Integrations } from "@sentry/tracing";
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { createRoot } from 'react-dom/client';

import { configureStore } from 'src/store';
import ErrorInterceptor from "./error-interceptor";
import posthog from 'posthog-js'

import './index.tailwind.css';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';

const Error404Modern = lazy(() => import("./pages/error/404-modern"));

Amplify.configure({
  "aws_project_region": process.env.REACT_APP_AWS_REGION,
  "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
  "aws_user_pools_id": process.env.REACT_APP_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_WEB_CLIENT_ID,
  "oauth": {}
});

const store = configureStore();

// Disable mousewheel scroll on number inputs
document.addEventListener("wheel", function(event){
  if(document.activeElement.type === "number"){
    document.activeElement.blur();
  }
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new Replay(),
  ],
  environment: process.env.REACT_APP_API_ENV,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  beforeSend(event) {
    if (event.message === 'ResizeObserver loop limit exceeded') {
      return null;
    }

    return event;
  }
});

if (typeof process.env.REACT_APP_POSTHOG_KEY !== 'undefined') {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, { api_host: 'https://analytics.felloh.com' });
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorInterceptor>
    <Provider store={store}>
      <LiveChatLoaderProvider providerKey={ process.env.REACT_APP_HELPSCOUT_KEY } provider="helpScout" idlePeriod={1}>
        <Suspense fallback={<div />}>
          <Router basename={`/`}>
            <Route render={({ location }) => (location.state && location.state.is404 ? <Error404Modern /> : <App />)} />
          </Router>
        </Suspense>
      </LiveChatLoaderProvider>
    </Provider>
  </ErrorInterceptor>
);
