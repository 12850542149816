import React from "react";

import { Card, CardImg, CardBody, CardTitle, CardText, Col, Badge, CardFooter } from 'reactstrap';

const IntegrationCard = ({ buttons, is_beta, logo, name, description, is_felloh }) => {
  return (
    <Col md="12" lg="4" xl="3">
      <Card className="card-bordered integration-item">
        <div className="image-container mb-0 py-0">
          {
            is_beta && (
              <Badge color="warning" className="position-absolute top-0 end-0 -mt-[1px] -mr-[1px] pr-1 rounded-r-none">Coming Soon</Badge>
            )
          }
          <CardImg top src={logo} alt={name} />
        </div>
        <CardBody className="card-inner pt-0 py-2 my-0">
          <CardTitle tag="h5" className='text-xl text-purple-900 font-bold py-0 my-0'>{name}</CardTitle>
          <CardText className='text-sm my-0 py-0'>{description}</CardText>
        </CardBody>
        <CardFooter>
          { buttons() }
        </CardFooter>
      </Card>
    </Col>
  );
}

export default IntegrationCard;