import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

const RiskMap = ({ data }) => {
  let locationData = data;

  return (
    <MapContainer
      center={[locationData.coordinates[1], locationData.coordinates[0]]}
      zoom={9}
      scrollWheelZoom={false}
      zoomControl={false}
      doubleClickZoom={false}
      touchZoom={false}
      boxZoom={false}
      className="h-[250px] w-95 grayscale-[100%] mt-2 mx-auto opacity-90 rounded-md"
    >
      <TileLayer
        attribution='&copy;'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
}

export default RiskMap;