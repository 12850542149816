import { takeEvery, put, select } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import Axios from "axios";

import { API_ROUTES } from "src/config/api";
import { LOAD } from 'src/actions/applicationActions';
import {
  SET_ORGANISATION,
  SET_ORGANISATION_FEATURES,
  SET_ORGANISATION_CURRENCIES,
  SET_ORGANISATIONS,
  FETCHING_ORGANISATIONS,
  FETCH_ORGANISATIONS,
  SET_ORGANISATION_PAYMENT_METHODS,
  SET_ORGANISATION_UNPAID,
  SET_ORGANISATION_INTEGRATIONS,
  SET_ORGANISATION_SURCHARGING,
} from 'src/actions/organisationActions';
import { SILENT_LOGIN } from "src/actions/accountActions";
import { getOrganisation } from "../selectors";

/**
 * Get the organisations
 * @returns
 */
function* getOrganisations() {
  let organisation = yield select(getOrganisation);
  let user = null;

  try { user = yield Auth.currentAuthenticatedUser() } catch {}

  if (organisation.fetching_organisations === true || user === null) {
    return null;
  }

  yield put({ type: FETCHING_ORGANISATIONS, payload: true});
  
  const result = yield Axios(
    API_ROUTES.user['organisations:get'](),
    {
      method: 'get',
      headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
    }
  );

  yield put({ type: SET_ORGANISATIONS, payload: result.data.data });
  yield put({ type: FETCHING_ORGANISATIONS, payload: false});
}

/**
 * Sets the organisation features
 * @returns 
 */
function* setOrganisationFeatures(trigger) {
  let organisation = yield select(getOrganisation);

  if (typeof organisation.organisation === 'undefined' || organisation.organisations.length === 0) return false;

  let features = [];
  let invoiceUnpaid = false;
  let surchargingEnabled = false;

  for (const org of organisation.organisations) {
    if (org.id === organisation.organisation) {
      surchargingEnabled = org?.surcharging_enabled || false;
      if (org.unpaid_invoice === true) {
        invoiceUnpaid = true;

        /* eslint-disable-next-line */
        org.features.forEach((feature) => {
          if (feature?.machine_name === 'booking-and-payment') {
            features.push(feature);
          }
        });
      } else {
        features = org.features;
      }
    }
  }

  yield put({ type: SET_ORGANISATION_FEATURES, payload: features});
  yield put({ type: SET_ORGANISATION_UNPAID, payload: invoiceUnpaid });
  yield put({ type: SET_ORGANISATION_SURCHARGING, payload: surchargingEnabled });
}

/**
 * Sets the organisation parameters
 * @returns 
 */
function* setOrganisationParams() {
  let organisation = yield select(getOrganisation);

  if (typeof organisation.organisation === 'undefined' || organisation.organisations.length === 0) return false;

  let currencies = [];
  let paymentMethods = [];
  let integrations = [];

  for (const org of organisation.organisations) {
    if (org.id === organisation.organisation) {
      currencies = org.currencies;
      paymentMethods = org.payment_methods;
      integrations = org.integrations;
    }
  }

  yield put({ type: SET_ORGANISATION_CURRENCIES, payload: currencies});
  yield put({ type: SET_ORGANISATION_PAYMENT_METHODS, payload: paymentMethods});
  yield put({ type: SET_ORGANISATION_INTEGRATIONS, payload: integrations });

}

export default function* sagas() {

  yield takeEvery(LOAD, function* () {
    yield put({ type: FETCHING_ORGANISATIONS, payload: false});
    yield getOrganisations();
  });

  yield takeEvery(SILENT_LOGIN, getOrganisations);
  yield takeEvery(FETCH_ORGANISATIONS, getOrganisations);
  yield takeEvery(SET_ORGANISATION, setOrganisationFeatures);
  yield takeEvery(SET_ORGANISATION, setOrganisationParams);

  yield takeEvery(SET_ORGANISATIONS, setOrganisationFeatures);
  yield takeEvery(SET_ORGANISATIONS, setOrganisationParams);
}
