import React from 'react';
import { Row, Button, Col } from 'reactstrap';
import { Icon } from 'src/components/Component';
import Head from 'src/layout/head/Head';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";

import WebhooksLogo from './images/webhooks.png'
import APILogo from './images/api.png'
import OpenBankingLogo from './images/open-banking.png'
import XeroLogo from './images/xero.png';
import ZapierLogo from './images/zapier.png';
import SlackLogo from './images/slack.png';
import QuickbooksLogo from './images/quickbooks.svg';
import BrowserWindows from './images/browser-windows.png';

import { PROVIDER_CONFIGURATION } from 'src/config/providers';
import IntegrationCard from './components/integration-card';

import { ScaleIcon } from '@heroicons/react/24/outline';

const Integrations = () => {
  const { organisation: { organisations, features }, account: { account } } = useSelector((state) => state);

  const isFelloh = organisations.map((org) => org.id).includes('X1234');

  return (
    <React.Fragment>

      <Head title="Integrations" />


      <Row className='mb-3 ml-1 mr-1 mt-2'>
        <div className='w-full clearfix'>
          <div className='float-right'>
            { isFelloh && (
              <Link to='/organisation/integrations/payment-weights'>
                <Button className='btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:text-slate-800 hover:border-slate-800'>
                  <ScaleIcon className="h-4 w-4 -mt-0.5 mr-1" />
                  Manage Weightings
                </Button>
              </Link>
            )}
          </div>
          <h2 className="nk-block-title page-title text-primary">Payment <span className="fw-normal">Integrations</span></h2>
        </div>
      </Row>

      <Row>
        <Col className="ml-1 mr-1">
          <Row>

            {Object.keys(PROVIDER_CONFIGURATION).map((providerId) => {
              const provider = PROVIDER_CONFIGURATION[providerId];

              return (
                <IntegrationCard
                  is_beta={provider.is_beta}
                  is_felloh={isFelloh}
                  logo={provider.logo}
                  name={provider.name}
                  description={provider.description}
                  buttons={() => (
                    <React.Fragment>
                      {isFelloh && (
                        <React.Fragment>
                          <Link to={provider.manage_path}>
                            <Button className='btn-block w-100 text-center bg-violet-800 border-violet-800'>
                              <Icon name="setting" />Configure
                            </Button>
                          </Link>
                        </React.Fragment>
                      )}
                      {!isFelloh && (
                        <Button className='disabled:bg-red-800 disabled:border-red-800 btn-block w-100 text-center' disabled>
                          You do not have access to manage this feature
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                />
              );
            })}

          </Row>

        </Col>
      </Row >

      <Row className="mb-3 ml-1 mr-1 mt-5">
        <h2 className="nk-block-title page-title text-primary">Accounting & Finance <span className="fw-normal">Integrations</span></h2>
      </Row>

      <Row>
        <Col className="ml-1 mr-1">
          <Row>

            <IntegrationCard
              logo={OpenBankingLogo}
              name="Banking Integration"
              description="Link your bank account, for easy end to end reconcilliation of payments."
              buttons={() => (
                <React.Fragment>
                  {HasFeature(features, ['aisp']) && HasRole(account, ['open-banking:manage']) && (
                    <Link to='/organisation/bank'>
                      <Button className='btn-block w-100 text-center bg-violet-800 border-violet-800'>
                        <Icon name="setting" />Configure
                      </Button>
                    </Link>
                  )}
                  {(!HasFeature(features, ['aisp']) || !HasRole(account, ['open-banking:manage'])) && (
                    <Button className='disabled:bg-red-800 disabled:border-red-800 btn-block w-100 text-center' disabled>
                      You do not have access to this feature
                    </Button>
                  )}
                </React.Fragment>
              )}
            />

            <IntegrationCard
              is_beta={true}
              logo={QuickbooksLogo}
              name="Quickbooks"
              description="Reconcile disbursements to Quickbooks from your Felloh account."
              buttons={() => (
                <Button className='btn-green btn-block w-100 text-center' disabled>
                  Coming Soon
                </Button>
              )}
            />

            <IntegrationCard
              is_beta={true}
              logo={XeroLogo}
              name="Xero"
              description="Reconcile disbursements to Xero from your Felloh account."
              buttons={() => (
                <Button className='btn-green btn-block w-100 text-center' disabled>
                  Coming Soon
                </Button>
              )}
            />

          </Row>

        </Col>

      </Row>

      <Row className="mb-3 ml-1 mr-1 mt-5">
        <h2 className="nk-block-title page-title text-primary">Other <span className="fw-normal">Integrations</span></h2>
      </Row>

      <Row>
        <Col className="ml-1 mr-1">
          <Row className='d-flex'>

            <IntegrationCard
              is_beta={true}
              logo={APILogo}
              name="Felloh API"
              description="Programatically access any of the Felloh API's to build custom integrations."
              buttons={() => (
                <React.Fragment>
                  {HasRole(account, ['developers:manage:keys']) && HasFeature(features, ['developers']) && (
                    <Row>
                      <Col sm="12" lg="6">
                        <Link to='/developers'>
                          <Button className='btn-block w-100 text-center bg-violet-800 border-violet-800'>
                            <Icon name="setting" />Configure
                          </Button>
                        </Link>
                      </Col>
                      <Col sm="12" lg="6">
                        <a href='https://developers.felloh.com' target='__blank'>
                          <Button className='btn-secondary btn-block w-100 text-center'>
                            <Icon name="setting" />Documentation
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  )}
                  {(!HasFeature(features, ['developers']) || !HasRole(account, ['developers:manage:keys'])) && (
                    <Button className='disabled:bg-red-800 disabled:border-red-800 btn-block w-100 text-center' disabled>
                      You do not have access to this feature
                    </Button>
                  )}
                </React.Fragment>
              )}
            />

            <IntegrationCard
              logo={BrowserWindows}
              name="Felloh Payment Portal"
              description="Host a payment portal on your domain, allowing customers to pay themselves."
              buttons={() => (
                <React.Fragment>
                  <Row>
                    <Col sm="12" lg="6">
                      <Link to='/organisation/portal'>
                        <Button className='btn-block w-100 text-center bg-violet-800 border-violet-800'>
                          <Icon name="setting" />Configure
                        </Button>
                      </Link>
                    </Col>
                    <Col sm="12" lg="6">
                      <a href='https://developers.felloh.com' target='__blank'>
                        <Button className='btn-secondary btn-block w-100 text-center'>
                          <Icon name="setting" />Documentation
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            />

            <IntegrationCard
              is_beta={true}
              logo={SlackLogo}
              name="Slack"
              description="Receive payments & disbursement notifications to slack."
              buttons={() => (
                <Button className='btn-green btn-block w-100 text-center' disabled>
                  Coming Soon
                </Button>
              )}
            />

            <IntegrationCard
              logo={WebhooksLogo}
              name="Webhooks"
              description="Receive webhooks when changes happen within the felloh ecosystem."
              buttons={() => (
                <React.Fragment>
                  {HasRole(account, ['developers:manage:webhooks']) && HasFeature(features, ['developers']) && (
                    <Row>
                      <Col sm="12" lg="6">
                        <Link to='/developers'>
                          <Button className='btn-block w-100 text-center bg-violet-800 border-violet-800'>
                            <Icon name="setting" />Configure
                          </Button>
                        </Link>
                      </Col>
                      <Col sm="12" lg="6">
                        <a href='https://developers.felloh.com/webhooks' target='__blank'>
                          <Button className='btn-secondary btn-block w-100 text-center'>
                            <Icon name="setting" />Documentation
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  )}
                  {(!HasFeature(features, ['developers']) || !HasRole(account, ['developers:manage:webhooks'])) && (
                    <Button className='disabled:bg-red-800 disabled:border-red-800 btn-block w-100 text-center' disabled>
                      You do not have access to this feature
                    </Button>
                  )}
                </React.Fragment>
              )}
            />

            <IntegrationCard
              is_beta={true}
              logo={ZapierLogo}
              name="Zapier"
              description="Integrate payments with a wide range of technology providers."
              buttons={() => (
                <Button className='btn-green btn-block w-100 text-center' disabled>
                  Coming Soon
                </Button>
              )}
            />

          </Row>
        </Col>
      </Row >

    </React.Fragment >
  );
};

export default Integrations;
