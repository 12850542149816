import React, {useState} from 'react';
import {Button, Icon} from 'src/components/Component';
import {Alert, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";

const ReassignButton = ({ data, triggerReload }) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);
  const [bookingRef, setBookingRef] = useState('');
  const [errors, setErrors] = useState(null);

  const toggleForm = () => setModalForm(!modalForm);

  const reassign = async () => {
    setErrors(null);
    setModalFormProcessing(true);
    const user = await Auth.currentAuthenticatedUser();

    try {
      const response = await Axios(
        API_ROUTES.agent['booking:update-reference'](data.id),
        {
          method: 'post',
          data: {
            booking_reference: bookingRef,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          bubbleErrors: [422],
        }
      );

      if (typeof response?.data?.data?.new_booking_id !== 'undefined') {
        window.location.href = `/booking/${response.data.data.new_booking_id}`;
      } else {
        setModalFormProcessing(false);
        triggerReload();
        toggleForm();
      }
    } catch (error) {
      setModalFormProcessing(false);
      setErrors(error.response.data.errors[0].message);
    }
  };

  return (
    <React.Fragment>
      <div className="float-right">
        <Button
          id="create-payout-button"
          size="sm"
          color="white"
          outline
          className="btn-green text-white ml-2"
          onClick={toggleForm}
          style={{ height: '25px', borderRadius: '20px' }}
        >
          <Icon
            name="edit"
            className="text-white" />
        </Button>
      </div>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-secondary text-white text-lg"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Reassign Booking
        </ModalHeader>
        <ModalBody>
          {errors && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> { errors }
              </Alert>
            </div>
          )}
          <p className='mb-2'>Please enter the new booking reference</p>
          <FormGroup>
            <div className="form-control-wrap">
              <input
                type="text"
                id="bookingref"
                name="bookingref"
                value={bookingRef}
                onChange={(event) => setBookingRef(event.target.value)}
                placeholder="e.g. XXX-123"
                className="form-control form-control-xl"
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="text-white bg-red-600 hover:bg-red-700" type="submit" disabled={modalFormProcessing || bookingRef.length === 0} onClick={
              (ev) => {
                ev.preventDefault();
                reassign();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ReassignButton;
