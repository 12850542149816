import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

import Integrations from "./pages/integrations";
import Users from "./pages/users";
import User from "./pages/user";
import Audit from "./pages/audit";
import Billing from "./pages/billing";
import Suppliers from "./pages/suppliers";
import SupplierCreate from "./pages/supplier/create";
import Beneficiaries from "./pages/beneficiaries";
import BeneficiariesCreate from "./pages/beneficiaries/create";
import Bank from "./pages/bank";
import Portal from "src/pages/organisation/pages/portal";
import PaymentProvider from "src/pages/organisation/pages/intergration/payment-provider";
import PaymentWeights from "src/pages/organisation/pages/intergration/payment-weights";
import CreatePaymentProvider from "src/pages/organisation/pages/intergration/provider-manage/create";
import EditPaymentProvider from "src/pages/organisation/pages/intergration/provider-manage/edit";

import RedirectRenderer from "src/components/redirect-renderer";
import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";
import GroupMenu from "src/components/group-menu";

import {
  ArrowsRightLeftIcon,
  WindowIcon,
  BuildingLibraryIcon,
  FingerPrintIcon,
  Square3Stack3DIcon,
  BuildingOffice2Icon,
  UsersIcon,
  DocumentDuplicateIcon,
  ScaleIcon,
} from "@heroicons/react/24/outline";

import { PROVIDER_CONFIGURATION } from "src/config/providers";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Audit Trail', icon: FingerPrintIcon, path: '/organisation/audit', check: HasRole(account, ['organisation:audit']) && HasFeature(features, ['audit']) },
    { name: 'Beneficaries', icon: BuildingLibraryIcon, path: '/organisation/beneficaries', check: HasRole(account, ['beneficiaries:manage']) && HasFeature(features, ['payouts']) },
    { name: 'Billing', icon: DocumentDuplicateIcon, path: '/organisation/billing', check: HasRole(account, ['billing:view']) },
    { name: 'Suppliers', icon: BuildingOffice2Icon, path: '/organisation/suppliers', check: HasRole(account, ['suppliers:manage']) && HasFeature(features, ['suppliers']) },
    { name: 'Users', icon: UsersIcon, path: '/organisation/users', check: HasRole(account, ['users:manage']) && HasFeature(features, ['users']) },
    { name: 'Integrations', icon: Square3Stack3DIcon, path: '/organisation/integrations', check: HasRole(account, ['organisation:integrations']) },
    { name: 'Banking Integrations', parent: 'Integrations', visible: false, icon: Square3Stack3DIcon, path: '/organisation/bank', check: HasFeature(features, ['aisp']) && HasRole(account, ['open-banking:manage']) },
    { name: 'Felloh Portal Configuration', parent: 'Integrations', visible: false, icon: WindowIcon, path: '/organisation/portal', check: HasRole(account, ['organisation:integrations']) },
    { name: 'Payment Weighting(s)', parent: 'Integrations', icon: ScaleIcon, visible: false, path: '/organisation/integrations/payment-weights', check: HasRole(account, ['organisation:integrations']) }, 
  ];

  Object.keys(PROVIDER_CONFIGURATION).forEach((key) => {
    const provider = PROVIDER_CONFIGURATION[key];
    items.push({
      name: `${provider.display_name} Integration`,
      parent: 'Integrations',
      icon: ArrowsRightLeftIcon,
      visible: false,
      path: provider.manage_path,
      check: HasRole(account, ['organisation:integrations']),
    });
  });

  return (
    <div id="content">
      <GroupMenu items={items} title="Organisation" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const BasePathRenderer = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  let path = '/';

  if (HasRole(account, ['users:manage']) && HasFeature(features, ['users'])) {
    path = `/organisation/users`;
  } else if (path === '' && HasRole(account, ['organisation:integrations'])) {
    path = `/organisation/integrations`;
  } else if (path === '' && HasRole(account, ['organisation:audit'])) {
    path = `/organisation/audit`;
  } else if (path === '' && HasRole(account, ['billing:view'])) {
    path = `/organisation/billing`;
  } else if (path === '' && HasRole(account, ['suppliers:manage']) && HasFeature(features, ['suppliers'])) {
    path = `/organisation/suppliers`;
  } else if (path === '' && HasRole(account, ['beneficiaries:manage']) && HasFeature(features, ['payouts'])) {
    path = `/organisation/beneficaries`;
  } else if (path === '' && HasFeature(features, ['aisp']) && HasRole(account, ['open-banking:manage'])) {
    path = `/organisation/bank`;
  }

  return (
    <Route exact path="/organisation" component={(props) => {
      return (
        <Redirect to={path} />
      )
    }}
    />
  );
};

const redirects = [
  { from: '/integrations', to: '/organisation/integrations' },
  { from: '/users', to: '/organisation/users' },
  { from: '/users/:id', to: '/organisation/user/:id' },
  { from: '/users/:id/roles', to: '/organisation/user/:id/roles' },
  { from: '/users/:id/organisations', to: '/organisation/user/:id/organisations' },
  { from: '/audit', to: '/organisation/audit' },
  { from: '/billing', to: '/organisation/billing' },
  { from: '/suppliers', to: '/organisation/suppliers' },
  { from: '/supplier/create', to: '/organisation/supplier/create' },
  { from: '/beneficiaries', to: '/organisation/beneficiaries' },
  { from: '/beneficiaries/create', to: '/organisation/beneficiaries/create' },
];

const Payments = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        {RedirectRenderer({ redirects })}
        {BasePathRenderer()}

        {HasRole(account, ['organisation:integrations']) && (
          <React.Fragment>
            <Route exact path={`/organisation/integrations`} component={() => <Layout><Integrations /></Layout>} />
            <Route exact path={`/organisation/portal`} component={() => <Layout><Portal /></Layout>} />
            <Route exact path={`/organisation/integrations/payment-weights`} component={() => <Layout><PaymentWeights /></Layout>} />

            { Object.keys(PROVIDER_CONFIGURATION).map((key) => {
              const provider = PROVIDER_CONFIGURATION[key];
              return (
                <Route exact path={provider.manage_path} component={() =>
                  <Layout><PaymentProvider providerID={key} providerName={provider.name} /></Layout>}
                />
              );
            })}

            <Route exact path={`/organisation/integrations/provider/:id/create`} component={() => <Layout><CreatePaymentProvider /></Layout>} />
            <Route exact path={`/organisation/integrations/provider/:id/edit`} component={() => <Layout><EditPaymentProvider /></Layout>} />

          </React.Fragment>
        )}

        {HasFeature(features, ['aisp']) && HasRole(account, ['open-banking:manage']) && (
          <React.Fragment>
            <Route exact path={`/organisation/bank`} component={() => <Layout><Bank /></Layout>} />
          </React.Fragment>
        )}

        {HasRole(account, ['users:manage']) && HasFeature(features, ['users']) && (
          <React.Fragment>
            <Route exact path={`/organisation/users`} component={() => <Layout><Users /></Layout>} />
            <Route exact path={`/organisation/user/:id`} component={() => <Layout><User /></Layout>} />
            <Route exact path={`/organisation/user/:id/roles`} component={() => <Layout><User /></Layout>} />
            <Route exact path={`/organisation/user/:id/organisations`} component={() => <Layout><User /></Layout>} />
          </React.Fragment>
        )}

        {HasRole(account, ['organisation:audit']) && HasFeature(features, ['audit']) && (
          <Route exact path={`/organisation/audit`} component={() => <Layout><Audit /></Layout>} />
        )}

        {HasRole(account, ['billing:view']) && (
          <Route exact path={`/organisation/billing`} component={() => <Layout><Billing /></Layout>} />
        )}

        {HasRole(account, ['suppliers:manage']) && HasFeature(features, ['suppliers']) && (
          <React.Fragment>
            <Route exact path={`/organisation/suppliers`} component={() => <Layout><Suppliers /></Layout>} />
            <Route exact path={`/organisation/supplier/create`} component={() => <Layout><SupplierCreate /></Layout>} />
          </React.Fragment>
        )}

        {
          HasRole(account, ['beneficiaries:manage']) && HasFeature(features, ['payouts']) && (
            <React.Fragment>
              <Route exact path={`/organisation/beneficaries`} component={() => <Layout><Beneficiaries /></Layout>} />
              <Route exact path={`/organisation/beneficaries/create`} component={() => <Layout><BeneficiariesCreate /></Layout>} />
            </React.Fragment>
          )}


      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Payments;
