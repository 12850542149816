import React, { useCallback, useEffect, useState } from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  Icon,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux';

import 'react-loading-skeleton/dist/skeleton.css'
import { Auth } from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from 'src/config/api';
import { Button, Spinner } from "reactstrap";
import { brandLogoTransformer } from "src/transformer/transaction-type";
import CountryTypeTransformer from "src/transformer/country-type-transformer";

const UserFeatures = () => {
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [directDebitSetup, setDirectDebitSetup] = useState(false);
  const [rates, setRates] = useState([]);
  const [reload, setReload] = useState(0);

  const { organisation } = useSelector((state) => state);

  const getPayoutConfiguration = useCallback(async () => {
    if (organisation?.organisation) {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.user['organisation:surcharging:get'](organisation.organisation),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setLoading(false)
      setEnabled(result.data.data.enabled);
      setDirectDebitSetup(result.data.data.direct_debit);
      setRates(result.data.data.rates)
    }
  }, [organisation, reload]);

  const toggleChange = async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.user['organisation:surcharging:toggle'](organisation.organisation),
      {
        method: 'post',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setReload(reload + 1);
  }

  useEffect(() => {
    getPayoutConfiguration();
  }, [getPayoutConfiguration]);

  if (loading) return (
    <div>
      <Head title="Organisation Surcharging" />
      <Block>
      <div className="mt-3">
          <Skeleton count={1} height="130px" className="mb-4" style={{
            width: '100%',
          }} />
        </div>
        <div className="mt-3">
          <Skeleton count={5} height="50px" className="mb-4" style={{
            width: '100%',
          }} />
        </div>
      </Block>
    </div>
  );

  if (directDebitSetup === false) return (
    <div>
      <Head title="Organisation Surcharging" />
      <Block>
        <div className="border bg-red-100 border border-red-400 rounded-md">
          <div className="text-white overflow-hidden p-4">
            <a href="https://pay.gocardless.com/BRT00033GYYPPQG" target="__blank" className="float-right block mt-1">
              <Button color="primary" size="md">Setup Direct Debit</Button>
            </a>
            <div className="text-red-700 text-lg font-semibold">
              <p className="text-md text-red-700"><strong>You do not have a direct debit setup</strong></p>
              <p className="text-sm text-red-700">A direct debit is required in order to use surcharging. Felloh charges 10% of surcharges for this feature, invoices are raised on a monthly basis.</p>
            </div>
          </div>
        </div>
      </Block>
    </div>
  );

  return (
    <div>
      <Head title="Organisation Surcharging" />
      <Block>
        <div className="data-item border bg-purple-200 rounded-md">
          <div className="data-col text-purple-900">
            <span className="text-purple-900 pl-1 text-lg font-semibold">
              Surcharging is <strong>{enabled === true ? 'Enabled' : 'Disabled'}</strong>
              <br />
              <span className="text-sm text-slate-900">Toggle the switch to enable or disable surcharging</span>
            </span>
          </div>
          <div className="data-col data-col-end">
            <div className="data-col data-col-end">
              <span>{loading && <Spinner size="sm" className="mt-1 mr-3"> </Spinner>}</span>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  checked={enabled}
                  disabled={loading}
                  onChange={() => toggleChange()}
                  id={`payouts-switch`}
                />
                <label className="custom-control-label" htmlFor={`payouts-switch`} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 px-4">
          <div className="border-b border-purple-900 pb-2 mb-4">
            <h2 className="text-purple-900 text-xl font-semibold">Applicable Surcharging Rates</h2>
            <p className="text-sm text-slate-600">Surcharges can only be applied to international & corporate cards. Surcharges are based on your acquiring rates (inline with scheme rules).</p>
          </div>

          <table className="table-auto w-full">
            <thead>
              <tr>
                <th scope="col" className="pb-2 text-start text-xs font-medium text-gray-500 uppercase">Card Type</th>
                <th scope="col" colSpan={2} className="pb-2 text-start text-xs font-medium text-gray-500 uppercase text-left">Scheme</th>
                <th scope="col" className="pb-2 text-start text-xs font-medium text-gray-500 uppercase">Level</th>
                <th scope="col" className="pb-2 text-start text-xs font-medium text-gray-500 uppercase">Region</th>
                <th scope="col" className="pb-2 text-start text-xs font-medium text-gray-500 uppercase">Surcharge</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {rates.map((rate, index) => {
                const transactionType = brandLogoTransformer(rate.scheme);
                const countryType = CountryTypeTransformer(rate.countryType);

                return (
                  <tr key={index}>
                    <td className="py-2 whitespace-nowrap text-sm font-medium text-gray-800">{rate.type}</td>
                    <td className="text-center border-r align-middle w-[45px]">
                    <div style={{ width: '38px', padding: '0 4px 0 6px', maxWidth: '38px', margin: '0 auto' }}>
                      {
                        typeof transactionType.image !== 'undefined' && (
                          <img src={transactionType.image} className={`${transactionType.imageClass} -mt-0.5`} />
                        )
                      }
                      {
                        typeof transactionType.image === 'undefined' && (
                          <Icon name={`ni text-primary icon ${transactionType.icon}`} style={{ fontSize: '21px' }} />
                        )
                      }
                      </div>
                    </td>
                    <td className="py-2 whitespace-nowrap text-sm text-gray-800">{transactionType.name}</td>
                    <td className="py-2 whitespace-nowrap text-sm text-gray-800">{rate.level}</td>
                    <td className="py-2 whitespace-nowrap text-sm text-gray-800">{countryType}</td>
                    <td className="py-2 whitespace-nowrap text-sm text-gray-800">{rate.cost}%</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

      </Block>
    </div>
  );
};
export default UserFeatures;
