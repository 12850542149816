import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';

import Head from 'src/layout/head/Head';
import { setUserData } from 'src/actions/accountActions';

import LoginForm from './forms/login-form';
import ConfirmationForm from './forms/confirmation-form';
import MagicLinkForm from './forms/magic-link-form';
import PasswordResetForm from './forms/password-reset-form';

import OrganisationSelect from "./views/organisation-select";

import LogoDark2x from "../../images/logo-dark2x.png";

export const VIEWS = {
  LOGIN: 'LOGIN',
  CONFIRMATION: 'CONFIRMATION',
  ORGANISATION_SELECT: 'ORGANISATION_SELECT',
  MAGIC_LINK: 'MAGIC_LINK',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
}

const Login = ({ page }) => {
  const [view, setView] = useState(page ? page : VIEWS.LOGIN);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const { account } = useSelector((state) => state);

  useEffect(() => {
    if (account.user && typeof account.user !== 'undefined') {
      setView(VIEWS.ORGANISATION_SELECT);
    }
  }, [account.user])

  const handleSubmitSuccess = async (userResponse) => {
    if (typeof userResponse.attributes !== 'undefined') {
      const data = await Auth.currentUserInfo();
      await dispatch(setUserData(data));

      setView(VIEWS.ORGANISATION_SELECT);
    }
  };

  const handleSubmitFailure = async (error, email, password) => {
    setUsername(email);
    setPassword(password);

    if (error.name === 'UserNotConfirmedException') {
      setView(VIEWS.CONFIRMATION);
    }
  };

  return (
    <React.Fragment>
      <Head title="Login" />
      <div className="flex h-screen">

        <div className="hidden lg:flex items-center flex-1 bg-purple-800 text-white">
          <div className="max-w-lg mx-auto">
            <img className="logo-dark logo-img h-max-100px" src={LogoDark2x} alt="logo" />
            <p className='text-white text-3xl ml-3 font-extralight'>Payments operations for travel</p>

            <div className="mt-10 mx-2 bg-purple-900">

              <div className='w-100 px-4 py-3'>
                <p className='font-bold mb-2'>Major Version Update 2024.03</p>
              </div>

              <div className='px-5'>
                <ul className='list-disc text-sm'>
                  <li className='mb-2'>We've revamped the dashboard to enhance navigation and seamlessly manage our expanding array of features</li>
                  <li className='mb-2'>Transaction charges can be accessed directly from the dashboard upon request</li>
                  <li className='mb-2'>We've introduced the concept of customers, establishing connections among all entities, including payment links, transactions, and bookings</li>
                  <li className='mb-2'>Tokenization is now available, enabling you to securely store and recharge customer cards</li>
                  <li className='mb-2'>Surcharging is avaialable for international and corporate cards</li>
                  <li className='mb-2'>We have improved date filtering across reports</li>
                  <li className='mb-2'>Pre-authorisation of payments is now available</li>
                </ul>
              </div>

              <div className='w-100 px-2 py-2 bg-slate-300 text-purple-900 text-center mt-6'>
                <p className='text-sm'>To activate or discuss any features, please email <a className='underline' href="mailto:help@felloh.com">help@felloh.com</a></p>
              </div>

            </div>
          </div>
        </div>

        <div className={`w-full bg-gray-50 lg:w-3/5 flex justify-center ${view !== VIEWS.ORGANISATION_SELECT ? 'items-center': ''}`}>
          <div className="max-w-xl w-full p-6">
            <div className="mt-3">
              {view === VIEWS.FORGOT_PASSWORD && (
                <PasswordResetForm
                  onSubmitSuccess={handleSubmitSuccess}
                  onSubmitFailure={handleSubmitFailure}
                  setView={setView}
                />
              )}
              {view === VIEWS.LOGIN && (
                <LoginForm
                  onSubmitSuccess={handleSubmitSuccess}
                  onSubmitFailure={handleSubmitFailure}
                  setView={setView}
                />
              )}
              {view === VIEWS.CONFIRMATION && (
                <ConfirmationForm
                  onSubmitSuccess={handleSubmitSuccess}
                  onSubmitFailure={handleSubmitFailure}
                  username={username}
                  password={password}
                />
              )}
              {view === VIEWS.MAGIC_LINK && (
                <MagicLinkForm
                  onSubmitSuccess={handleSubmitSuccess}
                  onSubmitFailure={handleSubmitFailure}
                  setView={setView}
                />
              )}
              {view === VIEWS.ORGANISATION_SELECT && (
                <OrganisationSelect />
              )}
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default Login;
