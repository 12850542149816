import React, { Suspense, useLayoutEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RedirectAs404 } from 'src/utils/Utils';

import Dashboard from 'src/pages/dashboard';
import Payments from 'src/pages/payments';
import Bookings from 'src/pages/bookings';
import Admin from 'src/pages/admin';
import Developers from 'src/pages/developers';
import Customers from 'src/pages/customers';
import Organisation from 'src/pages/organisation';
import Settlement from 'src/pages/settlement';
import Surcharging from 'src/pages/surcharging';

import UserProfileLayout from 'src/pages/user/UserProfileLayout';

import FragmentSupportingSwitch from 'src/components/fragment-supporting-switch';
import OrganisationModal from "src/components/organisation/modal";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const { organisation: { organisation } } = useSelector((state) => state);

  if (organisation === null) {
    return <OrganisationModal />
  }

  return (
    <Suspense fallback={<div />}>
      <FragmentSupportingSwitch>

        {Payments()}
        {Dashboard()}
        {Bookings()}
        {Admin()}
        {Developers()}
        {Customers()}
        {Organisation()}
        {Settlement()}
        {Surcharging()}

        <Route exact path={`/user/profile`} component={UserProfileLayout} />
        <Route exact path={`/user/activity/`} component={UserProfileLayout} />
        <Route exact path={`/user/setting/`} component={UserProfileLayout} />

        <Route component={RedirectAs404} />
      </FragmentSupportingSwitch>
    </Suspense>
  );
};
export default Pages;
