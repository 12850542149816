import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import Axios from 'axios';
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import moment from "moment";
import Skeleton from 'react-loading-skeleton';
import {
  Col,
} from "src/components/Component";
import Head from 'src/layout/head/Head';

import API_ROUTES from "src/config/api";

import 'react-loading-skeleton/dist/skeleton.css'

/**
 * Validate the domain
 * @param {*} url
 * @returns boolean
 */
const domainValidation = (url) => {
  const urlRegex = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
  if (urlRegex.test(url)) {
    return true;
  } else {
    return false;
  }
};

const Portal = () => {
  const { organisation } = useSelector((state) => state);

  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDomains = async () => {
      if (loading) return;

      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();

      try {
        const response = await Axios(
          API_ROUTES.user['organisation:portal'](organisation.organisation),
          {
            method: 'get',
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          }
        );

        setDomains(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    fetchDomains();
  }, []);

  /**
   * Validate the domain on type
   * @param {*} domain 
   */
  const validateDomain = (domain) => {
    setNewDomain(domain);

    if (domainValidation(domain)) {
      setError(null);
    } else {
      setError('The domain you have enetered is invalid');
    }
  }

  /**
   * Create the domain
   */
  const createDomain = async () => {
    const user = await Auth.currentAuthenticatedUser();

    setTouched(true);
    setError(null);
    setSubmitting(true);

    if (domainValidation(newDomain)) {
      try {
        await Axios(
          API_ROUTES.user['organisation:portal'](organisation.organisation),
          {
            method: 'put',
            data: {
              domain: newDomain,
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          }
        );

        setSubmitting(false);
        setNewDomain('');
        setDomains([...domains, { domain: newDomain, status: 'Enabled', created_at: new Date(), record: '18.132.54.95' }]);
      } catch (error) {
        setError('There was an error adding the domain');
        setSubmitting(false);
      }
    } else {
      setError('The domain you have enetered is invalid');
      setSubmitting(false);
    }
  }

  const deleteDomain = async (portalID) => {
    const user = await Auth.currentAuthenticatedUser();

    try {
      setDomains(domains.filter((domain) => domain.id !== portalID));

      await Axios(
        API_ROUTES.user['organisation:portal:delete'](organisation.organisation, portalID),
        {
          method: 'delete',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      )
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <React.Fragment>

      <Head title="Portal" />

      <div className="grid grid-cols-4 gap-4">

        <div>
          <Card className="card-stretch card-bordered">

            <CardHeader className="py-3 bg-slate-50 border-b">
              <h5 className="text-primary mb-0 text-xl">
                Add new <strong>Domain</strong>
              </h5>
            </CardHeader>

            <CardBody className="p-2">
              <div className={`sm:flex rounded-lg shadow-sm ${touched && error ? 'border border-red-800' : ''}`}>
                <span className="py-3 px-4 inline-flex items-center min-w-fit w-full border border-gray-200 bg-gray-50 text-sm text-gray-500 -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:w-auto sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg dark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400">https://</span>
                <input
                  type="text"
                  value={newDomain}
                  onChange={(e) => validateDomain(e.target.value)}
                  className="py-3 px-4 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                />
              </div>
              {touched && error && (<p className='text-xs text-red-800 mt-2'>{error}</p>)}
              <div>
                {submitting && (
                  <button className="mt-3 btn btn-secondary float-right">Creating Domain...</button>
                )}
                {!submitting && (
                  <button onClick={() => createDomain()} className="mt-3 btn btn-primary float-right">Add Domain</button>
                )}
              </div>

            </CardBody>

          </Card>

          <Card className="card-stretch card-bordered">

            <CardHeader className="py-3 bg-slate-50 border-b">
              <h5 className="text-primary mb-0 text-xl">
                Setup <strong>Instructions</strong>
              </h5>
            </CardHeader>

            <CardBody className="p-4">
              <ul className="list-outside list-decimal ml-4">
                <li>Add the domain where you want to host the felloh portal in the box above and click add domain, then wait for the portal to be created.</li>
                <li>Once the portal has been created in the domain list, you will need to take the A Record and create a dns record with your registrar for the domain/subdomain with this value.</li>
                <li>Once the record has been added, you wil typically be able to use as soon as the domain has propgated (can take upto half an hour).</li>
              </ul>
            </CardBody>

          </Card>
        </div>

        <div className="col-span-3">
          <Card className="card-stretch card-bordered">

            <CardHeader className="py-3 bg-slate-50 border-b">
              <h5 className="text-primary mb-0 text-xl">
                Portal <strong>Domains</strong>
              </h5>
            </CardHeader>

            <CardBody className="p-4">

              {
                loading === true && (
                  <Col size={12} style={{ width: '100%' }}>
                    <Skeleton count={5} height="50px" className="mb-4" style={{
                      width: '100%',
                    }} />
                  </Col>
                )
              }

              {domains.length === 0 && loading === false && (
                <p className='text-md text-purple-800'>You do not have any domains setup</p>
              )}

              {domains.length > 0 && (
                <table className="table table-striped table-row-bordered table-row-gray-300 align-middle table-row-outer-border">
                  <thead>
                    <tr>
                      <th className='text-left'>Domain</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>A Record</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {domains.map((domain, index) => (
                      <tr key={index}>
                        <td>{domain.domain}</td>
                        <td className='text-center'>{domain.status}</td>
                        <td className='text-center'>{moment(domain.created_at).format('Do MMMM YYYY')}</td>
                        <td className='text-center'>{domain.record}</td>
                        <td className='text-center'>
                          <button className="btn btn-danger" onClick={() => deleteDomain(domain.id)}>Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

            </CardBody>

          </Card>
        </div>

      </div>

    </React.Fragment>
  )
};

export default Portal;
