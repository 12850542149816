import React, {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton'

import {
  BlockTitle, Icon, OutlinedInput
} from "src/components/Component";
import {useDispatch, useSelector} from "react-redux";

import 'react-loading-skeleton/dist/skeleton.css'
import { setOrganisation } from "src/actions/organisationActions";
import {useHistory} from "react-router-dom";

function OrganisationSelect() {
  const [searchText, setSearchText] = useState('');
  const [organisations, setOrganisations] = useState([]);
  const organisation = useSelector((state) => state.organisation);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    const sortedOrganisations = [...organisation.organisations];

    if (!sortedOrganisations) {
      return;
    }

    sortedOrganisations.sort((a, b) => a.name.localeCompare(b.name));

    if (searchText !== "") {
      const filteredObject = sortedOrganisations.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase()) || item.id.toLowerCase().includes(searchText.toLowerCase());
      });
      setOrganisations([...filteredObject]);
    } else {
      setOrganisations(sortedOrganisations);
    }
  }, [searchText, organisation.organisations]);

  const onFilterChange = (e) => setSearchText(e.target.value);

  const selectOrg = (id) => {
    dispatch(setOrganisation(id));

    history.push("/");
  }

  const loadingOrganisations = (
    <React.Fragment>
      <Skeleton count={10} className="p0 mb-4" height="52px" style={{
        width: '100%',
      }}/>
    </React.Fragment>
  );

  const organisationSelect = (
    <React.Fragment>
      { organisation.organisations.length >= 10 && (
        <div className="pl-0 pr-3 mb-2">
          <OutlinedInput
            type="text"
            className="form-control input-group-lg form-focus-none"
            label="Search organisations"
            value={searchText}
            onChange={(e) => onFilterChange(e)}
            id="outlined-icon"
            bsSize="xl"
            icon="search"
          />
        </div>
      )}
      { organisations.map((item) => (
        <div
          className="data-item mt-0 pl-1"
          key={item.id}
          onClick={() => selectOrg(item.id)}
          style={{ cursor: 'pointer' }}
        >
          <div className="data-col">
            <span className="data-label text-primary font-normal"><strong>{ item.name }</strong></span>
            <span className="data-label text-primary">{ item.id }</span>
          </div>
          <div className="data-col data-col-end">
            <Icon name="arrow-right" style={{ fontSize: '2em' }}/>
          </div>
        </div>
      )) }
    </React.Fragment>
  );

  return (
    <div className='w-100'>
      <div  className="text-primary mb-5">
        <BlockTitle tag="h2" className="text-primary text-3xl">Select Organisation</BlockTitle>
      </div>
      {
        organisation.fetching_organisations === true
          ? loadingOrganisations
          : organisationSelect
      }
    </div>
  );
}

export default OrganisationSelect;
