import React, { useState } from 'react';
import { Spinner } from "reactstrap";
import { Button, Icon } from "src/components/Component";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";

const AddBankButton = () => {
  const { organisation } = useSelector((state) => state);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initiatingAdd, setInitiatingAdd] = useState(false);
  const [banks, setBanks] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  const openModal = async () => {
    setSelectedBank(null);
    setInitiatingAdd(false);
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    // Create the payment link
    const data = await Axios(
      API_ROUTES.aisp['bank:list'](),
      {
        method: 'get',
        data: {
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setBanks(data?.data?.data);
    setFilteredList(data?.data?.data);
    setLoading(false);
    setModal(true);
  };

  const addBank = async (bank) => {
    setSelectedBank(bank);
    setInitiatingAdd(true);

    const user = await Auth.currentAuthenticatedUser();

    // Create the payment link
    const data = await Axios(
      API_ROUTES.aisp['bank:add'](),
      {
        method: 'post',
        data: {
          max_historical_days: bank.transaction_total_days,
          institution_id: bank.id,
          organisation: organisation.organisation,
          redirect_url: `${window.location.origin}${window.location.pathname}`,
          institution_name: bank.name,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    window.location.href = data?.data?.data?.url;
  };

  const filterList = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = banks.filter((bank) => bank.name.toLowerCase().includes(value));
    setFilteredList(filtered);
  }

  return (
    <React.Fragment>
      <Button
        disabled={loading}
        outline
        className="mr-1 btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800"
        onClick={() => openModal()
        }>
        <span>{loading ? <Spinner size="sm" color="primary"> </Spinner> : <React.Fragment>
          <Icon name="plus" className="mr-[1px] -mt-[1px] block float-left" />
          <span className='block float-left'>Add Bank Account</span>
        </React.Fragment>}</span>
      </Button>

      <Modal isOpen={modal} toggle={() => {
        if (initiatingAdd === false) setModal(false);
      }} className="modal-dialog-centered" size="lg">
        {
          initiatingAdd === false && (
            <ModalHeader
              className="bg-violet-800 text-xl text-white"
              close={
                initiatingAdd === false &&
                <button className="mt-0.5 text-white" onClick={(ev) => {
                  ev.preventDefault();
                  setModal(false);
                }}>
                  <XMarkIcon className="h-6 w-6" />
                </button>
              }
            >
              {'Select a Bank to connect'}
            </ModalHeader>
          )
        }
        <ModalBody className='p-0'>

          {initiatingAdd === true && (
            <div className="text-center pt-8">
              <div className='clearfix text-center mb-4'>
                <div className='inline-block'>
                  <img src={selectedBank.logo} alt={selectedBank.name} style={{ width: '70px', height: '70px' }} className="animate-pulse" />
                </div>
              </div>
              <p className='mb-4 text-slate-700 bg-violet-100 border-violet-800 border-t border-b p-4 font-bold'>Connecting to {selectedBank.name}.....</p>
            </div>
          )}

          {initiatingAdd === false && (
            <React.Fragment>
              <input
                type="text"
                className="w-full border-0 border-b-2 border-violet-700 py-3 px-4 bg-white mb-3"
                placeholder="Search for a bank"
                onChange={filterList}
              />
              <div className='max-h-[500px] overflow-scroll mx-2'>
                {
                  filteredList.map((bank, index) => {
                    return (
                      <Form key={index}>
                        <div
                          onClick={() => addBank(bank)}
                          className="w-full bg-white text-left border-slate-200 py-2 px-1 border-0 border-b-2 clearfix text-lg cursor-pointer"
                        >
                          <img src={bank.logo} alt={bank.name} className="w-10 h-10 block float-left mr-2" />
                          <div className="block float-left mt-1">{bank.name}</div>
                          <ChevronRightIcon className="h-6 w-6 block float-right mt-2" />
                        </div>
                      </Form>
                    );
                  })
                }
                {
                  filteredList.length === 0 && (
                    <div className="text-center text-slate-500 pt-4 mb-10">
                      No banks found for your search
                    </div>
                  )
                }
              </div>
            </React.Fragment>
          )}

        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddBankButton;
