import React, {useCallback, useEffect, useState} from 'react';

import DataTable from 'src/components/data-table';
import API_ROUTES from 'src/config/api';
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "src/components/pii-obfuscator";
import SuppliersHeld from "./suppliers-held";
import {useSelector} from "react-redux";
import {Auth} from "aws-amplify";
import Axios from "axios";
import StatBox from "src/pages/dashboard/pages/main/components/statistics/stat-box";
import {convertPenniesToDecimals} from "src/utils/money";

const RowFormatter = ({item}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <strong className="date"><PiiObfuscator type="company">{item.name}</PiiObfuscator></strong>
      </td>
      <td className="text-center border-left" style={{width: '12%'}}>
        <span>{item.risk.all.count}</span>
      </td>
      <td className="text-center" style={{width: '12%'}}>
        <span>{CurrencyFormat().format( convertPenniesToDecimals(item.risk.all.sum) )}</span>
      </td>
      <td className="text-center border-left" style={{width: '12%'}}>
        <span>{CurrencyFormat().format( convertPenniesToDecimals(item.risk.month.sum) )}</span>
      </td>
      <td className="text-center" style={{width: '12%'}}>
        <span>{CurrencyFormat().format( convertPenniesToDecimals(item.risk.oneThreeMonth.sum) )}</span>
      </td>
      <td className="text-center" style={{width: '12%'}}>
        <span>{CurrencyFormat().format( convertPenniesToDecimals(item.risk.fourSixMonth.sum) )}</span>
      </td>
      <td className="text-center" style={{width: '12%'}}>
        <span>{CurrencyFormat().format( convertPenniesToDecimals(item.risk.sixMonth.sum) )}</span>
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th rowSpan={2}>
        <strong>Supplier Name</strong>
      </th>
      <th className="text-center border-left" colSpan={2}>
        <span>Bookings (Future Departure)</span>
      </th>
      <th className="text-center border-left" colSpan={4}>
        <span>Departing In</span>
      </th>
    </tr>
    <tr className="tb-tnx-head">
      <th className="text-center border-left">
        <span>Count</span>
      </th>
      <th className="text-center">
        <span>Value</span>
      </th>
      <th className="text-center border-left">
        <span>0 - 30 Days</span>
      </th>
      <th className="text-center">
        <span>31 Days - 90 Days</span>
      </th>
      <th className="text-center">
        <span>91 Days - 180 Days</span>
      </th>
      <th className="text-center">
        <span>181 Days +</span>
      </th>
    </tr>
  </React.Fragment>
);


const TopBlock = () => {
  const { organisation } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [statisticsData, setStatisticsData] = useState({});
  const [chartFilter, setChartFilter] = useState('total');

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.reporting['rolling-reserve'](organisation.organisation),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setStatisticsData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <div className="flex-col mt-1 mb-3">
      <div className="h-full grid grid-cols-3 gap-4">
          <StatBox
            title="Untraveled Bookings"
            figure={statisticsData?.totals?.total_count}
            period="Count of un-travelled booking"
            loading={loading}
          />

          <StatBox
            title="Untraveled Bookings"
            figure={CurrencyFormat().format( convertPenniesToDecimals(statisticsData?.totals?.total_value) )}
            period="Total value of all un-travelled booking"
            loading={loading}
          />

          <StatBox
            title="Untraveled Bookings"
            figure={CurrencyFormat().format( convertPenniesToDecimals(statisticsData?.totals?.total_value / statisticsData?.totals?.total_count) )}
            period="Average value of all un-travelled booking"
            loading={loading}
          />

          <div className="col-span-3">
            <SuppliersHeld data={statisticsData} loading={loading} chartFilter={chartFilter} setChartFilter={setChartFilter} />
          </div>
      </div>
      </div>
  );
};

const Suppliers = ({ data }) => (
  <DataTable
    title="Risk Exposure"
    columnCount={8}
    TopBlock={TopBlock}
    TableHeaders={TableHeaders}
    RowFormatter={RowFormatter}
    endpoint={API_ROUTES.agent['suppliers:get']()}
    searchPlaceholder="Supplier name"
    hideFilters={true}
    noHeader={true}
  />
);

export default Suppliers;

