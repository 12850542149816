import React, { useState, useEffect } from "react";

import {
  Block,
  Col,
} from "src/components/Component";
import {
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';

import Ranger from "../ranger";
import { getInitialWeights, normaliseWeights, calcInitialPoints, getWeight, indexLabel } from "../utils";

const Group = ({ organisation, providers, group, setWeights }) => {
  const [points, setPoints] = useState([]);

  const transactionMethod = group.transactionMethod;

  useEffect(() => {
    const action = () => {
      if (!organisation.organisation || !providers || !providers.length) {
        return;
      }

      const initialWeights = getInitialWeights(providers, transactionMethod);
      const weights = normaliseWeights(initialWeights);
      const initialPoints = calcInitialPoints(weights);

      setPoints(initialPoints);
    }

    action();
  }, [organisation.organisation]);

  useEffect(() => {
    const action = () => {
      const newWeights = {};
      providers.forEach((provider, index) => {
        const paymentProviderId = provider.id;
        const weight = getWeight(points, index);
        const key = `${paymentProviderId}-${transactionMethod}`;
        const item = {
          payment_provider_id: paymentProviderId,
          transaction_method: transactionMethod,
          weight,
        };
        newWeights[key] = item;
      });
      setWeights((prevState) => ({ ...prevState, ...newWeights }));
    }

    action();
  }, [points]);

  return (
    <Col size="6">
      <Card className="card-stretch card-bordered">

        <CardHeader className="py-3 bg-slate-50 border-b">
          <h5 className="text-primary mb-0 text-xl">
            {group.key}
          </h5>
        </CardHeader>

        <CardBody className="p-2">
          {(!providers || (providers.length === 0)) && (
            <Block size="lg">
              No providers configured for this group.
            </Block>
          )}

          {(providers && (providers.length > 0)) && (
            <div className="pt-2">
              <Block size="lg">
                <Ranger points={points} setPoints={setPoints} />
              </Block>

              <Block size="lg pt-0">
                <div className="nk-data data-list data-list-s2">
                  {providers.map((provider, index) => (
                    <div key={provider.id} className="data-item">
                      <div className="data-col">
                        <span className="data-label">({indexLabel(index)}) {provider.name}</span>
                        <span className="data-value">{getWeight(points, index)}%</span>
                      </div>
                    </div>
                  ))}
                </div>
              </Block>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default Group;
