import React  from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import API_ROUTES from 'src/config/api';
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "src/components/pii-obfuscator";
import {convertPenniesToDecimals} from "src/utils/money";

const RowFormatter = ({item}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <strong className="date"><PiiObfuscator type="company">{ item.name }</PiiObfuscator></strong>
      </td>
      <td className="text-center border-left" style={{ width: '12%' }}>
        <span>{item.risk.all.count}</span>
      </td>
      <td className="text-center" style={{ width: '12%' }}>
        <span>{ CurrencyFormat().format( convertPenniesToDecimals(item.risk.all.sum) ) }</span>
      </td>
      <td className="text-center border-left" style={{ width: '12%' }}>
        <span>{ CurrencyFormat().format( convertPenniesToDecimals(item.risk.month.sum) ) }</span>
      </td>
      <td className="text-center" style={{ width: '12%' }}>
        <span>{ CurrencyFormat().format( convertPenniesToDecimals(item.risk.oneThreeMonth.sum) ) }</span>
      </td>
      <td className="text-center" style={{ width: '12%' }}>
        <span>{ CurrencyFormat().format( convertPenniesToDecimals(item.risk.fourSixMonth.sum ) ) }</span>
      </td>
      <td className="text-center" style={{ width: '12%' }}>
        <span>{ CurrencyFormat().format( convertPenniesToDecimals(item.risk.sixMonth.sum ) ) }</span>
      </td>
    </tr>
  );
}

const PageOptions = () => (
  <ul className="nk-block-tools g-3">
    <li>
      <Link to={'/supplier/create'} id="link__create_supplier">
        <Button color="dark" outline className="bg-violet-800 text-white border-none">
          <Icon name="plus" />
          <span>Create Supplier</span>
        </Button>
      </Link>
    </li>
  </ul>
);

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th rowSpan={2}>
        <strong>Supplier Name</strong>
      </th>
      <th className="text-center border-left" colSpan={2}>
        <span>Bookings (Future Departure)</span>
      </th>
      <th className="text-center border-left" colSpan={4}>
        <span>Departing In</span>
      </th>
    </tr>
    <tr className="tb-tnx-head">
      <th className="text-center border-left">
        <span>Count</span>
      </th>
      <th className="text-center">
        <span>Value</span>
      </th>
      <th className="text-center border-left">
        <span>0 - 30 Days</span>
      </th>
      <th className="text-center">
        <span>31 Days - 90 Days</span>
      </th>
      <th className="text-center">
        <span>91 Days - 180 Days</span>
      </th>
      <th className="text-center">
        <span>181 Days +</span>
      </th>
    </tr>
  </React.Fragment>
);

const Suppliers = () => (
  <DataTable
    title="Suppliers"
    columnCount={8}
    TableHeaders={TableHeaders}
    RowFormatter={RowFormatter}
    PageOptions={PageOptions}
    endpoint={API_ROUTES.agent['suppliers:get']()}
    noHeader={true}
    searchPlaceholder="Supplier name"
  />
);

export default Suppliers;
