import React, { useState, useEffect } from 'react';
import { CardBody } from 'reactstrap';
import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { brandLogoTransformer, cardTypeTransformer } from 'src/transformer/transaction-type';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Col, Row,
} from 'src/components/Component';
import StatBox from "src/pages/dashboard/pages/main/components/statistics/stat-box";
import DataTable from 'src/components/data-table';
import API_ROUTES from "src/config/api";
import { Link } from 'react-router-dom';
import moment from "moment";
import CurrencyFormat from 'src/transformer/currency-format';
import { convertPenniesToDecimals } from "src/utils/money";
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import RegionTransformer from 'src/transformer/region';
import LevelTransformer from 'src/transformer/level';

const RowFormatter = ({ item, childFilterState, enums }) => {
  const type = brandLogoTransformer(item.payment_brand);
  const total = CurrencyFormat(enums.currency, item?.transaction?.currency).format(convertPenniesToDecimals(item?.transaction?.amount));
  const charges = CurrencyFormat(enums.currency, item?.charges?.currency).format(convertPenniesToDecimals(item.charges?.amount));
  const cardType = cardTypeTransformer(item?.metadata?.card_type, item?.metadata?.payment_brand, '');

  return (
    <tr
      key={item.id}
      className="tb-tnx-item"
    >
      <td className="text-left">
        <Link to={`/transaction/${item?.transaction?.id}/`} className="text-violet-800 underline">
          {item?.transaction?.id}
        </Link>
      </td>
      <td className="text-center">
        <span className="title">{moment(item?.transaction?.completed_at || item?.transaction?.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className='text-center border-r'>
          {item?.booking?.id && (
            <Link to={`/booking/${item.booking.id}`} className="link__payment_link text-violet-800 underline">{item.booking.booking_reference}</Link>
          )}
          {!item?.booking?.id && <React.Fragment>&mdash;</React.Fragment>}
      </td>
      <td className='text-center'>
        {RegionTransformer(item?.metadata?.country_type)}
      </td>
      <td className='text-center'>
        {type.name}
      </td>
      <td className='text-center'>
        {cardType.label}
      </td>
      <td className='border-r text-center'>
        {LevelTransformer(item?.metadata?.level)}
      </td>
      <td className='text-center'>
        {total}
      </td>
      <td className='text-center'>
        {item?.charges?.rate}%
      </td>
      <td className='text-center'>
        {charges}
      </td>
    </tr>
  );
}

const PageOptions = ({ requestParams, triggerReload, loading }) => {
  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
      </li>
    </ul>
  )
};

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th className='text-left' rowSpan={2}>
        <span>Tranaction ID</span>
      </th>
      <th className="text-center" rowSpan={2}>
        <span>Processing Date</span>
      </th>
      <th className="text-center" rowSpan={2}>
        <span>Booking</span>
      </th>
      <th className="text-center border-l w-[120px]" rowSpan={2}>
        <span>Region</span>
      </th>
      <th className="text-center w-[120px]" rowSpan={2}>
        <span>Brand</span>
      </th>
      <th className="text-center w-[120px]" rowSpan={2}>
        <span>Type</span>
      </th>
      <th className="text-center w-[120px]" rowSpan={2}>
        <span>Level</span>
      </th>
      <th className="text-center border-l" colSpan={3}>
        <span>Transaction</span>
      </th>
    </tr>
    <tr className="tb-tnx-head">
      <th className="text-center w-[120px] border-l">
        <span>Value</span>
      </th>
      <th className="text-center w-[120px]">
        <span>Rate</span>
      </th>
      <th className="text-center w-[120px]">
        <span>Charge</span>
      </th>
    </tr>
  </React.Fragment>
);

const TopBlock = ({ loading }) => {
  return (
    <div className="bg-rose-100 border-t-4 border-rose-500 rounded-b text-teal-900 px-4 py-3 mb-4" role="alert">
      <div className="flex">
        <div className="py-1">
          <ExclamationTriangleIcon className="h-6 w-6 text-rose-500 mr-4 mt-1" aria-hidden="true" />
        </div>
        <div>
          <p className="font-bold">For informational purposes only</p>
          <p className="text-sm">Charges are estimated based on rate information available from your merchant acquirer(s), this can vary and should be used for rough predictions only.</p>
        </div>
      </div>
    </div>
  );
};

const SubBlock = ({ requestParams, enums }) => {
  const [loading, setLoading] = useState(true);
  const [internalParams, setInternalParams] = useState({});
  const [data, setData] = useState(null);
  const [totals, setTotals] = useState([]);

  const getAggregatorCharges = async (params) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['charges:get:aggregates'](),
      {
        method: 'post',
        data: params,
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setData(result.data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (
      requestParams !== null && (
        requestParams.date_from !== internalParams.date_from ||
        requestParams.date_to !== internalParams.date_to ||
        requestParams.organisation !== internalParams.organisation ||
        requestParams['show-child-organisations'] !== internalParams['show-child-organisations']
      )
    ) {
      let params = {
        date_from: requestParams.date_from,
        date_to: requestParams.date_to,
        organisation: requestParams.organisation,
        'show-child-organisations': requestParams['show-child-organisations'],
      };
      setInternalParams(params)

      setLoading(true);
      getAggregatorCharges(params)
      // getAggregatorCharges();
    }
  }, [requestParams]);

  useEffect(() => {
    if (data) {
      let response = {};

      for (const item of data) {
        if (response[item.currency] === undefined) {
          response[item.currency] = {
            count: 0,
            total: 0,
            charges: 0,
          };
        }

        response[item.currency].count += item.count;
        response[item.currency].total += parseInt(item.tx_total);
        response[item.currency].charges += parseInt(item.charges);
      }

      setTotals(response);
    }
  }, [data]);

  const space = 4;

  return (
    <React.Fragment>

      <Row className="mt-2 mb-2">
        <Col md="12" lg="4" xl={space} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>GBP</strong> Transaction Count</span>}
            figure={new Intl.NumberFormat('en-GB').format(totals?.['GBX']?.count || 0)}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={space} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>EUR</strong> Transaction Count</span>}
            figure={new Intl.NumberFormat('en-GB').format(totals?.['EUX']?.count || 0)}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={space} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>USD</strong> Transaction Count</span>}
            figure={new Intl.NumberFormat('en-GB').format(totals?.['USX']?.count || 0)}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={space} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>GBP</strong> Transaction Volume</span>}
            figure={CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(totals?.['GBX']?.total || 0))}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={space} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>EUR</strong> Transaction Volume</span>}
            figure={CurrencyFormat(enums.currency, 'EUX').format(convertPenniesToDecimals(totals?.['EUX']?.total || 0))}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={space} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>USD</strong> Transaction Volume</span>}
            figure={CurrencyFormat(enums.currency, 'USX').format(convertPenniesToDecimals(totals?.['USX']?.total || 0))}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={4} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>GBP</strong> Transaction Charges</span>}
            figure={CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(totals?.['GBX']?.charges || 0))}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={4} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>EUR</strong> Transaction Charges</span>}
            figure={CurrencyFormat(enums.currency, 'EUX').format(convertPenniesToDecimals(totals?.['EUX']?.charges || 0))}
            loading={loading}
          />
        </Col>

        <Col md="6" lg="4" xl={4} className="mb-3">
          <StatBox
            period={<span className='text-violet-800'><strong>USD</strong> Transaction Charges</span>}
            figure={CurrencyFormat(enums.currency, 'USX').format(convertPenniesToDecimals(totals?.['USX']?.charges || 0))}
            loading={loading}
          />
        </Col>
      </Row>

      <h2 className="text-2xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-1 font-normal mt-1 mb-2">Charges by Group</h2>

      <div className="flex-col rounded-md border animate-in fade-in-50 mb-4">

        <CardBody className="p-0">
          <table className="table table-tranx border-light card-bordered mb-0">
            <tbody>
              <tr className="tb-tnx-head">
                <th className="text-center align-middle" rowSpan={2}>
                  <span>Region</span>
                </th>
                <th className="text-center align-middle" rowSpan={2}>
                  <span>Brand</span>
                </th>
                <th className="text-center align-middle" rowSpan={2}>
                  <span>Type</span>
                </th>
                <th className="text-center align-middle border-r" rowSpan={2}>
                  <span>Level</span>
                </th>
                <th className="text-center border-r" colSpan={4}>
                  <span>Transaction</span>
                </th>
              </tr>
              <tr className="tb-tnx-head">

                <th className="text-center">
                  <span>Count</span>
                </th>
                <th className="text-center">
                  <span>Total Value</span>
                </th>
                <th className="text-center">
                  <span>Rate</span>
                </th>
                <th className="text-center">
                  <span>Charges</span>
                </th>
              </tr>
              {loading === true && Array.from(Array(2).keys()).map((key) => (
                <tr className="tb-tnx-item" key={key}>
                  <td colSpan={10}>
                    <Skeleton count={1} height="50px" className="m-0 p-1" />
                  </td>
                </tr>
              ))}
              {loading === false && data.map((item, key) => {
                const type = brandLogoTransformer(item.payment_brand);
                const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.tx_total));
                const charges = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.charges));
                const cardType = cardTypeTransformer(item.card_type, item.payment_brand, '');

                return (
                  <tr className="tb-tnx-item" key={key}>
                    <td className='text-center'>
                      {RegionTransformer(item.country_type)}
                    </td>
                    <td className='text-center'>
                      {type.name}
                    </td>
                    <td className='text-center'>
                      {cardType.label}
                    </td>
                    <td className='text-center border-r'>
                      {LevelTransformer(item.level)}
                    </td>
                    <td className="text-center w-[120px]">
                      {item.count}
                    </td>
                    <td className="text-center w-[120px]">
                      {total}
                    </td>
                    <td className='text-center w-[120px]'>
                      {item.rate}%
                    </td>
                    <td className='text-center w-[120px]'>
                      {charges}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

      </div>
      <h2 className="text-2xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-1 font-normal mb-2">Transaction Charges</h2>
    </React.Fragment>
  );
}

const Bookings = () => {
  const endpoint = API_ROUTES.agent['charges:get']();

  return (
    <DataTable
      title="Acquirer Charges"
      columnCount={10}
      TableHeaders={TableHeaders}
      SubBlock={SubBlock}
      TopBlock={TopBlock}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      dateFilterOption={true}
      defaultDateFilter={{ 
        startDate: moment().subtract(1, "months").startOf("months").toDate().toISOString().split('T')[0],
        endDate: new Date(new Date().setDate(0)).toISOString().split('T')[0],
      }}
      dateFilterLabel="Transaction Date"
      endpoint={endpoint}
      childOrganisationsOption={true}
      noHeader={true}
    />
  );
};

export default Bookings;
