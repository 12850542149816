import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Icon } from '../../../../../components/Component';
import Axios from 'axios';
import API_ROUTES from '../../../../../config/api';
import {Auth} from "aws-amplify";
import {Link} from "react-router-dom";

const Options = ({ item, triggerReload }) => {
  const [modalForm, setModalForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const toggleForm = () => setModalForm(!modalForm);

  /**
   * Delete an API token
   */
  const deleteToken = async() => {
    const user = await Auth.currentAuthenticatedUser();
    setDisabled(true);

    await Axios(
      API_ROUTES.token['key:delete'](item.id),
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalForm(false);
    triggerReload();
  };

  return (
    <React.Fragment>
      <DropdownMenu end>
        <ul className="link-list-plain">
          <li>
            <Link to={`/users/${item.user.id}/roles/`}>Manage Permissions</Link>
          </li>
          <li>
            <DropdownItem
              tag="a"
              href="#view"
              onClick={(ev) => {
                ev.preventDefault();
                toggleForm();
              }}
            >
              Delete Token
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>

      <Modal isOpen={modalForm} toggle={toggleForm} >
        <ModalHeader
          toggle={toggleForm}
          className="bg-red-500 text-white text-lg"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Delete API Token
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you would like to delete the <strong>"{ item.name }"</strong> API key?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="bg-red-500 text-white hover:bg-red-700" type="submit" disabled={disabled} onClick={
              (ev) => {
                ev.preventDefault();
                deleteToken(item);
              }
            } size="lg">
              Continue
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default Options;
