import React, {useCallback, useEffect, useState} from 'react';
import { Route, Switch, Link, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';

import Content from 'src/layout/content/Content';

import OrganisationInformation from './information';
import Payouts from './payouts';
import Features from './features';
import Theming from './theming';

import { API_ROUTES } from 'src/config/api';
import {Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle, Icon, Button} from 'src/components/Component';
import {Auth} from 'aws-amplify';
import Axios from 'axios';

import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import PiiObfuscator from "src/components/pii-obfuscator";

const UserProfileLayout = () => {
  const [organisation, setOrganisation] = useState({});
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const getOrganisation = useCallback(async (showLoading = false) => {
    if (showLoading === true) {
      setLoading(true);
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.user['organisation:get'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setLoading(false);
    setOrganisation(result.data.data);
  }, [id]);

  useEffect(() => {
    getOrganisation();
  }, [getOrganisation]);

  return (
      <React.Fragment>
        <Content>

          <BlockHead size="sm" className="border-b border-violet-800 pt-4 mb-4">
            <BlockBetween>
              <BlockHeadContent>
                <span style={{ display: loading === true ? 'block' : 'none' }}>
                  <Skeleton count={1} height="32px" className="p0 m0" style={{
                    width: '450px',
                    maxWidth: '100%',
                  }}/>
                </span>
                <span style={{ display: loading === false ? 'block' : 'none' }}>
                   <BlockTitle className="text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text">
                     <strong><PiiObfuscator type="company">{organisation.name}</PiiObfuscator></strong> | {organisation.id}
                  </BlockTitle>
                </span>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3 pr-1">
                      <Link to={'/organisations'}>
                        <Button color="dark" outline className="bg-violet-800 text-white border-none" id="button__back">
                          <Icon name="back-ios" />
                          <span>Back</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li
                    className={`nav-item ${
                        window.location.pathname === `/admin/organisation/${id}`
                            ? "active current-page"
                            : ""
                    } `}
                >
                  <Link
                      to={`/admin/organisation/${id}`}
                      className={`nav-link
                    ${window.location.pathname === `/admin/organisation/${id}` ? "active" : ""}
                  `}
                  >
                    <Icon name="building" />
                    <span>Organisation</span>
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    window.location.pathname === `/admin/organisation/${id}/payouts` ? "active" : ""
                  }`}
                >
                  <Link
                    to={`/admin/organisation/${id}/payouts`}
                    className={`nav-link ${
                      window.location.pathname === `/admin/organisation/${id}/payouts` ? "active" : ""
                    }`}
                  >
                    <Icon name="layers" />
                    <span>Payouts</span>
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    window.location.pathname === `/admin/organisation/${id}/features` ? "active" : ""
                  }`}
                >
                  <Link
                    to={`/admin/organisation/${id}/features`}
                    className={`nav-link ${
                      window.location.pathname === `/admin/organisation/${id}/features` ? "active" : ""
                    }`}
                  >
                    <Icon name="layout-alt" />
                    <span>Features</span>
                  </Link>
                </li>

                <li
                    className={`nav-item ${
                        window.location.pathname === `/admin/organisation/${id}/theming` ? "active" : ""
                    }`}
                >
                  <Link
                      to={`/admin/organisation/${id}/theming`}
                      className={`nav-link ${
                          window.location.pathname === `/admin/organisation/${id}/theming` ? "active" : ""
                      }`}
                  >
                    <Icon name="puzzle" />
                    <span>Theming</span>
                  </Link>
                </li>
              </ul>

                <Switch>
                  <Route
                      exact
                      path={`/admin/organisation/:id/`}
                      render={() => <OrganisationInformation id={id} organisation={organisation} parentLoading={loading} getOrganisation={getOrganisation} />}
                  />
                  <Route
                    exact
                    path={`/admin/organisation/:id/payouts`}
                    render={() => <Payouts id={id} parentLoading={loading} />}
                  />
                  <Route
                    exact
                    path={`/admin/organisation/:id/features`}
                    render={() => <Features id={id} />}
                  />
                  <Route
                      exact
                      path={`/admin/organisation/:id/theming`}
                      render={() => <Theming organisation={organisation} parentLoading={loading} />}
                  />
                </Switch>

            </Card>
          </Block>
        </Content>
      </React.Fragment>
  );
};

export default UserProfileLayout;
