import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Spinner } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import { API_ROUTES } from 'src/config/api';
import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
  Block,
  Row,
  Col,
} from "src/components/Component";
import { Card, CardHeader, CardBody } from 'reactstrap';

import Group from "./group";
import { buildProviderGroups, providersInGroup } from "./utils";

const OrganisationPaymentConfigWeights = () => {
  const [providers, setProviders] = useState();
  const [weights, setWeights] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organisation } = useSelector((state) => state);

  useEffect(() => {
    const action = async () => {
      if (!organisation.organisation) {
        return;
      }

      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['organisation:providers'](organisation.organisation),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setProviders(data);
    }

    action();
  }, [organisation.id]);

  const history = useHistory();

  const processForm = async () => {
    setIsSubmitting(true);

    const data = {
      weights: Object.values(weights),
    };

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.payment['organisation:providers:set-weights'](organisation.organisation),
      {
        method: 'put',
        data,
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    history.push(`/organisation/integrations`);
  };

  if (!providers) {
    return (
      [0, 1, 2, 3, 4, 5].map(() => (
        <Col size="6" className="float-left mb-4">
          <Card className="card-stretch card-bordered">
            <CardHeader className="py-3 bg-slate-50 border-b">
              <div className="h-100 w-full p-1" />
            </CardHeader>
            <CardBody className="p-2">
              <div className="p-2">
                <Skeleton count={1} height="100px" style={{ width: '100%' }} />
              </div>
            </CardBody>
          </Card>
        </Col>
      ))
    );
  }

  return (
    <>
      <Head title="Set Payment Provider Weighting" />
      <Content>

        <Row className="g-gs">
          {buildProviderGroups(providers).map((group) => (
            <Group
              key={group.key}
              organisation={organisation}
              providers={providersInGroup(providers, group)}
              group={group}
              setWeights={setWeights}
            />
          ))}
        </Row>

        <Block size="lg mt-4">
          <button
            onClick={(event) => { event.preventDefault(); processForm(); }}
            type="submit"
            className="float-right btn border-violet-800 bg-white text-violet-800 text-md hover:bg-slate-800 hover:border-slate-800"
          >
            {isSubmitting ? <Spinner size="sm" color="dark" className="text-violet-800"> </Spinner> : "Save Weighting(s)"}
          </button>
        </Block>
      </Content>
    </>
  );
};
export default OrganisationPaymentConfigWeights;
