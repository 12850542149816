import React, {useState} from 'react';
import Skeleton from "react-loading-skeleton";
import {Card, CardHeader, CardBody, Spinner} from "reactstrap";
import moment from "moment";
import { Button } from "src/components/Component";
import {useSelector} from "react-redux";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../../../../../config/api";

const BankConnections = ({ data, loading }) => {
  const { organisation, account } = useSelector((state) => state);
  const [reSyncInProgress, setReSyncInProgress] = useState(false);

  const reSyncAccount = async (id) => {
    setReSyncInProgress(true);

    const user = await Auth.currentAuthenticatedUser();

    const data = await Axios(
      API_ROUTES.saltedge['bank:resync'](),
      {
        method: 'post',
        data: {
          organisation: organisation.organisation,
          connection_id: id,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    window.location.href = data.data.data.redirect_url;
  }

  return (
    <React.Fragment>
      <Card className="card-stretch">
        <CardHeader className="py-3 card-bordered bg-slate-50 border-bottom-0">
          <h5 className="text-primary mb-0 text-lg">
            Bank Connections
          </h5>
        </CardHeader>

        <CardBody className="p-0">
          <table className="table table-tranx border-light card-bordered">
            <tbody>
            <tr className="tb-tnx-head">
              <th className='text-left'><span>Connection Name</span></th>
              <th className="border-left text-center"><span>Owner</span></th>
              <th className="border-left text-center"><span>Created On</span></th>
              <th className="border-left text-center"><span>Last Synchronisation</span></th>
              <th className="border-left text-center" style={{ width: '130px' }}><span>Actions</span></th>
            </tr>
            { loading && Array.from(Array(2).keys()).map((key) => (
              <tr className="tb-tnx-item" key={key}>
                <td colSpan={7}>
                  <Skeleton count={1} height="50px" className="m-0 p-1" />
                </td>
              </tr>
            ))
            }
            { loading === false && data?.connections?.items.length === 0 && (
              <tr className="tb-tnx-item">
                <td colSpan={7} className="text-center">
                  <strong>No connections found</strong>
                </td>
              </tr>
            )}
            { loading === false && data?.connections?.items.map((connection, key) => {
              return (
                <tr className="tb-tnx-item" key={key}>
                  <td className="border-left">{ connection.provider_name }</td>
                  <td className="border-left text-center">{ connection.user_name }</td>
                  <td className="border-left text-center">{ moment(connection.created_at).format('Do MMMM YYYY')  }</td>
                  <td className="border-left text-center">{ connection.last_success_at ? moment(connection.last_success_at).format('Do MMMM YYYY') : '-'  }</td>
                  <td className="text-center border-left pl-1 pr-1" style={{ minWidth: '130px' }}>
                    {
                      account.id === connection.user_id && (
                        <Button
                          size="sm"
                          className="btn-block"
                          type="submit"
                          color="primary"
                          onClick={() => reSyncAccount(connection.id)}
                          disabled={reSyncInProgress}
                          style={{ width: '130px' }}
                        >
                          <span>{reSyncInProgress ? <Spinner size="sm" color="light"> </Spinner> : "Reconnect"}</span>
                        </Button>
                      )
                    }
                    {
                      account.id !== connection.user_id && (
                        <React.Fragment>-</React.Fragment>
                      )
                    }
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </CardBody>
      </Card>

    </React.Fragment>
  )
}

export default BankConnections;
