import React, { useState, useEffect, useCallback } from "react";
import Head from "../../../../layout/head/Head";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import Axios from "axios"; 
import { API_ROUTES, FRONTEND_ROUTES } from "src/config/api";
import { Auth } from 'aws-amplify';
import { CircleStackIcon } from '@heroicons/react/24/outline';

import 'react-loading-skeleton/dist/skeleton.css'

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  DropdownItem,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Icon,
} from "src/components/Component";
import moment from "moment";

const Organisations = () => {
  const [loading, setLoading] = useState(false);
  const [organisationsRaw, setOrganisationsRaw] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [onSearchText, setSearchText] = useState("");

  const [withoutImageFilter, setWithoutImageFilter] = useState(false)

  const getSortedOrganisations = useCallback(() => {
    let orgs = [...organisationsRaw];

    orgs.sort((a, b) => a.name.localeCompare(b.name));

    return orgs;
  }, [organisationsRaw]);


  useEffect(() => {
    const getOrganisations = async () => {
      setLoading(true);

      const user = await Auth.currentAuthenticatedUser();
  
      const result = await Axios(
        API_ROUTES.user['organisations:get'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
  
      setOrganisationsRaw(result.data.data);
      setLoading(false);
    };

    getOrganisations();
  }, []);

  useEffect(() => {
    setOrganisations(getSortedOrganisations());
  }, [organisationsRaw]);

  // Changing state value when searching name
  useEffect(() => {
    const sortedOrganisations = getSortedOrganisations();

    let response = null;

    if (onSearchText !== "") {

      const filteredObject = sortedOrganisations.filter((item) => {
        return item.name.toLowerCase().includes(onSearchText.toLowerCase()) || item.id.toLowerCase().includes(onSearchText.toLowerCase());
      });
      response = [...filteredObject];
    } else {
      response = [...sortedOrganisations];
    }

    if (withoutImageFilter === true) {
      const newResponse = [];

      for (const item of response) {
        if (item.image === null) {
          newResponse.push(item);
        }
      }

      response = newResponse;
    }

    setOrganisations(response);
  }, [withoutImageFilter, onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <React.Fragment>
      <Head title="Organisation Management" />
      <div className="h-full col-span-6 rounded-md border border-slate-300 animate-in fade-in-50">
        <BlockHead size="sm" className="absolute top-0 right-0">
          <BlockBetween>
            <BlockHeadContent>
              <Link to="/admin/organisations/create">
                <Button outline className="btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800 mt-2">
                  <span>Create Organisation</span>
                  <Icon name="plus" />
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-stretch">
            <div className="card-inner-group">
              <div className="bg-gray-300 b-0 px-4 py-2">
                <div className="card-title-group">
                  <div className={`active w-50 border border-slate-400 rounded px-2 bg-white`}>
                    <input
                      className="form-control border-transparent form-focus-none pl-0"
                      type="text"
                      placeholder="Search by organisation name"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon mr-2">
                      <Icon name="search" />
                    </Button>
                  </div>
                  <div className="card-tools mr-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                            <button
                              id="hs-as-table-table-filter-dropdown"
                              type="button"
                              className="btn btn-outline-light bg-white mr-2 border border-slate-300 px-3"
                            >
                              <svg
                                className="flex-shrink-0 w-3.5 h-3.5 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M3 6h18" />
                                <path d="M7 12h10" />
                                <path d="M10 18h4" />
                              </svg>
                              Filter
                            </button>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-check">
                              <li>
                                <span>Image</span>
                              </li>
                              <li className={withoutImageFilter === false ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setWithoutImageFilter(false);
                                  }}
                                >
                                  Show All
                                </DropdownItem>
                              </li>
                              <li className={withoutImageFilter === true ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setWithoutImageFilter(true);
                                  }}
                                >
                                  Has No Image
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>


              <div className="card-inner p-0">

                <div style={{ display: loading === false ? 'block' : 'none' }}>
                  <table className="table table-tranx">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-id text-left">
                          <span className="">Organisation</span>
                        </th>
                        <th className="text-left">
                          <span>Parent Account</span>
                        </th>
                        <th>
                          <span>Member Since</span>
                        </th>
                        <th className="text-center border-left border-right">
                          <span>Image</span>
                        </th>
                        <th className="tb-tnx-action">
                          <span>&nbsp;</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { loading === false
                        ? organisations.map((item) => {
                          return (
                            <tr key={item.id} className="tb-tnx-item">
                              <td className="tb-tnx-id">
                                <Link to={`/organisations/${item.id}`}>
                                  <strong>{item.name}</strong>
                                </Link>
                                <span className="title">{item.id}</span>
                              </td>
                              <td>
                                <span className="title">
                                  {item.parent ? <Link to={`/organisations/${item.parent.id}`}>{item.parent.name}</Link> : 'NONE'}
                                </span>
                              </td>
                              <td className="text-center">
                                <span>{moment(item.created_at).format('Do MMMM YYYY')}</span>
                              </td>
                              <td className="border-left border-right text-center">
                                {!item.image && (
                                  <span><Icon name={`ni text-primary icon ni-cross pr-1`} /></span>
                                )}
                                {item.image && (
                                  <img className="m-auto" style={{ maxWidth: '100px', maxHeight: '50px' }} src={`${FRONTEND_ROUTES.assets.base()}${item.image}`} alt="" />
                                )}
                              </td>
                              <td className="tb-tnx-action pl-3">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="menu" />
                                  </DropdownToggle>
                                  <DropdownMenu end style={{ width: '200px' }}>
                                    <ul className="link-list-plain">
                                      <li>
                                        <Link to={`/admin/organisation/${item.id}`}>
                                          <Icon name="building" />Information
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`/admin/organisation/${item.id}/payouts`}>
                                          <Icon name="layers" />Payout Configuration
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`/admin/organisation/${item.id}/features`}>
                                          <Icon name="layout-alt" />Features
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`/admin/organisation/${item.id}/theming`}>
                                          <Icon name="puzzle" />Theming
                                        </Link>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                </div>

                <div className="text-center pl-4 pr-4 pt-3 pb-3" style={{ display: loading === true ? 'block' : 'none' }}>
                  <Skeleton count={10} height="50px" className="mb-2" />
                </div>

              </div>
            </div>
          </Card>
        </Block>
      </div>
    </React.Fragment>
  );
};

export default Organisations;
