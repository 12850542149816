import React, { useCallback, useEffect, useState } from 'react';
import Head from 'src/layout/head/Head';
import Content from 'src/layout/content/Content';
import moment from 'moment';

import {
  Block,
  Button,
  Row,
  Col,
} from "src/components/Component";
import BankAccounts from "./components/accounts";
import Connections from "./components/connections";
import StatBox from "../../../dashboard/pages/main/components/statistics/stat-box";
import AddBankButton from "./components/add-bank-button";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../../../config/api";
import { useHistory } from "react-router-dom";
import { ArrowsUpDownIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const Dashboard = () => {
  const { organisation } = useSelector((state) => state);

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const [loading, setLoading] = useState(true);
  const [newLoading, setNewLoading] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);

  const [statistics, setStatistics] = useState({});

  const triggerAccountFetch = useCallback(async (acountReference) => {
    setLoading(true);
    setNewLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.aisp['requisition:update'](acountReference),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    getData();
  });

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.aisp['statistics:get'](),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        params: {
          organisation: organisation.organisation
        }
      }
    );

    setStatistics(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    if (organisation.fetching_organisations === false) {
      const ref = urlParams.get('ref');

      if (ref) {
        triggerAccountFetch(ref);
      } else {
        getData();
      }
    }
  }, [organisation, reloadCount]);

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        {newLoading === true && (
          <div className="bg-violet-200 border-t-4 border-purple-900 rounded-b text-teal-900 px-4 py-3 mb-4" role="alert">
            <div className="flex">
              <div className="py-1">
                <ArrowsUpDownIcon className="h-6 w-6 text-purple-900 mr-4 mt-1" aria-hidden="true" />
              </div>
              <div>
                <p className="font-bold">Account synchronisation in progress</p>
                <p className="text-sm">Congratulations, your account(s) have been connected. We are in the progress of synchronizing your account, this can take a couple of hours to complete.</p>
              </div>
            </div>
          </div>
        )}
        <div className='clearfix'>
          <div className="toggle-wrap nk-block-tools-toggle float-right">
            <div className="toggle-expand-content">
              <ul className="nk-block-tools g-3">
                <AddBankButton />
                <li>
                  <Button 
                    disabled={loading}
                    className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800"
                    id="trigger_reload"
                    onClick={() => setReloadCount(reloadCount + 1)}>
                    <ArrowPathIcon className='h-4 w-5' />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Block className="mt-4">
          <Row className="g-gs">
            <Col size="4">
              <StatBox
                title={<React.Fragment>Active <span className="fw-normal">Connections</span></React.Fragment>}
                figure={statistics?.connections?.count || 0}
                loading={loading}
                popoverTitle="Active Accounts"
                popoverDescription="Account that you have activated and are synchronizing with Felloh"
              />
            </Col>
            <Col size="4">
              <StatBox
                title={<React.Fragment>Active <span className="fw-normal">Accounts</span></React.Fragment>}
                figure={statistics?.accounts?.count || 0}
                loading={loading}
                popoverTitle="Expired Accounts"
                popoverDescription="Accounts that have expired and are no longer synchronizing with Felloh"
              />
            </Col>
            <Col size="4">
              <StatBox
                title={<React.Fragment>Last <span className="fw-normal">Synchronisation</span></React.Fragment>}
                figure={statistics?.accounts?.last_sync ? moment(statistics?.accounts?.last_sync).fromNow() : '-'}
                loading={loading}
                popoverTitle="Last Synchronisation"
                popoverDescription="When the last time your accounts were synchronised with Felloh"
              />
            </Col>
          </Row>
        </Block>
        <Block>
          <Row className="g-gs">
            <Col size="12">
              <Connections data={statistics} loading={loading} />
              <BankAccounts data={statistics} loading={loading} triggerReload={() => { }} />
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;
