import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import Payments from './pages/payments';
import PaymentLinks from './pages/payment-links';
import Ecommerce from './pages/ecommerce';
import EcommerceAssign from './pages/ecommerce/assign';
import Refund from './pages/refund';
import Refunds from './pages/refunds';
import Chargebacks from "./pages/chargebacks";
import Payment from './pages/payment';
import CreatePaymentLink from './pages/payment-link/create';
import PaymentLink from './pages/payment-link/link';
import PaymentLinkAssign from './pages/payment-link/assign';
import PaymentLinkReAssign from './pages/payment-link/re-assign';
import BankDeposits from "./pages/bank-deposits";

import RedirectRenderer from "src/components/redirect-renderer";
import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";
import GroupMenu from "src/components/group-menu";

import { ArrowUturnUpIcon, BanknotesIcon, LinkIcon, ReceiptRefundIcon, WindowIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Bank Deposits', icon: BanknotesIcon, path: '/payments/bank-deposits', check: HasRole(account, ['open-banking:view']) && HasFeature(features, ['aisp']) },
    { name: 'Chargebacks', icon: ArrowUturnUpIcon, path: '/payments/chargebacks', check:HasRole(account, ['chargebacks:view']) && HasFeature(features, ['chargebacks'])  },
    { name: 'Ecommerce Sessions', icon: WindowIcon, path: '/payments/ecommerce', check:HasFeature(features, ['ecommerce']) && HasRole(account, ['booking-and-payment:organisation-access']) },
    { name: 'Payments', icon: BanknotesIcon, path: '/payments', check: true },
    { name: 'Payment Links', icon: LinkIcon, path: '/payments/payment-links', check: true },
    { name: 'Refunds', icon: ReceiptRefundIcon, path: '/payments/refunds', check:HasRole(account, ['refund:approve']) && HasFeature(features, ['refunds']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Payments" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const redirects = [
  { from: '/batch', to: '/payments/batch' },
  { from: '/ecommerce', to: '/payments/ecommerce' },
  { from: '/transactions', to: '/payments' },
  { from: '/payment-links', to: '/payments/payment-links' },
  { from: '/refunds', to: '/payments/refunds' },
  { from: '/refunds/:id', to: '/payments/refund/:id' },
  { from: '/chargebacks', to: '/payments/chargebacks' },
  { from: '/transaction/:id', to: '/payment/:id' },
  { from: '/transaction/:id/payout', to: '/payment/:id/payout' },
  { from: '/transaction/:id/settlement', to: '/payment/:id/settlement' },
  { from: '/transaction/:id/bank', to: '/payment/:id/bank' },
];

const PaymentsRouter = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        { RedirectRenderer({ redirects }) }

        <Route path="/payments" exact component={() => <Layout><Payments /></Layout>} />
        <Route path="/payments/payment-links" component={() => <Layout><PaymentLinks /></Layout>} />

        { /* ECOMMERCE **/}
        {HasFeature(features, ['ecommerce']) && HasRole(account, ['booking-and-payment:organisation-access']) && (
          <React.Fragment>
            <Route exact path={`/payments/ecommerce`} component={() => <Layout><Ecommerce /></Layout>} />
            <Route exact path={`/ecommerce/:id/assign`} component={() => <Layout><EcommerceAssign /></Layout>} />
          </React.Fragment>
        )}

        { /* BANK DEPOSITS **/}
        {HasRole(account, ['open-banking:view']) && HasFeature(features, ['aisp']) && (
          <Route exact path={`/payments/bank-deposits`} component={() => <Layout><BankDeposits /></Layout>} />
        )}

        { /* BATCHES **/}
        {HasRole(account, ['payouts:view']) && (
          <Route exact path={`/payments/batch`} component={() => (
            <div className="px-2 py-4">
              <Payments />
            </div>
          )} />
        )}

        { /* CHARGEBACKS **/}
        { HasRole(account, ['chargebacks:view']) && HasFeature(features,['chargebacks']) && (
          <Route exact path={`/payments/chargebacks`} component={() => <Layout><Chargebacks /></Layout>} />
        )}

        { /* REFUNDS **/}
        {HasRole(account, ['refund:approve']) && HasFeature(features, ['refunds']) && (
          <React.Fragment>
            <Route exact path={`/payments/refunds`} component={() => <Layout><Refunds /></Layout>} />
            <Route exact path={`/payments/refund/:id`} component={() => <Layout><Refund /></Layout>} />
          </React.Fragment>
        )}
        
        { /* PAYMENT **/}
        { HasFeature(features, ['booking-and-payment']) && (
          <React.Fragment>
            <Route exact path={`/payment/:id`} component={Payment} />
            <Route exact path={`/payment/:id/payout`} component={Payment} />
            <Route exact path={`/payment/:id/settlement`} component={Payment} />
            <Route exact path={`/payment/:id/bank`} component={Payment} />
            { HasRole(account, ['booking-and-payment:create:payment']) && (
              <Route exact path={`/payment-link/create`} component={CreatePaymentLink} />
            )}

            <Route exact path={`/payment-links/:id`} component={PaymentLink} />
            <Route exact path={`/payment-links/:id/assign`} component={PaymentLinkAssign} />
            <Route exact path={`/payment-links/:id/re-assign`} component={PaymentLinkReAssign} />
          </React.Fragment>
        )}

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default PaymentsRouter;
