import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

import Admin from "./pages/setup";
import Overview from "./pages/overview";

import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";
import GroupMenu from "src/components/group-menu";

import { ArrowDownOnSquareStackIcon, CogIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Surcharging Overview', icon: ArrowDownOnSquareStackIcon, path: '/surcharging/overview', check: HasRole(account, ['booking-and-payment:organisation-access']) },
    { name: 'Surcharging Configuration', icon: CogIcon, path: '/surcharging/admin', check: HasRole(account, ['booking-and-payment:organisation-access']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Surcharging" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const BasePathRenderer = () => {
  const { account: { account } } = useSelector((state) => state);

  let path = '/';

  if (HasRole(account, ['booking-and-payment:organisation-access'])) {
    path = `/surcharging/overview`;
  }

  return (
    <Route exact path="/surcharging" component={(props) => {
      return (
        <Redirect to={path} />
      )
    }}
    />
  );
};

const SettlementRouter = () => {
  const { account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        {BasePathRenderer()}

        {HasRole(account, ['booking-and-payment:organisation-access']) && (
          <Route exact path={`/surcharging/overview`} component={() => <Layout><Overview /></Layout>} />
        )}

        {HasRole(account, ['booking-and-payment:organisation-access']) && (
          <Route exact path={`/surcharging/admin`} component={() => <Layout><Admin /></Layout>} />
        )}

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default SettlementRouter;
