import React, { useState } from 'react';
import { DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { Icon } from 'src/components/Component';
import { LinkList, LinkItem } from 'src/components/links/Links';
import { logout } from 'src/actions/accountActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const User = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.account);

  const handleSignout = async (e) => {
    e.preventDefault();
    await dispatch(logout());

    setTimeout(() =>{
      history.push("/");
    }, 1500);
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        id="button_user_toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <Icon style={{ fontSize: '32px', color: '#6b21a8', paddingTop: '1px', marginTop: '-4px' }} name="user-circle" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>{ account.account.first_name.charAt(0) }{ account.account.last_name.charAt(0) }</span>
            </div>
            <div className="user-info">
              <span className="lead-text">{ account.account.first_name } { account.account.last_name }</span>
              <span className="sub-text">{ account.user.attributes.email }</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/user/profile" icon="user-alt" onClick={toggle}>
              Profile
            </LinkItem>
            <LinkItem link="/user/setting" icon="setting-alt" onClick={toggle}>
              Account Settings
            </LinkItem>
            <LinkItem link="/user/activity" icon="activity-alt" onClick={toggle}>
              User Activity
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href={`/`} onClick={handleSignout} id="sign-out-button">
              <Icon name="signout" />
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;

