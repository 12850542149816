import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { API_ROUTES } from 'src/config/api';
import Head from "src/layout/head/Head";
import { Card, CardHeader, CardBody, Button as ButtonShape } from "reactstrap";

import { buildFormData } from "../components/utils";
import Form from "../components/form";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { PROVIDER_CONFIGURATION } from 'src/config/providers';

const OrganisationPaymentConfigCreate = () => {
  const { id } = useParams();

  const [transactionMethods, setTransactionMethods] = useState();
  const [beneficaries, setBeneficaries] = useState();

  const { organisation } = useSelector((state) => state);

  const { enums } = useSelector((state) => state.enums);
  const currencies = Object.values(enums.currency).map((item) => ({ id: item.minor_unit }));

  const history = useHistory();

  const initialValues = {
    name: '',
    paymentProvider: id,
    paymentProviderName: '',
    transactionMethods: [],
    currencies: [],
    publicKey: '',
    secretKey: '',
    googlepay_enabled: false,
    applepay_enabled: false,
    beneficiary:  { id: '0', label: 'FELLOH MANAGED TRUST' },
  }

  useEffect(() => {
    const fetchBeneficaries = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.ledger['beneficiaries:get'](),
        {
          method: 'post',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          data: { organisation: organisation.organisation },
        }
      );

      const data = result.data.data;
      setBeneficaries(data);
    };

    const fetchTransactionMethods = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['transaction-methods:list'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setTransactionMethods(data);
    };

    fetchTransactionMethods();
    fetchBeneficaries();
  }, [organisation.organisation]);

  const processForm = async (values) => {
    const user = await Auth.currentAuthenticatedUser();

    const data = buildFormData(values);
    await Axios(
      API_ROUTES.payment['organisation:provider:create'](organisation.organisation),
      {
        method: 'post',
        data,
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );
    history.push(PROVIDER_CONFIGURATION[id].manage_path);
  };

  return (
    <React.Fragment>
      <Head title="New Payment Provider" />

      <Card className="card-stretch card-bordered">

        <CardHeader className="py-3 bg-slate-50 border-b">
          <div className='float-right'>
            <ul className="nk-block-tools g-3">
              <li>
                <Link to={PROVIDER_CONFIGURATION[id].manage_path}>
                  <ButtonShape color="light" className="btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800">
                    <ArrowLeftIcon className="h-4 w-4 -mt-0.5 mr-1" />
                    <span>Back</span>
                  </ButtonShape>
                </Link>
              </li>
            </ul>
          </div>
          <h5 className="text-primary mb-0 text-xl">
            New <strong>{PROVIDER_CONFIGURATION[id].name} Configuration</strong>
          </h5>
        </CardHeader>

        <CardBody>
          { (!transactionMethods || !currencies) && (
            <Skeleton count={3} height="150px" className="mb-3" style={{ width: '100%' }} />
          )}

          { transactionMethods && currencies && (
            <Form
              transactionMethods={transactionMethods}
              currencies={currencies}
              initialValues={initialValues}
              buttonText="Create Payment Provider"
              processForm={processForm}
              beneficaries={beneficaries}
            />
          )}

        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default OrganisationPaymentConfigCreate;
