import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Button,
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import moment from "moment";
import API_ROUTES from "src/config/api";
import TransactionMethodTransformer from "src/transformer/transaction-method";
import TransactionTypeTransformer from "src/transformer/transaction-type";
import TransactionStatusTransformer from "src/transformer/transaction-status";
import CurrencyFormat from 'src/transformer/currency-format';
import DisbursementInformation from "./disbursement-information";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Spinner } from "reactstrap";
import PiiObfuscator from "src/components/pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import ParseName from 'parse-full-name';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const customerNameFormatter = (item) => {

  if (item.payment_link && item.payment_link?.id) {
    return (
      <React.Fragment>
        {
          item?.customer?.id ? (
            <strong>
              <Link to={`/customer/${item.customer.id}`} className="text-violet-800 underline">
                {ParseName.parseFullName(item.payment_link.customer_name).first} {ParseName.parseFullName(item.payment_link.customer_name).last}
              </Link>
            </strong>
          ) : (
            <strong>{ParseName.parseFullName(item.payment_link.customer_name).first} {ParseName.parseFullName(item.payment_link.customer_name).last}</strong>
          )
        }
        <br />
        {item.payment_link.email.toLowerCase()}
      </React.Fragment>
    );
  }

  if (item.ecommerce && item.ecommerce?.id) {
    return (
      <React.Fragment>
        {
          item?.customer?.id ? (
            <strong>
              <Link to={`/customer/${item.customer.id}`} className="text-violet-800 underline">
                {ParseName.parseFullName(item.ecommerce.customer_name).first} {ParseName.parseFullName(item.ecommerce.customer_name).last}
              </Link>
            </strong>
          ) : (
            <strong>{ParseName.parseFullName(item.ecommerce.customer_name).first} {ParseName.parseFullName(item.ecommerce.customer_name).last}</strong>
          )
        }
        <br />
        {item.ecommerce.email.toLowerCase()}
      </React.Fragment>
    );
  }

  if (typeof item.booking !== 'undefined' && item.booking?.customer_name) {
    return (
      <React.Fragment>
      {
          item?.customer?.id ? (
            <strong>
              <Link to={`/customer/${item.customer.id}`} className="text-violet-800 underline">
                {ParseName.parseFullName(item.booking.customer_name).first} {ParseName.parseFullName(item.booking.customer_name).last}
              </Link>
            </strong>
          ) : (
            <strong>{ParseName.parseFullName(item.booking.customer_name).first} {ParseName.parseFullName(item.booking.customer_name).last}</strong>
          )
        }

        <br />
        {item.booking.email.toLowerCase()}
      </React.Fragment>
    );
  }

  if (item.metadata && item.metadata?.customer_name) {
    return <span>{item.metadata.cardholder_name}</span>
  }

  return <span>-</span>;
}

const getItemTotals = (enums, currency, amount, surcharge) => {
  if (!surcharge) {
    return [
      CurrencyFormat(enums, currency).format(convertPenniesToDecimals(amount)),
      CurrencyFormat(enums, currency).format(convertPenniesToDecimals(0)),
    ]
  }

  return [
    CurrencyFormat(enums, currency).format(convertPenniesToDecimals(amount - surcharge.amount)),
    CurrencyFormat(enums, currency).format(convertPenniesToDecimals(surcharge.amount)),
  ]
}

const RowFormatter = ({item, childFilterState, enums, pathFilters, organisation}) => {
  const [total, surcharge] = getItemTotals(enums.currency, item.currency, item.amount, item.surcharge);

  const method = TransactionMethodTransformer(item.method);
  const type = TransactionTypeTransformer(item.type, item.metadata);
  const status = TransactionStatusTransformer(item.status);
  const history = useHistory();

  const rowClick = (event, url) => {
    if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON' && event.target.tagName !== 'INPUT') {
      history.push(url)
    }
  }

  return (
    <tr key={item.id} className="tb-tnx-item cursor-pointer hover:bg-gray-50" onClick={(event) => rowClick(event, `/transaction/${item.id}`)}>
      <td className='text-left'>
        <PiiObfuscator type="customer_name">{customerNameFormatter(item)}</PiiObfuscator>
      </td>
      <td className="text-left hidden lg:table-cell" style={{ maxWidth: '180px' }}>
        <span className="text-center title" style={{ inlineSize: '150px', overflowWrap: 'break-word' }}>
          {item.booking && (
            <Link to={`/booking/${item.booking.id}`} className="link__payment_link text-violet-800 underline">{item.booking.booking_reference}</Link>
          )}
          {!item.booking && item?.payment_link?.id && (
            <Link to={`/payment-links/${item.payment_link.id}/assign`}>
              <Button color="dark" className="bg-violet-800 text-white border-none">Assign to Booking</Button>
            </Link>
          )}
        </span>
      </td>
      <td className="text-center border-l w-[120px]">
        <span className="date">{total}</span>
      </td>
      {
        organisation?.surcharging_enabled === true && (
          <td className="text-center border-l w-[120px]">
            <span className="date">{surcharge}</span>
          </td>
        )
      }
      {
        pathFilters?.disbursement_id && (
          <td className="text-center border-l hidden lg:table-cell">
            <span className="date">{CurrencyFormat(enums.currency, item?.settlement?.currency).format(convertPenniesToDecimals(item?.settlement?.amount))}</span>
          </td>
        )
      }
      {childFilterState === true && (
        <td className="text-center border-l hidden lg:table-cell">
          <PiiObfuscator className="title" type="organisation">{item.organisation.name}</PiiObfuscator>
        </td>
      )}

      <td className="text-right border-l w-120px hidden lg:table-cell">
        <div style={{ width: '38px', padding: '0 4px 0 6px', maxWidth: '38px', margin: '0 auto' }}>
        {
          typeof type.image !== 'undefined' && (
            <img src={type.image} className={type.imageClass} />
          )
        }
        {
          typeof type.image === 'undefined' && (
            <Icon name={`ni text-primary icon ${type.icon} pr-3`} style={{ fontSize: '21px' }} />
          )
        }
        </div>
      </td>
      <td className="text-center border-left w-120px pl-0 hidden lg:table-cell">
        <span className="date" style={{ verticalAlign: 'top' }}>{type.label}</span>
      </td>
      <td className="text-center w-120px hidden lg:table-cell">
        <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
        <span className="date" style={{ verticalAlign: 'top' }}>{method.label}</span>
      </td>
      <td className="text-center border-l hidden lg:table-cell">
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="text-center lg:w-150px border-l px-1">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-light text-white ${status.color}`}>
          <Icon name={`ni text-white icon ${status.icon}`} />
          <span className="date" style={{ verticalAlign: 'top' }}>{status.label}</span>
        </span>
      </td>
    </tr>
  );
}

const PageOptions = ({requestParams, triggerReload, loading}) => {
  const { account } = useSelector((state) => state.account);
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    await axios.post(
      API_ROUTES.agent['csv:generate']('transaction'),
      { ...requestParams },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    toast.info(`Your export will be ready shortly. A link to download it will be sent to ${account.email}.`, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

    await new Promise(resolve => setTimeout(resolve, 10000));

    setFetchingCSV(false);
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
        </li>
      <li>
        <Button disabled={fetchingCSV} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = ({ childFilterState, pathFilters, organisation }) => {

  return (
    <tr className="tb-tnx-head">
  
      <th className='text-left'>
        <span>Customer</span>
      </th>
      <th className='text-left hidden lg:table-cell'>
        <span>Booking Ref</span>
      </th>
      <th className="text-center border-l">
        <span>{ pathFilters?.disbursement_id ? 'Transaction Amount' : 'Amount' }</span>
      </th>
      {
        organisation?.surcharging_enabled === true && (
          <th className='text-center border-l'>
            <span>Surcharge</span>
          </th>
        )
      }
      {
        pathFilters?.disbursement_id && (
          <th className="text-center border-l hidden lg:table-cell">
            <span>Settlement Amount</span>
          </th>
        )
      }
      {childFilterState === true && (
        <th className="text-center border-l hidden lg:table-cell">
          <span>Organisation</span>
        </th>
      )}
      <th className="text-center border-l hidden lg:table-cell">
        <span>Brand</span>
      </th>
      <th className="text-center border-left hidden lg:table-cell">
        <span>Type</span>
      </th>
      <th className="text-center hidden lg:table-cell">
        <span>Method</span>
      </th>
      <th className="border-l hidden lg:table-cell">
        <span>Date</span>
      </th>
      <th className="text-center border-l">
        <span>Status</span>
      </th>
    </tr>
  );
}

const Transactions = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const pathFilters = {
    disbursement_id: urlParams.get('disbursement_id'),
  };

  const endpoint = API_ROUTES.agent['payments:get']();

  if (urlParams.get('has_booking_reference')) {
    pathFilters.filter_booking_exists = urlParams.get('has_booking_reference') === '1' ? true : false;
  }

  let TopBlock = null;
  if (pathFilters.disbursement_id) {
    TopBlock = DisbursementInformation;
  }

  return (
    <React.Fragment>
      <DataTable
        title={pathFilters.disbursement_id ? 'Disbursal Payments' : 'Payments'}
        itemTitle={pathFilters.disbursement_id ? 'Disbursal Payments' : 'Payments'}
        largeHeading={true}
        columnCount={11}
        TableHeaders={TableHeaders}
        RowFormatter={RowFormatter}
        PageOptions={PageOptions}
        TopBlock={TopBlock}
        endpoint={endpoint}
        childOrganisationsOption={true}
        dateFilterOption={true}
        dateFilterLabel="Payment Date"
        searchPlaceholder="Customer or Booking Ref"
        pathFilters={pathFilters}
        noHeader={pathFilters.disbursement_id ? false : true}
        totalFilterOption={pathFilters.disbursement_id ? false : true}
        hideFilters={pathFilters.disbursement_id ? true : false}
        pathFiltersTranslations={{
          filter_booking_exists: {
            name: 'Has Booking',
            filterName: 'Unassigned Only',
            selectedValue: false,
            removeIfNotSelected: true,
          },
        }}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Transactions;
