import React, { useState } from 'react';
import Skeleton from "react-loading-skeleton";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader, ModalBody, FormGroup, ModalFooter, Spinner
} from "reactstrap";
import moment from "moment";
import { Button, Icon } from "src/components/Component";
import { Link } from "react-router-dom";
import CurrencyFormat from "../../../../../../transformer/currency-format";
import { useSelector } from "react-redux";
import { convertPenniesToDecimals } from "../../../../../../utils/money";
import PiiObfuscator from "../../../../../../components/pii-obfuscator";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../../../../../config/api";

const BankAccounts = ({ data, loading, triggerReload }) => {
  const { enums } = useSelector((state) => state.enums);
  const { organisation: { organisation } } = useSelector((state) => state);

  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);

  const [modalID, setModalID] = useState(null);
  const [modalName, setModalName] = useState('');

  const updateName = async () => {
    setModalFormProcessing(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.bank['account:rename'](modalID),
      {
        method: 'post',
        data: {
          organisation,
          name: modalName,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalFormProcessing(false);
    setModalForm(false);
    triggerReload();
  }

  const toggleForm = (id) => {
    setModalName('');
    setModalForm(!modalForm);
    setModalID(id);
  }

  return (
    <React.Fragment>
      <Card className="card-stretch">
        <CardHeader className="py-3 card-bordered bg-gray-50 border-bottom-0">
          <h5 className="text-primary mb-0 text-lg">
            Bank Accounts
          </h5>
        </CardHeader>

        <CardBody className="p-0">
          <table className="table table-tranx border-light card-bordered">
            <tbody>
              <tr className="tb-tnx-head">
                <th className='text-left'><span>Account Name</span></th>
                <th className="border-left text-center"><span>Last Updated</span></th>
                <th className="border-left text-center"><span>Account Number</span></th>
                <th className="text-center"><span>Sort Code</span></th>
                <th className="text-center"><span>IBAN</span></th>
                <th className="border-left text-center"><span>Balance</span></th>
                <th className="border-left text-center"><span>Actions</span></th>
              </tr>
              {loading && Array.from(Array(2).keys()).map((key) => (
                <tr className="tb-tnx-item" key={key}>
                  <td colSpan={7}>
                    <Skeleton count={1} height="50px" className="m-0 p-1" />
                  </td>
                </tr>
              ))
              }
              {loading === false && data?.accounts?.items.length === 0 && (
                <tr className="tb-tnx-item">
                  <td colSpan={7} className="text-center">
                    <strong>No accounts found</strong>
                  </td>
                </tr>
              )}
              {loading === false && data?.accounts?.items.map((account, key) => {
                const rowCount = account.nickname ? 2 : 1;

                return (
                  <React.Fragment>
                    <tr className="tb-tnx-item" key={key}>
                      <td className={`border-left !border-b-0${account.nickname ? ' pb-0' : ''}`}>{account.nickname || account.name}</td>
                      <td rowSpan={rowCount} className="border-left text-center">{moment(account.updated_at).format('Do MMMM YYYY')}</td>
                      <td rowSpan={rowCount} className="border-left text-center">
                        <PiiObfuscator className="title" type="account_number">{account.account_number || '---'}</PiiObfuscator>
                      </td>
                      <td rowSpan={rowCount} className="text-center">
                        <PiiObfuscator className="title" type="sort_code">{account.sort_code || '---'}</PiiObfuscator>
                      </td>
                      <td rowSpan={rowCount} className="text-center">
                        <PiiObfuscator className="title" type="iban">{account.iban || '---'}</PiiObfuscator>
                      </td>
                      <td rowSpan={rowCount} className="border-left text-center">{CurrencyFormat(enums.currency, account.currency).format(convertPenniesToDecimals(account.balance))}</td>
                    </tr>
                    {
                      rowCount === 2 && (
                        <tr>
                          <td className="!border-t-0 pt-0"><small>{account.name}</small></td>
                        </tr>
                      )
                    }
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-secondary text-white"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Update Account Name
        </ModalHeader>
        <ModalBody>
          <p>Please enter the new name</p>
          <FormGroup>
            <div className="form-control-wrap">
              <input
                type="text"
                id="return_date"
                name="return_date"
                value={modalName}
                onChange={(event) => setModalName(event.target.value)}
                className={`form-control form-control-xl date-picker`}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="danger" type="submit" disabled={modalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                updateName();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  )
}

export default BankAccounts;
