import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Axios from 'axios';
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { PlayIcon, PauseIcon, TrashIcon, PencilIcon } from '@heroicons/react/24/outline';

import { useSelector } from 'react-redux';
import { Button, Icon } from 'src/components/Component';
import API_ROUTES from "src/config/api";

const Options = ({ item, reload }) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const toggleConfirmation = () => setModalConfirmation(!modalConfirmation);
  const { organisation } = useSelector((state) => state);

  /**
   * Change organisation payment provider active state
   */
  const changePaymentProviderActiveState = async (state) => {
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.payment['organisation:provider:active:change'](organisation.id, item.id, state),
      {
        method: 'put',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    reload();
  };

  /**
   * Delete an organisation payment provider
   */
  const deletePaymentProvider = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setDisabled(true);

    await Axios(
      API_ROUTES.payment['organisation:provider:delete'](organisation.id, item.id),
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalConfirmation(false);

    reload();
  };

  return (
    <React.Fragment>
      <Link to={`/organisation/integrations/provider/${item.id}/edit`}>
        <button className='text-sm bg-violet-800 rounded-md px-2 py-[2px] text-white'>
          <PencilIcon className='h-3 w-3 -mt-[2px] mr-1 inline' />edit
        </button>
      </Link>
      {item.payment_provider.is_active && !item.is_active && (
        <button className='text-sm ml-1 bg-green-600 rounded-md px-2 py-[2px] text-white' onClick={() => changePaymentProviderActiveState('true')}><PlayIcon className='h-3 w-3 -mt-[2px] mr-1 inline' />activate</button>
      )}
      {item.payment_provider.is_active && item.is_active && (
        <button className='text-sm ml-1 bg-pink-600 rounded-md px-2 py-[2px] text-white' onClick={() => changePaymentProviderActiveState('false')}><PauseIcon className='h-3 w-3 -mt-[2px] mr-1 inline' />deactivate</button>
      )}
      <button className='text-sm ml-1 bg-red-600 rounded-md px-2 py-[2px] text-white' onClick={toggleConfirmation}><TrashIcon className='h-3 w-3 -mt-[2px] mr-1 inline' />delete</button>

      <Modal isOpen={modalConfirmation} toggle={toggleConfirmation}>
        <ModalHeader
          toggle={toggleConfirmation}
          className="bg-red-500 text-white text-lg"
          close={
            <button className="close" onClick={toggleConfirmation}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Delete Payment Provider
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you would like to delete the <strong>"{item.name}"</strong> payment provider?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="bg-red-500 text-white hover:bg-red-700" type="submit" disabled={disabled} onClick={
              (ev) => {
                ev.preventDefault();
                deletePaymentProvider(item);
              }
            } size="lg">
              Continue
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default Options;
