const CountryTypeTransformer = (countryType) => {
  const type = countryType.toUpperCase();

  if (type === 'DOMESTIC') return 'UK';
  if (type === 'INTRA') return 'EU & EEA';
  if (type === 'INTER') return 'Rest of World';

  return type;
}

export default CountryTypeTransformer;
