import PROVIDER_IDS, { PROVIDER_CONFIGURATION } from "src/config/providers";

const ProviderNameTransformer = (name) => {
  const lcName = name.toLowerCase();

  if (PROVIDER_IDS.hasOwnProperty(lcName)) {
    return PROVIDER_CONFIGURATION[PROVIDER_IDS[lcName]].display_name;
  }

  return name;
}

export default ProviderNameTransformer;
