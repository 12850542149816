import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async-creatable';
import Select from 'react-select';
import Validator from 'validator';
import { Row, Col, FormGroup, Card, Spinner, Label, Alert, CardHeader, CardFooter } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { camelCase, startCase } from 'lodash';
import { CogIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { DateRangePicker } from 'react-date-range';

import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
  Block, BlockBetween, BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from "src/components/Component";
import API_ROUTES from "src/config/api";
import CurrencyFormat from "src/transformer/currency-format";
import { convertNumberToPennies, roundDecimalMoney } from "src/utils/money";
import { DEFAULT_CURRENCY, mapCurrency } from "src/utils/currency";
import HasFeature from "src/utils/has-feature";

import './index.css'

const DEFAULT_PAYMENT_LINK_TYPE = { value: 'FULL_PAYMENT', label: 'Full Payment' };

/**
 * Payment Link Create Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentLinkCreate = ({ ...props }) => {
  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);

  const formDefaults = {
    amount: '',
    bookingField: null,
    booking: '',
    booking_id: null,
    name: '',
    email: '',
    departure_date: '',
    departure_date_pretty: '',
    return_date: '',
    return_date_pretty: '',
    description: '',
    expires_at: null,
    type: DEFAULT_PAYMENT_LINK_TYPE,
    advanced: {
      card_payments: true,
      open_banking: true,
      payment_reciept: true,
      authorise_only: false,
      currency: organisation.currencies.includes(DEFAULT_CURRENCY) ? DEFAULT_CURRENCY : organisation.currencies[0],
      surcharging: true,
      amex: true,
      mastercard: true,
      visa: true,
      domestic: true,
      inter: true,
      intra: true,
    },
  };

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [formValues, setFormValues] = useState(formDefaults);
  const [displayCurrency, setDisplayCurrency] = useState(mapCurrency(enums.currency[organisation.currencies.includes(DEFAULT_CURRENCY) ? DEFAULT_CURRENCY : organisation.currencies[0]]));
  const [advacedOptionsDisplay, setAdvancedOptionsDisplay] = useState({
    payment_methods: true,
    card_types: true,
    card_regions: true,
    pre_authorisation: true,
    custom_expiration: true,
    surcharging: true,
  });

  const currencies = organisation.currencies.map(item => {
    const currency = enums.currency[item];
    return mapCurrency(currency);
  });

  const paymentLinkTypes = enums.payment_link_type.map(item => ({
    value: item,
    label: startCase(camelCase(item)),
  }));

  const history = useHistory();

  const [formState, setFormState] = useState({
    isTemporaryBooking: false,
    allowCustomerFieldsEntry: false,
    bookingFieldEnabled: false,
    showAdvancedOptions: false,
    showSelectDropdown: false,
    asyncSelectLoaded: false,
  });

  const [bookingDateRange, setBookingDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  /**
   * Constantly check form field validity
   */
  useEffect(() => {
    checkFormValidity();
  }, [formValues]);

  const handleBookingDateChange = (newValue) => {
    setBookingDateRange({
      ...bookingDateRange,
      startDate: newValue.selection.startDate,
      endDate: newValue.selection.endDate,
    });

    setFormValues({
      ...formValues,
      departure_date: new Date(newValue.selection.startDate.getTime() - (newValue.selection.startDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
      return_date: new Date(newValue.selection.endDate.getTime() - (newValue.selection.endDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
    });
  }

  /**
   * Update a form value
   * @type {updateFormField}
   */
  const updateFormField = ((e) => {
    let value = e.target.value;

    setTouched({ ...touched, [e.target.id]: true });

    if (e.target.id === 'amount' && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      value = roundDecimalMoney(e.target.value);
    }

    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
  });

  /**
   * Update date fields
   * @param date
   * @param field
   */
  const updateDateField = (date, field) => {
    setTouched({
      ...touched,
      [field]: true,
    });

    setFormValues({
      ...formValues,
      [field]: new Date(date).toISOString().split('T')[0],
      [`${field}_pretty`]: new Date(date).toDateString(),
    });
  };

  /**
   * Set whether the booking is temporary
   * @param itemState
   */
  const setIsTemporary = (itemState) => {
    setFormValues({
      ...formDefaults,
      type: formValues.type,
      amount: formValues.amount,
      description: formValues.description,
      advanced: {
        ...formDefaults.advanced,
        currency: formValues.advanced.currency,
      }
    });

    setTouched({});

    setFormState({
      ...formState,
      isTemporaryBooking: itemState,
      allowCustomerFieldsEntry: itemState,
      bookingFieldEnabled: itemState === true,
    });
  };

  /**
   * Set the advanced options fields
   * @param e
   */
  const updateAdvanceOption = (e) => setFormValues({
    ...formValues,
    advanced: {
      ...formValues.advanced,
      [e.target.name]: e.target.checked,
    }
  });

  /**
   * Set currency (advanced option)
   * @param e
   */
  const setCurrency = (e) => {
    setDisplayCurrency(e);
    return setFormValues({
      ...formValues,
      advanced: {
        ...formValues.advanced,
        currency: e.id,
      }
    });
  };

  /**
   * Set whether advanced options should be shown
   * @param e
   */
  const showAdvancedOptions = (e) => {
    e.preventDefault();
    setFormState({ ...formState, showAdvancedOptions: true });
  };

  /**
   * Check the validity of the form and update state
   * @returns {{}}
   */
  const checkFormValidity = () => {
    const errorObj = {};

    if (formValues.amount === '') errorObj.amount = 'Amount cannot be empty';
    if (formValues.amount < 0.01 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be greater than zero';
    if (formValues.amount > 100000 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be less than or equal to 100,000';

    if (formValues.name === '') errorObj.name = 'Name cannot be empty';

    if (formValues.email === '') errorObj.email = 'Email cannot be empty';

    if (!Validator.isEmail(formValues.email) && typeof errorObj.email === 'undefined') errorObj.email = 'Email is Invalid';

    if (formState.isTemporaryBooking === false) {
      if (formValues.booking === '') errorObj.booking = 'Booking cannot be empty';

      if (formValues.departure_date === '') errorObj.departure_date = 'Departure date cannot be empty';

      if (formValues.return_date === '') errorObj.return_date = 'Return date cannot be empty';

      if (formValues.departure_date !== ''
        && formValues.return_date !== ''
        && new Date(formValues.return_date).getTime() < new Date(formValues.departure_date).getTime()
      ) {
        errorObj.return_date = 'Return date must be greater than or equal to departure date';
      }
    }

    setErrors(errorObj);

    return errorObj;
  };

  /**
   * Submit the form
   * @param e
   * @returns {Promise<boolean>}
   */
  const formSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      ...touched,
      name: true,
      email: true,
      amount: true,
      booking: true,
      departure_date: true,
      return_date: true,
    });

    const errorCheck = checkFormValidity();

    if (Object.keys(errorCheck).length >= 1) {
      return false;
    }

    setIsSubmitting(true);

    try {
      const user = await Auth.currentAuthenticatedUser();

      let bookingID = null

      // Create the booking if it is new
      if (formState.allowCustomerFieldsEntry === true && formState.isTemporaryBooking === false) {
        const bookingResponse = await Axios(
          API_ROUTES.agent['bookings:create'](),
          {
            method: 'put',
            data: {
              organisation: organisation.organisation,
              customer_name: formValues.name,
              email: formValues.email,
              booking_reference: formValues.booking,
              departure_date: formValues.departure_date !== '' ? formValues.departure_date : null,
              return_date: formValues.return_date !== '' ? formValues.return_date : null,
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          }
        );

        bookingID = bookingResponse.data.data.id;
      }

      const excludedCardTypes = [];
      if (formValues.advanced.amex === false) excludedCardTypes.push('AMEX');
      if (formValues.advanced.mastercard === false) excludedCardTypes.push('MASTER');
      if (formValues.advanced.visa === false) excludedCardTypes.push('VISA');

      const excludedCardRegions = [];
      if (formValues.advanced.domestic === false) excludedCardRegions.push('DOMESTIC');
      if (formValues.advanced.inter === false) excludedCardRegions.push('INTER');
      if (formValues.advanced.intra === false) excludedCardRegions.push('INTRA');

      // Create the payment link
      const paymentLink = await Axios(
        API_ROUTES.agent['payment-links:put'](),
        {
          method: 'put',
          data: {
            customer_name: formValues.name,
            email: formValues.email,
            booking_id: bookingID !== null ? bookingID : formValues.booking_id,
            amount: convertNumberToPennies(formValues.amount),
            organisation: organisation.organisation,
            open_banking_enabled: formValues.advanced.open_banking,
            card_enabled: formValues.advanced.card_payments,
            surcharging_enabled: formValues.advanced.surcharging,
            excluded_card_types: excludedCardTypes,
            excluded_card_regions: excludedCardRegions,
            currency: formValues.advanced.currency,
            description: formValues.description,
            expires_at: formValues.expires_at,
            authorisation_only: formValues.advanced.authorise_only,
            type_id: formValues.type.value,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      return history.push(`/payment-links/${paymentLink.data.data.id}`);
    } catch (error) {
      return history.push(`/errors/504`);
    }
  };

  /**
   * Find bookings using the API
   * @type {function(*): *}
   */
  const findBookings = useCallback(async (value) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['bookings:get'](organisation.organisation),
      {
        method: 'post',
        data: {
          take: 10,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFormState({ ...formState, asyncSelectLoaded: true });
    return result.data.data;
  }, []);

  /**
   * Populate the booking related fields on booking select
   * @param value
   * @returns {Promise<void>}
   */
  const populateBookingDetails = async (value) => {
    if (value !== null) {
      let newDuplicate = false;
      let data = { ...value };

      if (typeof value.__isNew__ !== 'undefined' && value.__isNew__ === true) {
        const bookingCheck = await findBookings(value.value);

        for (const booking of bookingCheck) {
          if (booking.booking_reference === value.value) {
            newDuplicate = true;
            data = booking;
          }
        }
      }

      if (typeof value.__isNew__ !== 'undefined' && value.__isNew__ === true && newDuplicate === false) {
        setFormState({ ...formState, asyncSelectLoaded: false, allowCustomerFieldsEntry: true });

        setFormValues({
          ...formValues,
          bookingField: value,
          booking: value.value,
          booking_id: null,
          name: '',
          email: '',
          departure_date: '',
          departure_date_pretty: '',
          return_date: '',
          return_date_pretty: '',
        });
      } else {
        setFormState({ ...formState, asyncSelectLoaded: false, allowCustomerFieldsEntry: false });

        const valuesToSet = {
          ...formValues,
          bookingField: value,
          booking: data.booking_reference,
          booking_id: data.id,
          name: data.customer_name,
          email: data.email,
          departure_date: data.departure_date,
          return_date: data.return_date,
        };
        if (data.departure_date) valuesToSet.departure_date_pretty = new Date(data.departure_date).toDateString();
        if (data.return_date) valuesToSet.return_date_pretty = new Date(data.return_date).toDateString();

        setFormValues(valuesToSet);
      }
      if (alertMessage) {
        setAlertMessage(false);
      }
    }
  }

  // Pre-Selected booking.
  const urlParams = new URLSearchParams(history.location.search);
  const preSelectedBookingID = urlParams.get('booking');

  const geBookingData = useCallback(async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const result = await Axios(
      API_ROUTES.agent['booking:get'](preSelectedBookingID),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        validateStatus: () => true,
      }
    );
    if (result.status === 200) {
      populateBookingDetails(result.data.data);
    } else {
      setAlertMessage(`Unable to set the pre-selected booking`);
    }
    setLoading(false);
  }, [preSelectedBookingID]);

  useEffect(() => {
    if (preSelectedBookingID) {
      geBookingData();
    }
  }, []);

  if (loading) {
    return (
      <React.Fragment>
        <Head title="New Payment Link" />
        <Content>

          <Card className="card-bordered p-5 rounded-xl shadow my-4">
            <BlockHead size="sm" className="mb-2">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal" page>
                    Create a Payment Link
                  </BlockTitle>
                  <BlockDes>Enter your customers payment details to take a payment via link, in person or over the phone</BlockDes>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block size="lg">
              <Skeleton count={5} height="60px" style={{ marginTop: "1em" }} />
            </Block>
          </Card>
        </Content>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Head title="New Payment Link" />
      <Content>

        <Card className="card-bordered rounded-md mt-3">

          <CardHeader className="border-0 px-4 py-4 bg-gray-50 border-b">
            <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal" page>
              Create a Payment Link
            </BlockTitle>
            <div className="toggle-wrap nk-block-tools-toggle float-right mt-[10px]">
              <div className="toggle-expand-content">
                <ul className="nk-block-tools g-3 pr-1">
                  {formState.showAdvancedOptions === false && (
                    <Button color="dark" outline className="border-violet-800 bg-violet-800 text-white hover:bg-slate-700 hover:text-white rounded-md mr-2 xs:invisble sm:invisible md:invisible lg:visible" id="button__back" onClick={showAdvancedOptions} disabled={isSubmitting}>
                      <CogIcon className='h-4 w-4 inline mr-1' /> Advanced Options
                    </Button>
                  )}
                  <Link to={'/payments/payment-links'}>
                    <Button color="dark" outline className="border border-violet-800 text-violet-800 hover:bg-slate-700 hover:text-white rounded-md" id="button__back">
                      <ArrowLeftIcon className='h-3.5 w-3.5 inline mr-1' />
                      <span>Payment Links</span>
                    </Button>
                  </Link>
                </ul>
              </div>
            </div>
          </CardHeader>

          {alertMessage && <><br /><Alert className="alert-icon" color="info"> {alertMessage} </Alert></>}

          <Block size="lg">

            <form>

              <div className={formState.showAdvancedOptions === true ? 'grid grid-cols-8 h-auto' : 'px-6 py-4'}>

                <div className={formState.showAdvancedOptions === true ? 'col-span-8 lg:col-span-6' : ''}>
                  <div className="gy-3 px-4 py-4'">

                    <Row className="g-3 align-center">
                      <Col md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Payment Amount<small className="text-danger pl-1 pb-3">required</small>
                          </Label>
                          <div className="form-control-wrap input-group">
                            <div className="input-group-addon pl-3 pr-3">{CurrencyFormat(enums.currency, formValues.advanced.currency).getCurrencySymbol()}</div>
                            <input
                              type="number"
                              id="amount"
                              disabled={isSubmitting}
                              value={formValues.amount}
                              onChange={updateFormField}
                              className={`form-control form-control-xl ${typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' ? 'error' : ''}`}
                              min={0}
                              step={0.01}
                              placeholder="e.g. 200.00"
                            />
                          </div>
                          {typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' && (
                            <small className="text-danger">{errors.amount}</small>
                          )}
                          {(typeof errors.amount === 'undefined' || typeof touched.amount === 'undefined') && (
                            <span className="form-note">The amount that the customer will pay</span>
                          )}
                        </FormGroup>
                      </Col>

                      {organisation.currencies.length > 1 && (
                        <Col md="6" lg="4">
                          <FormGroup>
                            <Label htmlFor="default-2" className="form-label text-primary">
                              Currency
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-wrap">
                                <Select
                                  className="form-control form-control-xl p-0 border-0 indicator-hidden"
                                  value={displayCurrency}
                                  getOptionLabel={item => item.label}
                                  getOptionValue={item => item.id}
                                  options={currencies}
                                  onChange={setCurrency}
                                  isDisabled={currencies.length <= 1}
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                            {currencies.length <= 1 && (
                              <span className="form-note">Multi currency is not available, contact your account manager to enable.</span>
                            )}
                            {currencies.length > 1 && (
                              <span className="form-note">The currency that the customer will pay in</span>
                            )}
                          </FormGroup>
                        </Col>
                      )}


                      <Col md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Payment Link Type
                          </Label>
                          <div className="form-control-wrap">
                            <div className="form-control-wrap">
                              <Select
                                name='type'
                                onChange={(val) => setFormValues({ ...formValues, type: val })}
                                value={formValues.type}
                                className="form-control form-control-xl p-0 border-0 indicator-hidden"
                                options={paymentLinkTypes}
                                classNamePrefix="react-select"
                              />
                            </div>
                          </div>
                          <span className="form-note">Payment Link Type ((Full Payment / Deposit))</span>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="g-3 align-center">
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Payment Link Description
                          </Label>
                          <div className="border">
                            <textarea
                              rows={2}
                              id="description"
                              name="description"
                              disabled={isSubmitting}
                              value={formValues.description}
                              onChange={updateFormField}
                              placeholder="e.g. Trip to Hawaii"
                              className={`w-100 p-2`}
                            />
                          </div>
                          <span className="form-note">The description of what the payment is for (this will be shown to the customer at payment)</span>
                        </FormGroup>
                      </Col>
                    </Row>

                    {!preSelectedBookingID && (
                      <React.Fragment>
                        <div className="nk-divider divider md pb-2" />

                        <Row className="g-3 align-center">
                          <Col lg="5">
                            <FormGroup>
                              <label className="form-label text-primary">Unknown Booking Reference</label>
                              <span className="form-note">If you don't know the booking reference, this will allow you to fill it in at a later stage.</span>
                            </FormGroup>
                          </Col>
                          <Col lg="7">
                            <FormGroup>
                              <div className="form-control-wrap">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    disabled={isSubmitting}
                                    className="custom-control-input form-control"
                                    checked={formState.isTemporaryBooking}
                                    value={formValues.bookingID}
                                    onChange={(e) => { setIsTemporary(e.target.checked) }}
                                    id="temp-booking-switch"
                                    name="temp-booking-switch"
                                  />
                                  <label className="custom-control-label" htmlFor="temp-booking-switch" />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        {formState.bookingFieldEnabled === false && (
                          <Row className="g-3 align-center">
                            <Col lg="5">
                              <FormGroup>
                                <label className="form-label text-primary">Booking Reference</label>
                                <span className="form-note">The customers reference for their booking</span>
                              </FormGroup>
                            </Col>
                            <Col lg="7">
                              <FormGroup>
                                <div className="form-control-wrap">
                                  <AsyncSelect
                                    isClearable
                                    className={`form-control form-control-xl p-0 border-0 indicator-hidden ${typeof errors.booking !== 'undefined' && typeof touched.booking !== 'undefined' ? 'error' : ''}`}
                                    allowCreateWhileLoading={false}
                                    value={formValues.bookingField}
                                    isMulti={false}
                                    getOptionLabel={e => {
                                      if (e.__isNew__) {
                                        return e.label;
                                      }

                                      return `${e.booking_reference} | ${e.customer_name}`;
                                    }}
                                    getOptionValue={e => e.id}
                                    loadOptions={findBookings}
                                    onChange={populateBookingDetails}
                                    menuIsOpen={formState.asyncSelectLoaded === true}
                                    placeholder="e.g. TEST-123"
                                    classNamePrefix="react-select"
                                    createOptionPosition="first"
                                    isDisabled={isSubmitting}
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                      let response = false;

                                      if (inputValue && inputValue.length >= 1) {
                                        response = true;
                                      }

                                      if (inputValue && inputValue.length > 0 && selectOptions && selectOptions.length > 0) {
                                        selectOptions.forEach((item) => {
                                          if (item.booking_reference.toLowerCase() === inputValue.toLowerCase()) response = false;
                                        })
                                      }

                                      return response;
                                    }}
                                  />
                                </div>
                                {typeof errors.booking !== 'undefined' && typeof touched.booking !== 'undefined' && (
                                  <small className="text-danger">{errors.booking}</small>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </React.Fragment>
                    )}

                    {
                      (formState.allowCustomerFieldsEntry === true || formValues.bookingField) && (
                        <React.Fragment>

                          <div className="nk-divider divider md pb-2" />

                          <Row className="g-3 align-top">
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="default-2" className="form-label text-primary">
                                  Customer Name
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formValues.name}
                                    onChange={updateFormField}
                                    className={`form-control form-control-xl ${typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' ? 'error' : ''}`}
                                    disabled={formState.allowCustomerFieldsEntry === false || isSubmitting === true}
                                  />
                                </div>
                                {typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' && (
                                  <small className="text-danger">{errors.name}</small>
                                )}
                                {(typeof errors.name === 'undefined' || typeof touched.name === 'undefined') && (
                                  <span className="form-note">The customers full name</span>
                                )}
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="default-2" className="form-label text-primary">
                                  Customer Email
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={updateFormField}
                                    className={`form-control form-control-xl ${typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' ? 'error' : ''}`}
                                    disabled={formState.allowCustomerFieldsEntry === false || isSubmitting === true}
                                  />
                                </div>
                                {typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' && (
                                  <small className="text-danger">{errors.email}</small>
                                )}
                                {(typeof errors.email === 'undefined' || typeof touched.email === 'undefined') && (
                                  <span className="form-note">The customers email address</span>
                                )}
                              </FormGroup>
                            </Col>

                            {
                              formState.isTemporaryBooking === false && formState.allowCustomerFieldsEntry === true && (
                                <Col sm="6" className="booking-picker">
                                  <Label htmlFor="default-2" className="form-label text-primary">
                                    Departure & Return Date
                                  </Label>
                                  <div className={`border rounded-md overflow-hidden mt-1 ${typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' ? 'border-red-500' : 'border-gray-200'}`}>
                                    <DateRangePicker
                                      ranges={[bookingDateRange]}
                                      onChange={handleBookingDateChange}
                                      staticRanges={[]}
                                      inputRanges={[]}
                                    />
                                  </div>
                                  {typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' && (
                                    <div className="text-danger text-xs">{errors.departure_date}</div>
                                  )}
                                </Col>
                              )
                            }

                            {
                              formState.isTemporaryBooking === false && formState.allowCustomerFieldsEntry === false && (
                                <React.Fragment>
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="default-2" className="form-label text-primary">
                                        Departure Date
                                      </Label>
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          id="departure_date"
                                          name="departure_date"
                                          value={formValues.departure_date_pretty}
                                          disabled={true}
                                          className={`form-control form-control-xl date-picker ${typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' ? 'error' : ''}`}
                                        />
                                      </div>
                                      {typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' && (
                                        <small className="text-danger">{errors.departure_date}</small>
                                      )}
                                      {(typeof errors.departure_date === 'undefined' || typeof touched.departure_date === 'undefined') && (
                                        <span className="form-note">The date that the customers holiday departs</span>
                                      )}
                                    </FormGroup>
                                    <FormGroup>
                                      <Label htmlFor="default-2" className="form-label text-primary">
                                        Return Date
                                      </Label>
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          id="return_date"
                                          name="return_date"
                                          value={formValues.return_date_pretty}
                                          disabled={true}
                                          className={`form-control form-control-xl date-picker ${typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' ? 'error' : ''}`}
                                        />
                                      </div>
                                      {typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' && (
                                        <small className="text-danger">{errors.departure_date}</small>
                                      )}
                                      {(typeof errors.return_date === 'undefined' || typeof touched.return_date === 'undefined') && (
                                        <span className="form-note">The date that the customers holiday returns</span>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </React.Fragment>
                              )
                            }
                          </Row>
                        </React.Fragment>
                      )
                    }

                  </div>
                </div>

                {
                  formState.showAdvancedOptions === true && (
                    <div className="col-span-2 bg-slate-50 bl-xl pb-6 xs:invisble sm:invisible md:invisible lg:visible">
                      <h2 className="bg-slate-800 text-white font-thin text-lg mb-1 px-3 py-1">Advanced Options</h2>

                      <div className="px-3 py-2">
                        {
                          formValues.advanced.card_payments === true && (
                            <div className="mb-4">
                              <div className="cursor-pointer" onClick={() => setAdvancedOptionsDisplay({ ...advacedOptionsDisplay, card_types: advacedOptionsDisplay.card_types !== true })}>
                                {advacedOptionsDisplay.card_types === true ? <React.Fragment /> : <ArrowRightIcon className="h-4 w-4 float-right mt-1 text-primary" />}
                                <h2 className={`text-primary text-lg font-semibold ${advacedOptionsDisplay.card_types === true ? 'border-b border-slate-200' : ''}`}>Allowed Card Types</h2>
                              </div>

                              {advacedOptionsDisplay.card_types === true && (
                                <React.Fragment>
                                  <p className="text-xs mt-1 mb-2 text-slate-600">Set which card types can be used for payment</p>

                                  <div className="grid grid-cols-2 h-auto py-1">
                                    <label className="form-label text-primary">AMEX</label>
                                    <div className="form-control-wrap">
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          checked={formValues.advanced.amex}
                                          onChange={updateAdvanceOption}
                                          disabled={isSubmitting}
                                          id="amex"
                                          name="amex"
                                        />
                                        <label className="custom-control-label" htmlFor="amex" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-2 h-auto py-1">
                                    <label className="form-label text-primary">Mastercard</label>
                                    <div className="form-control-wrap">
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          checked={formValues.advanced.mastercard}
                                          onChange={updateAdvanceOption}
                                          disabled={isSubmitting}
                                          id="mastercard"
                                          name="mastercard"
                                        />
                                        <label className="custom-control-label" htmlFor="mastercard" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-2 h-auto py-1">
                                    <label className="form-label text-primary">Visa</label>
                                    <div className="form-control-wrap">
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          checked={formValues.advanced.visa}
                                          onChange={updateAdvanceOption}
                                          disabled={isSubmitting}
                                          id="visa"
                                          name="visa"
                                        />
                                        <label className="custom-control-label" htmlFor="visa" />
                                      </div>
                                    </div>
                                  </div>

                                </React.Fragment>
                              )}
                            </div>
                          )}

                        {
                          formValues.advanced.card_payments === true && (
                            <div className="mb-4">
                              <div className="cursor-pointer" onClick={() => setAdvancedOptionsDisplay({ ...advacedOptionsDisplay, card_regions: advacedOptionsDisplay.card_regions !== true })}>
                                {advacedOptionsDisplay.card_regions === true ? <React.Fragment /> : <ArrowRightIcon className="h-4 w-4 float-right mt-1 text-primary" />}
                                <h2 className={`text-primary text-lg font-semibold ${advacedOptionsDisplay.card_regions === true ? 'border-b border-slate-200' : ''}`}>Allowed Card Regions</h2>
                              </div>

                              {advacedOptionsDisplay.card_regions === true && (
                                <React.Fragment>
                                  <p className="text-xs mt-1 mb-2 text-slate-600">Set which regions card payments can be accepted from</p>

                                  <div className="grid grid-cols-2 h-auto py-1">
                                    <label className="form-label text-primary">UK</label>
                                    <div className="form-control-wrap">
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          checked={formValues.advanced.domestic}
                                          onChange={updateAdvanceOption}
                                          disabled={isSubmitting}
                                          id="domestic"
                                          name="domestic"
                                        />
                                        <label className="custom-control-label" htmlFor="domestic" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-2 h-auto py-1">
                                    <label className="form-label text-primary">Europe / EEA</label>
                                    <div className="form-control-wrap">
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          checked={formValues.advanced.intra}
                                          onChange={updateAdvanceOption}
                                          disabled={isSubmitting}
                                          id="intra"
                                          name="intra"
                                        />
                                        <label className="custom-control-label" htmlFor="intra" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-2 h-auto py-1">
                                    <label className="form-label text-primary">Rest of World</label>
                                    <div className="form-control-wrap">
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          checked={formValues.advanced.inter}
                                          onChange={updateAdvanceOption}
                                          disabled={isSubmitting}
                                          id="inter"
                                          name="inter"
                                        />
                                        <label className="custom-control-label" htmlFor="inter" />
                                      </div>
                                    </div>
                                  </div>

                                </React.Fragment>
                              )}
                            </div>
                          )}

                        <div className="cursor-pointer" onClick={() => setAdvancedOptionsDisplay({ ...advacedOptionsDisplay, custom_expiration: advacedOptionsDisplay.custom_expiration !== true })}>
                          {advacedOptionsDisplay.custom_expiration === true ? <React.Fragment /> : <ArrowRightIcon className="h-4 w-4 float-right mt-1 text-primary" />}
                          <h2 className={`text-primary text-lg font-semibold ${advacedOptionsDisplay.custom_expiration === true ? 'border-b border-slate-200' : ''}`}>Custom Expiration Date</h2>
                        </div>

                        {advacedOptionsDisplay.custom_expiration === true && (
                          <React.Fragment>
                            <p className="text-xs mt-1 mb-2 text-slate-600">Set a custom expiration date for the payment link</p>

                            <FormGroup>
                              <div className="form-control-wrap">
                                <DatePicker
                                  id="expires_at"
                                  name="expires_at"
                                  value={formValues.expires_at}
                                  onChange={(value) => updateDateField(value, 'expires_at')}
                                  placeholderText="Click to select"
                                  className={`w-100 form-control form-control-md date-picker ${typeof errors.expires_at !== 'undefined' && typeof touched.expires_at !== 'undefined' ? 'error' : ''}`}
                                />
                                {typeof errors.expires_at !== 'undefined' && typeof touched.expires_at !== 'undefined' && (
                                  <small className="text-danger">{errors.expires_at}</small>
                                )}
                              </div>
                            </FormGroup>
                          </React.Fragment>
                        )}

                        <div className="cursor-pointer mt-4" onClick={() => setAdvancedOptionsDisplay({ ...advacedOptionsDisplay, payment_methods: advacedOptionsDisplay.payment_methods !== true })}>
                          {advacedOptionsDisplay.payment_methods === true ? <React.Fragment /> : <ArrowRightIcon className="h-4 w-4 float-right mt-1 text-primary" />}
                          <h2 className={`text-primary text-lg font-semibold ${advacedOptionsDisplay.payment_methods === true ? 'border-b border-slate-200' : ''}`}>Payment Methods</h2>
                        </div>
                        {
                          advacedOptionsDisplay.payment_methods === true && (
                            <React.Fragment>
                              <p className="text-xs mt-1 mb-2 text-slate-600">Set which payment methods your customer will be able to use to pay with</p>

                              <div className="grid grid-cols-2 h-auto py-1">
                                <label className="form-label text-primary">Card Payments</label>
                                <div className="form-control-wrap">
                                  <div className="custom-control custom-switch float-right">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input form-control"
                                      checked={formValues.advanced.card_payments}
                                      onChange={updateAdvanceOption}
                                      disabled={isSubmitting}
                                      id="Card Payments"
                                      name="card_payments"
                                    />
                                    <label className="custom-control-label" htmlFor="Card Payments" />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-2 h-auto py-1">
                                <label className="form-label text-primary">Open Banking</label>
                                <div className="form-control-wrap">
                                  <div className="custom-control custom-switch float-right">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input form-control"
                                      checked={formValues.advanced.open_banking}
                                      onChange={updateAdvanceOption}
                                      disabled={isSubmitting}
                                      id="Open Banking"
                                      name="open_banking"
                                    />
                                    <label className="custom-control-label" htmlFor="Open Banking" />
                                  </div>
                                </div>
                              </div>

                            </React.Fragment>
                          )
                        }

                        {
                          HasFeature(organisation.features, ['pre-auth']) && formValues.advanced.card_payments === true && (
                            <React.Fragment>
                              <div className="cursor-pointer mt-4" onClick={() => setAdvancedOptionsDisplay({ ...advacedOptionsDisplay, pre_authorisation: advacedOptionsDisplay.pre_authorisation !== true })}>
                                {advacedOptionsDisplay.pre_authorisation === true ? <React.Fragment /> : <ArrowRightIcon className="h-4 w-4 float-right mt-1 text-primary" />}
                                <h2 className={`text-primary text-lg font-semibold ${advacedOptionsDisplay.pre_authorisation === true ? 'border-b border-slate-200' : ''}`}>Pre-authorisation</h2>
                              </div>
                              {
                                advacedOptionsDisplay.pre_authorisation === true && (
                                  <React.Fragment>
                                    <p className="text-xs mt-1 mb-2 text-slate-600">Whether to only authorise the transaction and not take payment (the transaction will need to be manually completed)</p>

                                    <div className="grid grid-cols-2 h-auto py-1">
                                      <label className="form-label text-primary">Enabled</label>
                                      <div className="form-control-wrap">
                                        <div className="custom-control custom-switch float-right">
                                          <input
                                            type="checkbox"
                                            name="authorise_only"
                                            className="custom-control-input form-control"
                                            checked={formValues.advanced.authorise_only}
                                            onChange={updateAdvanceOption}
                                            disabled={isSubmitting}
                                            id="Authorise Only"
                                          />
                                          <label className="custom-control-label" htmlFor="Authorise Only" />
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              }
                            </React.Fragment>
                          )
                        }

                        {
                          organisation?.surcharging_enabled === true && formValues.advanced.card_payments === true && (
                            <React.Fragment>
                              <div className="cursor-pointer mt-4" onClick={() => setAdvancedOptionsDisplay({ ...advacedOptionsDisplay, surcharging: advacedOptionsDisplay.surcharging !== true })}>
                                {advacedOptionsDisplay.surcharging === true ? <React.Fragment /> : <ArrowRightIcon className="h-4 w-4 float-right mt-1 text-primary" />}
                                <h2 className={`text-primary text-lg font-semibold ${advacedOptionsDisplay.surcharging === true ? 'border-b border-slate-200' : ''}`}>Surcharging</h2>
                              </div>
                              {
                                advacedOptionsDisplay.surcharging === true && (
                                  <React.Fragment>
                                    <p className="text-xs mt-1 mb-2 text-slate-600">Whether customer should be surcharged on applicable card types</p>

                                    <div className="grid grid-cols-2 h-auto py-1">
                                      <label className="form-label text-primary">Enabled</label>
                                      <div className="form-control-wrap">
                                        <div className="custom-control custom-switch float-right">
                                          <input
                                            type="checkbox"
                                            name="surcharging"
                                            className="custom-control-input form-control"
                                            checked={formValues.advanced.surcharging}
                                            onChange={updateAdvanceOption}
                                            disabled={isSubmitting}
                                            id="Surcharging"
                                          />
                                          <label className="custom-control-label" htmlFor="Surcharging" />
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              }
                            </React.Fragment>
                          )
                        }

                      </div>
                    </div>
                  )
                }


              </div>

            </form>
          </Block>
          <CardFooter className="border-0 bg-slate-50 border-t overflow-hidden">
            <FormGroup>
              <Button
                id="payment_link__submit"
                color="primary"
                size="lg"
                onClick={formSubmit}
                disabled={isSubmitting}
                className="float-right"
              >
                {isSubmitting === true ? (
                  <React.Fragment>
                    Generating Link <Spinner style={{ marginLeft: '1em' }} size="sm"> </Spinner>
                  </React.Fragment>
                ) : (
                  <span>Create Payment Link</span>
                )}
              </Button>
            </FormGroup>
          </CardFooter>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default PaymentLinkCreate;
