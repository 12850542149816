import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Skeleton from "react-loading-skeleton";
import { Card, CardHeader, CardBody } from "reactstrap";

import { API_ROUTES } from 'src/config/api';
import Head from "src/layout/head/Head";

import { buildFormData, buildInitialValues } from "../components/utils";
import Form from "../components/form";

import { PROVIDER_CONFIGURATION } from 'src/config/providers';

const OrganisationPaymentConfigEdit = () => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();

  const [transactionMethods, setTransactionMethods] = useState();
  const [beneficaries, setBeneficaries] = useState();

  const { enums } = useSelector((state) => state.enums);
  const currencies = Object.values(enums.currency).map((item) => ({ id: item.minor_unit }));

  const { organisation } = useSelector((state) => state);
  const { id } = useParams();

  const history = useHistory();

  const getProviderData = useCallback(async () => {
    setLoading(true);

    if (!organisation.organisation) {
      return null;
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.payment['organisation:provider:get'](organisation.organisation, id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );
    const data = result.data.data;

    const initialValues = buildInitialValues(data);

    setInitialValues(initialValues);
    setLoading(false);
  }, [organisation.organisation, id]);

  useEffect(() => {
    const fetchBeneficaries = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.ledger['beneficiaries:get'](),
        {
          method: 'post',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          data: { organisation: organisation.organisation },
        }
      );

      const data = result.data.data;
      setBeneficaries(data);
    };

    const fetchTransactionMethods = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['transaction-methods:list'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setTransactionMethods(data);
    };

    fetchBeneficaries();
    fetchTransactionMethods();
  }, []);

  useEffect(() => {
    getProviderData();
  }, [getProviderData, organisation.organisation, id]);

  const processForm = async (values) => {
    const user = await Auth.currentAuthenticatedUser();

    const data = buildFormData(values);

    await Axios(
      API_ROUTES.payment['organisation:provider:update'](organisation.organisation, id),
      {
        method: 'put',
        data,
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    history.push(PROVIDER_CONFIGURATION[data.payment_provider_id].manage_path);
  };

  return (
    <React.Fragment>
      <Head title="Edit Payment Provider" />
      <Card className="card-stretch card-bordered">
        <CardHeader className="py-3 bg-slate-50 border-b">
          <h5 className="text-primary mb-0 text-xl">
            Update <strong>Provider Configuration</strong>
          </h5>
        </CardHeader>


        <CardBody>
          {(loading || !initialValues || !transactionMethods || !currencies) && (
            <Skeleton count={3} height="150px" className="mb-3" style={{ width: '100%' }} />
          )}

          {(!loading && initialValues && transactionMethods && currencies) && (
            <Form
              organisation={organisation}
              transactionMethods={transactionMethods}
              currencies={currencies}
              initialValues={initialValues}
              buttonText="Update Payment Provider"
              processForm={processForm}
              secretKeyOptional={true}
              beneficaries={beneficaries}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default OrganisationPaymentConfigEdit;
