import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardBody, CardHeader, Button as ButtonShape } from "reactstrap";
import Axios from 'axios';
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import Head from 'src/layout/head/Head';
import { Link } from 'react-router-dom';

import { PlayIcon, PauseIcon, PlusIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

import ProviderOptions from './components/provider-options';
import API_ROUTES from "src/config/api";

import 'react-loading-skeleton/dist/skeleton.css'

const PaymentProvider = ({ providerID, providerName }) => {
  const [loading, setLoading] = useState(true);

  const [providers, setProviders] = useState([]);
  const { organisation } = useSelector((state) => state);

  const getProviders = useCallback(async (triggerLoading = true) => {
    if (triggerLoading) {
      setLoading(true);
    }

    if (!organisation?.organisation) {
      return null;
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.payment['organisation:providers'](organisation.organisation, providerID),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setProviders(result.data.data);
    setLoading(false);
  }, [organisation.id]);

  useEffect(() => {
    getProviders();
  }, [getProviders, organisation.organisation]);

  return (
    <React.Fragment>

      <Head title="Nuapay Integration" />

      <Card className="card-stretch card-bordered">

        <CardHeader className="py-3 bg-slate-50 border-b">
          <div className='float-right'>
            <ul className="nk-block-tools g-3">
              <li>
                <Link to={`/organisation/integrations`}>
                  <ButtonShape color="light" className="btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800">
                    <ArrowLeftIcon className="h-4 w-4 -mt-0.5 mr-1" />
                    <span>Back</span>
                  </ButtonShape>
                </Link>
              </li>
              <li>
                <Link to={`/organisation/integrations/provider/${providerID}/create`}>
                  <ButtonShape color="light" className="btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800">
                    <PlusIcon className="h-4 w-4 -mt-0.5 mr-1" />
                    <span>Add Connection</span>
                  </ButtonShape>
                </Link>
              </li>
            </ul>
          </div>
          <h5 className="text-primary mb-0 text-xl">
            {providerName} <strong>Connections</strong>
          </h5>
        </CardHeader>

        <CardBody className="p-2">
          {loading === true && (
            <div className="text-center mt-1 mx-1">
              <Skeleton count={5} height="50px" className="mb-2" style={{
                width: '100%',
              }} />
            </div>
          )}

          {loading === false && providers.length > 0 && (
            <table className="table table-row-bordered table-row-gray-100 align-middle table-row-outer-border">
              <thead>
                <tr>
                  <th className='text-left'>Connection Name</th>
                  <th className='text-center border-l'>Methods</th>
                  <th className='text-center'>Currencies</th>
                  <th className='border-l text-center'>Status</th>
                  <th className='border-l'>Options</th>
                </tr>
              </thead>
              <tbody>
                {providers.map((provider, index) => (
                  <tr key={index}>
                    <td>{provider?.name}</td>
                    <td className='border-l text-center'>
                      {provider.transaction_methods.map((item, key) => (
                        <ButtonShape key={key} color="primary" disabled className="mt-[2px] mr-1 mb-1" size="xs">{item.name}</ButtonShape>
                      ))}
                    </td>
                    <td className='text-center'>
                      {provider.currencies.map((item, key) => (
                        <ButtonShape key={key} color="primary" disabled className="mt-[2px] mr-1 mb-1" size="xs">{item}</ButtonShape>
                      ))}
                    </td>
                    <td className='border-l text-center'>
                      <span className='text-center'>
                        {provider.is_active ? <PlayIcon className='h-5 w-5 -mt-[2px] mr-0.5 text-green-600 inline' /> : <PauseIcon className='h-5 w-5 -mt-[2px] mr-0.5 text-red-600 inline' />}
                        {provider.is_active ? 'Active ' : 'Inactive'}
                      </span>
                    </td>
                    <td className='text-center max-w-[250px] border-l'>
                      <ProviderOptions item={provider} reload={() => getProviders(true)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {loading === false && providers.length === 0 && (
            <div className="text-center my-2">
              <p className="text-gray-500 text-lg">No connections found</p>
              <Link to={`/organisation/integrations/provider/${providerID}/create`}>
                <button className="btn btn-primary bg-violet-800 mt-2">Add Connection</button>
              </Link>
            </div>
          )}

        </CardBody>

      </Card>
    </React.Fragment>
  )
};

export default PaymentProvider;
