import React from 'react';

import { useLocation, Link } from 'react-router-dom'

function GroupMenu({ items, title, titleOnly }) {
  const { pathname } = useLocation();

  const baseClass = 'text-lg pt-2 pb-[10px] mr-4 inline-flex items-center gap-x-2 border-b-2 text-sm whitespace-nowrap text-purple-800 hover:text-purple-800 disabled:opacity-50 disabled:pointer-events-none';
  const inaCtiveClass = `${baseClass} font-normal border-transparent`;
  const activeClass = `${baseClass} font-extrabold border-purple-900 border-b-`;
  let activeItem = null;

  let count = 0;
  for (const item of items) {
    if (item.check) {
      count += 1;
    }

    if (item?.path === pathname) {
      activeItem = item;
    }
  }

  let availableItems = 0;
  let activeTitle = null;
  for (const item of items) {
    if (item.check === true || typeof item.check === 'undefined') {
      availableItems += 1;
    }

    if (item.check && pathname === item.path) {
      activeTitle = item;
    }
  }

  if (availableItems === 0) {
    return (
      <div className="my-3 pt-1 border-b-2 border-violet-200 position-relative">
        <h2
          className={`text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-1 font-normal`}>
          {activeTitle?.icon && <activeTitle.icon className="w-7 h-7 text-purple-900 inline-block -mt-2 mr-1.5 " />}
          {activeTitle?.name || title}
        </h2>
      </div>
    );
  }

  return (
    <div className="my-3 pt-1 border-b-2 border-violet-200 position-relative">

      <h2
        className={`text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-1 font-normal`}>
        {activeTitle?.icon && <activeTitle.icon className="w-7 h-7 text-purple-900 inline-block -mt-2 mr-1.5 " />}
        {activeTitle?.name || title}
      </h2>

      <nav id="page-tabs-bar" className={`ml-0.5 block lg:flex lg:space-x-4 -mb-0.5 ${items.length === 0 || count < 2 ? 'pb-2' : ''} `}>

        {count > 1 && items.map((item, index) => {
          if (item.check && typeof item.visible === 'undefined') {
            return (
              <Link
                to={item.path}
                key={index}
                className="w-100 lg:w-auto block lg:inline-grid"
              >
                <button
                  type="button"
                  className={pathname === item.path || activeItem?.parent === item?.name ? activeClass : inaCtiveClass}
                  id={`basic-tabs-item-${index + 1}`}
                  aria-controls={`basic-tabs-item-${index + 1}`}
                  role="tab"
                >
                  {item.name}
                </button>
              </Link>
            )
          }

          return <React.Fragment key={index} />
        })}

      </nav>
    </div>
  );
}
export default GroupMenu;
