import React, { useState, useCallback } from "react";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
} from "reactstrap";
import {
  Icon,
  Row,
  Col,
  Button,
} from "src/components/Component";
import { useForm } from "react-hook-form";
import { Auth } from 'aws-amplify';
import { API_ROUTES } from 'src/config/api';
import Axios from 'axios';
import { useSelector } from "react-redux";

import { XMarkIcon } from "@heroicons/react/24/outline";

const defaultState = {
  first_name: "",
  last_name: "",
  email: "",
};

const AddUserModal = ({ refreshUsers }) => {
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const organisation = useSelector((state) => state.organisation);

  const [formData, setFormData] = useState(defaultState);

  const onFormSubmit = useCallback(async (userData) => {
    setFormData(defaultState);
    setModal({ edit: false }, { add: false });
    const user = await Auth.currentAuthenticatedUser(organisation.organisation);

    await Axios.post(
      API_ROUTES.user['organisation:user:add'](organisation.organisation),
      JSON.stringify(userData),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    refreshUsers();
  }, [organisation.organisation]);

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    setFormData(defaultState);
    refreshUsers();
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <ul className="nk-block-tools g-3">
        <li>
          <button className="mt-1.5 mr-1 btn border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" onClick={() => setModal({ add: true })}>
            <Icon name="user-add" />
            <span>Add / Invite User</span>
          </button>
        </li>
      </ul>

      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <Form noValidate onSubmit={handleSubmit(onFormSubmit)}>
          <ModalHeader
            className="bg-violet-800 text-xl text-white"
            close={
              <button className="mt-0.5 text-white" onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}>
                <XMarkIcon className="h-6 w-6" />
              </button>
            }
          >
            Add or Invite User
          </ModalHeader>
          <ModalBody>
            <div className="px-2">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="first_name"
                      defaultValue={formData.first_name}
                      placeholder="e.g. Luke"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.name && <span className="invalid">{errors.first_name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label">Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="last_name"
                      defaultValue={formData.last_name}
                      placeholder="e.g. Skywalker"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.name && <span className="invalid">{errors.last_name.message}</span>}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label">Email </label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      defaultValue={formData.email}
                      placeholder="e.g. luke@jedi.com"
                      ref={register({
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                    />
                    {errors.email && <span className="invalid">{errors.email.message}</span>}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="bg-light">
            <Button className="bg-violet-700 hover:bg-violet-800 text-white mb-0" size="md" type="submit">
              Add User
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

    </React.Fragment>
  );
};
export default AddUserModal;
